import React, {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import {PaymentMethod} from '@stripe/stripe-js';
import {useQuery} from '@tanstack/react-query';
import {useRouter} from 'next/router';
import Web3 from 'web3';

import {PaymentDto, ShopperDto, WalletDto} from 'types/Dto';

export const AUTH_STORAGE_KEY = 'shopper-token';

import {get, remove, set} from 'utils/Storage';

import {createShopperAuth, fetchShopperAuth} from 'api/auth/shopper';
import {fetchShopperPaymentMethods} from 'api/shoppers';

import useNoRampEvent from 'hooks/useNoRampEvent';

import {magic} from 'lib/magic';

export type PaymentContextProps = {
  selectedPaymentMethod: string | null;
  setSelectedPaymentMethod: Dispatch<SetStateAction<string | null>>;
  magicToken: string | undefined;
  setMagicToken: Dispatch<SetStateAction<string | undefined>>;
  isLoggedIn: boolean;
  setIsLoggedIn: Dispatch<SetStateAction<boolean>>;

  paymentMethods: PaymentMethod[];
  setPaymentMethods: Dispatch<SetStateAction<PaymentMethod[]>>;

  type: WalletDto['type'] | null;
  setType: Dispatch<SetStateAction<WalletDto['type'] | null>>;

  address: string | null;
  setAddress: Dispatch<SetStateAction<string | null>>;

  magicAddress: string | null;

  network: string | undefined;
  setNetwork: Dispatch<SetStateAction<string | undefined>>;

  logout: () => void;
  loading: boolean;
  loginWithMail: (email: string) => Promise<void>;
  loginWithSMS: (phone: string) => Promise<void>;
  waitingForAuth: boolean;

  isPreview: boolean;

  formComplete: boolean;
  setFormComplete: Dispatch<SetStateAction<boolean>>;

  onCardComplete: (status: boolean) => void;

  priceId: string | undefined;
  priceIdPreview: string;
  appId: string;

  payment: PaymentDto | null;
  paymentToken?: string;

  shopper: ShopperDto | null;
};

// Context
export const PaymentContext = createContext<Partial<PaymentContextProps>>({});

type PaymentContextProviderProps = {
  children: ReactNode;
};

// Provider
export const PaymentContextProvider: React.FC<PaymentContextProviderProps> = ({
  children,
}) => {
  const {query} = useRouter();
  const isPreview = useMemo(() => !!query.preview, [query.preview]);

  const paymentToken = useMemo(
    () => query.token_id?.toString(),
    [query.token_id],
  );
  const priceId = useMemo(() => query.price_id?.toString(), [query.price_id]);
  const appId = useMemo(() => query.app?.toString() ?? '', [query.app]);
  const [payment, setPayment] = useState<PaymentDto | null>(null);

  const onEvent = useCallback((data: any) => {
    if (!['error', 'completed'].includes(data.type)) {
      return;
    }

    setPayment(data.data);
  }, []);

  useNoRampEvent({
    event: 'onPayment',
    handler: onEvent,
  });

  const priceIdPreview = useMemo(
    () => (!priceId ? 'preview' : priceId),
    [priceId],
  );

  const [paymentMethods, setPaymentMethods] = useState<PaymentMethod[]>([]);

  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<
    string | null
  >(null);

  const [magicToken, setMagicToken] = useState<string | undefined>(undefined);
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const [type, setType] = useState<WalletDto['type'] | null>(null);
  const [network, setNetwork] = useState<string | undefined>(undefined);

  const [address, setAddress] = useState<string | null>(null);
  const [magicAddress, setMagicAddress] = useState<string | null>(null);

  const isJwtLoading = useRef(true);

  const isAuthenticating = useRef(false);

  const [waitingForAuth, setWaitingForAuth] = useState(false);

  const [formComplete, setFormComplete] = useState(false);

  const [shopper, setShopper] = useState<ShopperDto | null>(null);

  const [isLoading, setIsLoading] = useState(true);

  const authenticate = useCallback(async (didToken: string | null) => {
    if (!didToken) {
      throw new Error('No did token');
    }
    const {token, ...shopper} = await createShopperAuth(didToken);
    set(AUTH_STORAGE_KEY, token);
    setShopper(shopper);
    setIsLoggedIn(true);
  }, []);

  /* Auth via JWT token */

  useEffect(() => {
    async function authenticateWithMagicCookie() {
      if (!magic || isLoggedIn || isJwtLoading.current) {
        console.table({
          magic: !!magic,
          isLoggedIn,
          isJwtLoading: isJwtLoading.current,
        });
        return;
      }
      if (isAuthenticating.current) {
        return;
      }

      isAuthenticating.current = true;

      try {
        const isAuth = await magic.user.isLoggedIn();
        if (!isAuth) {
          isAuthenticating.current = false;

          return;
        }

        const didToken = await magic.user.getIdToken();

        authenticate(didToken);
      } catch (error) {
        console.error(error);
        // Handle any errors
      } finally {
        isAuthenticating.current = false;
      }
    }
    const authenticateWithJwt = async () => {
      if (isAuthenticating.current) return;

      isAuthenticating.current = true;
      try {
        const token = get<string>(AUTH_STORAGE_KEY);

        if (!token) {
          throw new Error('No token');
        }

        const shopper = await fetchShopperAuth();

        setIsLoggedIn(true);
        setShopper(shopper);
      } catch (error) {
        // Handle any errors
        console.error(error);

        isAuthenticating.current = false;
        isJwtLoading.current = false;
        await authenticateWithMagicCookie();
      } finally {
        isJwtLoading.current = false;
        isAuthenticating.current = false;

        setIsLoading(false);
      }
    };

    authenticateWithJwt();
  }, [authenticate, isAuthenticating, isLoggedIn]);

  /* get user metadata */

  useEffect(() => {
    async function run() {
      if (!isLoggedIn || !magic) return;

      const isAuth = await magic.user.isLoggedIn();
      if (!isAuth) return;

      const user = await magic.user.getInfo();
      // setUserMetadata(user);

      if (type === 'evm') {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const web3 = new Web3(magic.rpcProvider); // Or window.web3 = ...
        const addresses = await web3.eth.getAccounts();
        setAddress(addresses[0]);
        setMagicAddress(addresses[0]);
        console.log('EVM ADDRESSES', addresses);
      }

      if (type === 'near') {
        setAddress(user.publicAddress);
        setMagicAddress(user.publicAddress);
        console.log('NEAR ADDRESSES', user.publicAddress);
      }
    }

    try {
      run();
    } catch (e) {
      console.error(e);
    }
  }, [isLoggedIn, type]);

  const loginWithMail = useCallback(
    async (email: string) => {
      if (!magic) {
        return;
      }
      try {
        setWaitingForAuth(true);
        const didToken = await magic.auth.loginWithMagicLink({
          email,
          showUI: false,
        });
        authenticate(didToken);
      } catch (e) {
        console.error('Error doing login with magic link:', e);
      } finally {
        setWaitingForAuth(false);
      }
    },
    [authenticate],
  );

  const loginWithSMS = useCallback(
    async (phoneNumber: string) => {
      if (!magic) {
        return;
      }
      try {
        setWaitingForAuth(true);
        const didToken = await magic.auth.loginWithSMS({
          phoneNumber,
        });
        authenticate(didToken);
      } catch (e) {
        console.error('Error doing login with magic link SMS:', e);
      } finally {
        setWaitingForAuth(false);
      }
    },
    [authenticate],
  );

  const logout = useCallback(() => {
    magic?.user.logout();
    setIsLoggedIn(false);
    remove(AUTH_STORAGE_KEY);
  }, [setIsLoggedIn]);

  const {data, isLoading: isLoadingPaymentMethods} = useQuery({
    queryKey: ['payment-methods', shopper?.id],
    queryFn: async () => {
      if (!shopper) {
        return [];
      }
      // console.log('fetching  methods', magicToken, new Date().toISOString());
      return fetchShopperPaymentMethods();
    },

    enabled: !!isLoggedIn && !!shopper,

    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    if (isLoadingPaymentMethods || !data) return;

    setPaymentMethods(data);
    if (data.length > 0) {
      setSelectedPaymentMethod(data[0].id);
      setFormComplete(true);
    }
  }, [data, isLoadingPaymentMethods]);

  const onCardComplete = useCallback((status: boolean) => {
    setFormComplete(status);
  }, []);

  const values = React.useMemo(
    () => ({
      selectedPaymentMethod,
      setSelectedPaymentMethod,
      magicToken,
      setMagicToken,
      isLoggedIn,
      setIsLoggedIn,
      paymentMethods,
      setPaymentMethods,
      type,
      setType,
      address,
      setAddress,

      logout,
      loading: isLoading,
      loginWithMail,
      loginWithSMS,
      waitingForAuth,
      network,
      setNetwork,
      isPreview,
      formComplete,
      setFormComplete,
      onCardComplete,
      priceId,
      appId,
      priceIdPreview,
      magicAddress,
      paymentToken,
      payment,
      shopper,
    }),
    [
      selectedPaymentMethod,
      magicToken,
      isLoggedIn,
      paymentMethods,
      type,
      address,
      logout,
      isLoading,
      loginWithMail,
      loginWithSMS,
      waitingForAuth,
      network,
      isPreview,
      formComplete,
      onCardComplete,
      priceId,
      appId,
      priceIdPreview,
      magicAddress,
      paymentToken,
      payment,
      shopper,
    ],
  );

  return (
    <PaymentContext.Provider value={values}>{children}</PaymentContext.Provider>
  );
};

export function usePaymentContext() {
  const context = useContext(PaymentContext) as PaymentContextProps;

  if (!context) {
    console.error('Error deploying Payment Context!!!');
  }

  return context;
}

// export enum PaymentFormScreens {
//   Home = 'home',
//   AddPaymentMethod = 'add-payment-method',
//   AddWallet = 'add-wallet',
// }

export default usePaymentContext;
