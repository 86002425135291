import classNames from 'classnames';

export type CardProps = {
  children?: React.ReactNode;
  className?: string;
  padding?: number | string;
  borderRadius?: string | number;
  dark?: boolean;
  light?: boolean;
  outline?: boolean;
  transparent?: boolean;
  backgroundColor?: string;
  onPress?: () => void;
};

const Card = ({
  children,
  padding,
  borderRadius,
  className,
  dark,
  light,
  outline,
  transparent,
  backgroundColor,
  onPress,
}: CardProps) => {
  const paddingClass = padding
    ? typeof padding === 'number'
      ? `p-${padding}`
      : padding
    : 'p-5 lg:p-8';
  const borderRadiusClass = borderRadius
    ? typeof borderRadius === 'number'
      ? `rounded-${borderRadius}`
      : borderRadius
    : 'rounded';
  // const backgroundColorClass = backgroundColor ? `bg-${backgroundColor}` : '';

  return (
    <div
      onClick={onPress}
      style={{...(backgroundColor && {backgroundColor})}}
      className={classNames(
        'flex flex-col',
        paddingClass,
        borderRadiusClass,
        {
          'bg-gray-800': dark || light,
          'bg-transparent': transparent,
          'border border-gray-800': outline,
        },
        className,
      )}>
      {children}
    </div>
  );
};

export default Card;
