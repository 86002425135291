import Link from 'next/link';

import PAGE from 'constants/page';

import Title from 'components/Common/Title';
import NoRampIcon from 'components/v2/icons/NorampIcon';

import GithubAuthButton from '../auth-providers/github-auth-button/github-auth-button';
import GoogleAuthButton from '../auth-providers/google-auth-button/google-auth-button';
import AuthEmailForm from '../AuthEmailForm/AuthEmailForm';

type FormProps = {
  title: string;
  onEmailSent: (email: string) => void;
};

const AuthForm = ({title, onEmailSent}: FormProps) => {
  return (
    <div className="flex flex-col items-center mt-6">
      <NoRampIcon />
      <Title className="mb-6 mt-6 text-2xl font-semibold">{title}</Title>

      <div className="flex flex-col items-center space-y-5">
        <div className="">
          <AuthEmailForm onSuccess={onEmailSent} />
        </div>

        <div className="flex items-center w-full justify-center py-2">
          <div className="flex border-[0.5px] w-full border-gray-300" />

          <div className="mx-6 text-xs text-[#878787]">or</div>

          <div className="flex border-[0.5px] w-full border-gray-300" />
        </div>

        <GoogleAuthButton />

        <GithubAuthButton />
      </div>

      <p className="text-xs font-extralight border-b text-gray-300 mt-4">
        By continuing you agree to our{' '}
        <Link href={PAGE.LEGAL.TERMS} target="_blank" className=" ">
          Terms of Use
        </Link>
      </p>
    </div>
  );
};

export default AuthForm;
