import {useCallback, type MouseEvent} from 'react';

import classNames from 'classnames';

import {useForm} from '..';

import {Button, ButtonProps} from 'components/v3/button';

type SubmitProps = ButtonProps;

const Submit = ({className, children, ...props}: SubmitProps) => {
  const {isCreation, isSubmitting} = useForm(); // submitForm

  const onPress = useCallback(
    (e: MouseEvent) => {
      if (isSubmitting) {
        e.preventDefault();
      }
    },
    [isSubmitting],
  );

  // const onPressDialog = useCallback(
  //   (e: MouseEvent) => {
  //     if (isSubmitting) {
  //       e.preventDefault();
  //       return;
  //     }

  //     submitForm();
  //   },
  //   [submitForm, isSubmitting],
  // );

  return (
    <Button
      onClick={onPress}
      className={classNames('flex gap-2', className)}
      type="submit"
      {...props}
      disabled={isSubmitting}>
      {children ?? (isCreation ? 'form.button_add' : 'form.button_save')}
    </Button>
  );
};

export default Submit;
