import {useEffect, useMemo, useState} from 'react';

import {ThemeType} from 'types/Theme';

export const useSystemTheme = () => {
  const defaultTheme = useMemo(() => {
    if (typeof window === 'undefined') {
      return 'light';
    }

    const media = window.matchMedia('(prefers-color-scheme: dark)');
    return media.matches ? 'dark' : 'light';
  }, []);

  const [theme, setTheme] = useState<ThemeType>(defaultTheme);

  useEffect(() => {
    if (typeof window === 'undefined') {
      return;
    }

    if (!('matchMedia' in window)) {
      return;
    }

    const onThemeChange = (media: MediaQueryListEvent) => {
      setTheme(media.matches ? 'dark' : 'light');
    };

    const mediaQueryList = window.matchMedia('(prefers-color-scheme: dark)');

    // add Safari legacy support
    if (!('addEventListener' in mediaQueryList)) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      mediaQueryList.addListener(onThemeChange);
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      return () => mediaQueryList.addListener(onThemeChange);
    }

    mediaQueryList.addEventListener('change', onThemeChange);
    return () => mediaQueryList.addEventListener('change', onThemeChange);
  }, [setTheme]);

  return theme;
};
