import {useEffect, useState} from 'react';

import {useRouter} from 'next/router';

import {get, set} from 'utils/Storage';

import {IS_STAGING} from 'constants/all';

import {NetworkType} from 'components/App/AppContext';

const useNetwork = () => {
  const router = useRouter();
  const [localNetworkType, setLocalNetworkType] = useState<NetworkType>(
    IS_STAGING ? 'testnet' : 'mainnet',
  );

  useEffect(() => {
    // if (IS_STAGING) {
    //   setLocalNetworkType('testnet');
    //   return;
    // }

    if (router?.query?.network === 'testnet') {
      setLocalNetworkType(router?.query?.network as NetworkType);
      set('nr-network', router?.query?.network);
      delete router.query.network;

      return;
    }
    const storedNetwork = get('nr-network');
    if (
      storedNetwork &&
      (storedNetwork === 'mainnet' || storedNetwork === 'testnet')
    ) {
      setLocalNetworkType(storedNetwork as NetworkType);
    }
  }, [router?.query?.network]);

  const setNetworkType = (networkType: NetworkType) => {
    console.log({networkType});
    set('nr-network', networkType);
    setLocalNetworkType(networkType);
    router.push({
      query: {
        ...router.query,
        network: networkType,
      },
    });
  };

  return {
    networkType: localNetworkType,
    setNetworkType,
    isTestnet: localNetworkType === 'testnet',
    isMainnet: localNetworkType === 'mainnet',
  };
};

export default useNetwork;
