import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
  type Dispatch,
  type FormEvent,
  type ReactNode,
  type SetStateAction,
} from 'react';

import classNames from 'classnames';
import {t} from 'i18next';
import {
  FormProvider,
  useForm as useFormHook,
  useFormState,
  type FieldPath,
  type FieldValues,
  type SubmitHandler,
  type UseFormGetValues,
  type UseFormProps,
  type UseFormReturn,
  type UseFormSetValue,
} from 'react-hook-form';

import type {ErrorDto} from 'types/Dto/Error';

import {formatEndpoint, type PathParams} from 'utils/Api';
import {GET, PATCH, POST, PUT} from 'utils/Http';
import {
  deleteDeepValue,
  eachDeepValue,
  getDeepValue,
  isDeepValue,
  setDeepValue,
} from 'utils/Misc';

import API from 'constants/api';

import useHandleError from 'hooks/core/useHandleError';
import useIsMounted from 'hooks/core/useIsMounted';

import Error from 'components/App/Error';
import {useRedirect, type UseRedirect} from 'components/App/Redirect';
import Loading from 'components/Common/Loading';
import {useToast} from 'components/v3/toast/use-toast';

import {useConnect} from './Connect';
import Errors, {type ErrorMessage} from './Errors';

export type OnSubmit<Fields extends FieldValues> = (
  form: UserFormReturnPropWithValues<Fields>,
) => boolean | void | Promise<boolean | void>;

export type OnFieldChange<Fields extends FieldValues> = (
  form: UserFormReturnPropWithValues<Fields>,
  name: FieldName<Fields>,
  value: FieldValue,
) => void | Promise<void>;

export type OnSuccess<Fields extends FieldValues> = (
  form: UserFormReturnPropWithValues<Fields>,
) => void | Promise<void>;

export type OnRemove<Fields extends FieldValues> = (
  form: UserFormReturnProp<Fields>,
  result: unknown,
) => void | Promise<void>;

export type OnSaveValues<Fields> = (values: Partial<Fields>) => Promise<Fields>;

export type OnSubmitHandler = (event: FormEvent) => Promise<void>;

export type FieldName<Fields extends FieldValues> = FieldPath<Fields>;
/* export type FieldValue<Fields> = UnpackNestedValue<
  FieldPathValue<Fields, FieldPath<Fields>>
>; */
export type FieldValue = any;

type SetValues<Fields> = (values: Partial<Fields>) => void;

type FieldMetadata = {
  valueAsObject?: boolean;
  removeAsEmpty?: boolean;
};

type SetFieldMetadata = (field: string, metadata: FieldMetadata) => void;

export type FormExtraProps<Fields extends FieldValues = any> = {
  data: Fields;
  props: FormProps<Fields>;
  isCreation: boolean;
  isSubmitting: boolean;
  sourceUrl?: string;
  submitForm: () => Promise<void>;
  setValue: UseFormSetValue<Fields>;
  setValues: SetValues<Fields>;
  getValues: UseFormGetValues<Fields>;
  setFieldMetadata: SetFieldMetadata;
  setErrorInfo: Dispatch<SetStateAction<any>>;
  redirect: UseRedirect;
};

export type UserFormReturnProp<Fields extends FieldValues> =
  UseFormReturn<Fields> & FormExtraProps<Fields>;

export type UserFormReturnPropWithValues<Fields extends FieldValues> =
  UserFormReturnProp<Fields> & {
    values: Partial<Fields>;
  };

const FormContext = createContext<FormExtraProps>({
  data: {},
  props: {},
  isCreation: true,
  isSubmitting: false,
  submitForm: async () => {
    /* do nothing */
  },
  setValue: () => {
    /* do nothing */
  },
  setValues: () => {
    /* do nothing */
  },
  getValues: () => {
    /* do nothign */
    return [];
  },
  setFieldMetadata: () => {
    /* do nothing */
  },
  setErrorInfo: () => {
    /* do nothing */
  },
  redirect: () => {
    /* do nothing */
  },
});

export type FormProps<Fields extends FieldValues> = {
  className?: string;
  children?: (form: UserFormReturnProp<Fields>) => ReactNode;
  primaryId?: string;
  source?: keyof typeof API | API;
  sourceParams?: PathParams;
  data?: Fields;
  options?: UseFormProps;

  onSubmit?: OnSubmit<Fields>;
  onFieldChange?: OnFieldChange<Fields>;
  onSuccess?: OnSuccess<Fields>;
  onRemove?: OnRemove<Fields>;
  onRender?: () => void | Promise<void>;

  onSaveValues?: OnSaveValues<Fields>;

  creation?: boolean;
  sourceId?: string;

  alwaysSave?: boolean;
  showErrors?: boolean;

  headerContent?: ReactNode;
  footerContent?: ReactNode;

  successMsg?: string | boolean;
  removeMsg?: string | boolean;

  method?: 'PUT' | 'POST' | 'PATCH';

  onSubmitHandler?: OnSubmitHandler;
};

const Form = <Fields extends FieldValues>(props: FormProps<Fields>) => {
  const redirect = useRedirect();

  const {toast} = useToast();
  const handleError = useHandleError();
  const isMounted = useIsMounted();
  const formSavingValuesRef = useRef(false);
  const {
    className = 'default',
    primaryId = 'id',
    source,
    sourceId: sourceIdProp,
    sourceParams,
    data: initData,
    children,
    options,
    onRender,
    onFieldChange,
    onSuccess,
    onSubmit,
    onSaveValues,
    showErrors,
    alwaysSave,
    headerContent,
    footerContent,
    creation,
    successMsg,
    method,
    onSubmitHandler,
  } = props;

  const [sourceId, setSourceId] = useState<string | undefined>(sourceIdProp);
  const isCreation = creation || (!sourceId && 'sourceId' in props);

  // format resource url
  const sourceUrl = useMemo(() => {
    if (!source) {
      return;
    }

    // format with sourceParams
    return formatEndpoint(source, sourceParams);
  }, [source, sourceParams]);

  const [errorInfo, setErrorInfo] = useState<any | null>(null);
  const [data, setData] = useState<Fields | null>(
    initData ? {...initData} : !sourceUrl || isCreation ? ({} as Fields) : null,
  );

  // TODO: Casting from here makes Typescript intellisense (VSCode) extremely slow, no worries, everything is typed below
  //const form = useFormHook<Fields>({
  const form = useFormHook({
    mode: 'onSubmit',
    ...options,
  });
  const {
    watch,
    setValue: setValueProp,
    getValues: getValuesProp,
    control,
    handleSubmit,
    setError,
  } = form;
  const {errors} = useFormState({control});
  const {setValues: setValuesConnect} = useConnect();
  const [fieldsMetadata, setFieldMetadataConnect] = useState<{
    [name: string]: FieldMetadata;
  }>({});
  const fieldsMetadataRef = useRef(fieldsMetadata);
  fieldsMetadataRef.current = fieldsMetadata;

  if (onFieldChange || setValuesConnect) {
    watch();
  }

  const setFieldMetadata = useCallback<SetFieldMetadata>(
    (field, metadata) => {
      setFieldMetadataConnect(fields => setDeepValue(fields, field, metadata));
    },
    [setFieldMetadataConnect],
  );

  const setInitialConnectValues = useCallback(() => {
    if (setValuesConnect) {
      setValuesConnect(getValuesProp());
    }
  }, [getValuesProp, setValuesConnect]);

  useEffect(() => {
    if (onRender) {
      onRender();
    }
  }, [onRender]);

  useEffect(() => {
    if (!sourceUrl || isCreation) {
      setInitialConnectValues();
      return;
    }

    const loadData = async () => {
      try {
        const data = await GET<Fields>(sourceUrl);

        if (!isMounted()) {
          return;
        }

        if (Array.isArray(data)) {
          console.warn(
            '[Form] Loading array instead of object form, aborting... Use `creation` for creation forms.',
          );
          return;
        }

        setData(data);
        setInitialConnectValues();
      } catch (e) {
        setErrorInfo(e);
      }
    };

    loadData();
  }, [sourceUrl, isCreation, isMounted, setInitialConnectValues]);

  const saveSource = useCallback(
    async (values: Partial<Fields>): Promise<Partial<Fields> | Fields> => {
      let result: Fields;

      if (onSaveValues) {
        result = await onSaveValues(values);

        if (!result) {
          return Promise.resolve({});
        }
      } else {
        if (!sourceUrl) {
          return Promise.resolve({});
        }

        if (
          method === 'PATCH' ||
          method === 'PUT' ||
          (data && primaryId in data)
        ) {
          const methodFn = method === 'PUT' ? PUT : PATCH;
          result = await methodFn<Fields, Partial<Fields>>(sourceUrl, values);
        } else {
          result = await POST<Fields, Partial<Fields>>(sourceUrl, values);

          if (isMounted() && primaryId in (result ?? {})) {
            setSourceId((result as any)[primaryId]);
          }
        }
      }

      return Promise.resolve(result);
    },
    [onSaveValues, method, data, primaryId, setSourceId, isMounted, sourceUrl],
  );

  // TODO: Improve initialization and remove `any`
  const formProps = useMemo<any>(
    () => ({onRemove: props.onRemove, removeMsg: props.removeMsg}),
    [props.onRemove, props.removeMsg],
  );

  const isSubmitting = formSavingValuesRef.current;

  const formStrucBase = useMemo<any>(() => {
    return {
      // hook form core props
      ...form,

      // custom form props
      props: formProps,

      isCreation,
      isSubmitting,
      sourceUrl,
      setFieldMetadata,
      setErrorInfo,
      data: data as Fields,
      values: {} as Partial<Fields>,
      setValues: () => {
        /* do nothing */
      },
      redirect,
    };
  }, [
    data,
    form,
    formProps,
    redirect,
    isCreation,
    sourceUrl,
    isSubmitting,
    setFieldMetadata,
    setErrorInfo,
  ]);

  const sendSuccessMsg = useCallback(() => {
    if (!source) {
      return;
    }

    if (typeof successMsg === 'string' || typeof successMsg === 'undefined') {
      // ORIGINAL CODE
      // const message =
      //   typeof successMsg === 'string'
      //     ? successMsg
      //     : isCreation
      //     ? 'form.changes_added'
      //     : 'form.changes_saved';

      // MODIFIED CODE:
      let message;

      if (typeof successMsg === 'string') {
        message = successMsg;
      } else {
        if (isCreation) {
          message = 'form.changes_added';
        } else {
          message = 'form.changes_saved';
        }
      }
      // :MODIFIED CODE

      toast({
        variant: 'success',
        title: 'Success',
        description: t(message),
      });
    }
  }, [source, successMsg, isCreation, toast]);

  const isFieldValueAsObject = useCallback((name: string): boolean => {
    const metadata = getDeepValue<FieldMetadata>(
      fieldsMetadataRef.current,
      name,
    );
    return !!metadata?.valueAsObject;
  }, []);

  const isFieldRemoveAsEmtpty = useCallback((name: string): boolean => {
    const metadata = getDeepValue<FieldMetadata>(
      fieldsMetadataRef.current,
      name,
    );

    return !!metadata?.removeAsEmpty;
  }, []);

  const getValues = useCallback<UseFormGetValues<Fields>>(
    (fieldNames?: FieldPath<Fields> | ReadonlyArray<FieldPath<Fields>>) => {
      let values = getValuesProp(fieldNames as string);

      if (Array.isArray(fieldNames)) {
        Object.keys(values).forEach(key => {
          const value = getDeepValue(values, key);
          if (isFieldValueAsObject(key) && typeof value === 'string') {
            setDeepValue(values, key, value);
          }
        });
      } else if (
        isFieldValueAsObject(fieldNames as string) &&
        typeof values === 'string'
      ) {
        values = JSON.parse(values);
      }

      return values;
    },
    [getValuesProp, isFieldValueAsObject],
  );

  const setValue = useCallback<UseFormSetValue<Fields>>(
    (name, value) => {
      if (isFieldValueAsObject(name)) {
        setValueProp(name, JSON.stringify(value) as FieldValue);
        return;
      }

      setValueProp(name, value as FieldValue);
    },
    [setValueProp, isFieldValueAsObject],
  );

  const getChangedValues = useCallback(
    (values: Partial<Fields>) => {
      let valuesToUpdate: Partial<Fields> = {};

      const fullUpdate = alwaysSave || !data || !(primaryId in data);

      if (fullUpdate) {
        valuesToUpdate = {...values};

        eachDeepValue(valuesToUpdate, (name, value) => {
          // remove removeAsEmpty values
          if (isFieldRemoveAsEmtpty(name) && !value) {
            deleteDeepValue(valuesToUpdate, name);
          }
        });

        return valuesToUpdate;
      }

      // validate changes
      eachDeepValue(values, (name, value) => {
        // ignore if equal
        if (value === getDeepValue(data, name)) {
          return;
        }

        // ignore removeAsEmpty
        if (isFieldRemoveAsEmtpty(name) && !value) {
          return;
        }

        // ignore empty files
        if (value instanceof FileList && !(value as FileList).length) {
          return;
        }

        // ignore undefined values
        if (typeof value === 'undefined') {
          return;
        }

        const currentValue = getDeepValue(data, name);

        // ignore value equal to id
        if (
          currentValue &&
          typeof currentValue === 'object' &&
          currentValue?.id === value
        ) {
          return;
        }

        setDeepValue(valuesToUpdate, name, value);
      });

      return valuesToUpdate;
    },
    [isFieldRemoveAsEmtpty, alwaysSave, data, primaryId],
  );

  const setValues = useCallback(
    async (values: Partial<Fields>, newChangedValues?: Partial<Fields>) => {
      try {
        formSavingValuesRef.current = true;

        const fullUpdate = alwaysSave || !data || !(primaryId in data);

        const changedValues = newChangedValues ?? getChangedValues(values);

        if (!fullUpdate) {
          if (!Object.keys(changedValues).length) {
            sendSuccessMsg();
            if (onSuccess) {
              onSuccess({...formStrucBase, setValues, data, values});
            }

            formSavingValuesRef.current = false;
            return;
          }
        }

        const newData = await saveSource(changedValues);

        if (!isMounted()) {
          formSavingValuesRef.current = false;
          return;
        }

        const mergedData = {
          ...data,
          ...changedValues,
          ...newData,
        } as Fields;

        setData(mergedData);

        sendSuccessMsg();

        if (onSuccess) {
          await onSuccess({
            ...formStrucBase,
            setValues,
            data: mergedData,
            values,
          });
        }

        formSavingValuesRef.current = false;
      } catch (e) {
        formSavingValuesRef.current = false;

        if (!isMounted()) {
          return;
        }

        const {message} = e as ErrorDto;

        if (Array.isArray(message)) {
          const found = message.some(({field, errors}) => {
            if (!Object.keys(errors).length) {
              return false;
            }

            // get only the first error
            const message = Object.keys(errors)[0];

            if (field && isDeepValue(values, field)) {
              setError(
                field,
                {
                  type: 'remote',
                  message: message,
                },
                {
                  shouldFocus: true,
                },
              );
              return true;
            }

            return false;
          });

          if (found) {
            return;
          }
        }

        handleError(e);
      }
    },
    [
      handleError,
      getChangedValues,
      formStrucBase,
      primaryId,
      alwaysSave,
      data,
      onSuccess,
      saveSource,
      setError,
      sendSuccessMsg,
      isMounted,
    ],
  );

  const formStruc = useMemo(() => {
    return {
      ...formStrucBase,
      getValues,
      setValues,
      setValue,
    };
  }, [getValues, setValues, setValue, formStrucBase]);

  const formStrucRef = useRef(formStruc);
  formStrucRef.current = formStruc;

  const fieldsContent = useMemo(() => {
    if (errorInfo) {
      return <Error info={errorInfo} />;
    }

    if (!data) {
      return null;
    }

    if (children) {
      return children(formStruc);
    }
  }, [errorInfo, children, formStruc, data]);

  const checkValuesMetadata = useCallback(
    (values: Partial<Fields>) => {
      eachDeepValue(values, (name, value) => {
        const metadata = getDeepValue(fieldsMetadata, name);

        if (metadata?.valueAsObject) {
          if (typeof value === 'string') {
            try {
              setDeepValue(values, name, JSON.parse(value));
            } catch (e) {
              setDeepValue(values, name, undefined);
            }
          }
        }
      });

      return values;
    },
    [fieldsMetadata],
  );

  useEffect(() => {
    /* if (!onFieldChange && !setValuesConnect) {
      return;
    } */

    const sub = watch((values, {name}) => {
      if (setValuesConnect) {
        setValuesConnect(values);
      }

      if (onFieldChange && name) {
        onFieldChange(
          {
            ...formStrucBase,
            setValues,
            data,
            values,
          },
          name as FieldName<Fields>,
          getDeepValue(values, name),
        );
      }
    });

    return () => sub.unsubscribe();
  }, [watch, onFieldChange, setValuesConnect, formStrucBase, setValues, data]);

  //UseFormHandleSubmit
  const _onSubmit = useCallback<SubmitHandler<Fields>>(
    async submittedValues => {
      const values = submittedValues as Fields;

      if (formSavingValuesRef.current) {
        return Promise.resolve();
      }

      checkValuesMetadata(values);

      formSavingValuesRef.current = true;

      const changedValues = getChangedValues(values);

      const onSubmitResult = await onSubmit?.({
        ...formStrucRef.current,
        values: changedValues,
      });

      if (onSubmitResult === false) {
        formSavingValuesRef.current = false;
        return Promise.resolve();
      }

      formSavingValuesRef.current = false;
      await setValues(values, changedValues);

      return new Promise(finish => {
        if (!isMounted()) {
          return;
        }

        finish(values);
      });
    },
    [
      formSavingValuesRef,
      formStrucRef,
      onSubmit,
      setValues,
      checkValuesMetadata,
      isMounted,
      getChangedValues,
    ],
  );

  const submitHandler = useMemo(() => {
    return !onSubmitHandler ? handleSubmit(_onSubmit as any) : onSubmitHandler;
  }, [onSubmitHandler, handleSubmit, _onSubmit]);

  const submitForm = useCallback(async () => {
    return submitHandler({} as any);
  }, [submitHandler]);

  const errorsContent = useMemo(() => {
    const errorsList: ErrorMessage[] = [];

    Object.keys(errors).map(name => {
      if (name in errors) {
        errorsList.push({name, ...(errors as any)[name]});
      }
    });

    if (!errorsList.length) {
      return null;
    }

    return showErrors && <Errors errors={errorsList} />;
  }, [errors, showErrors]);

  const renderedForm = useMemo(() => {
    return (
      <FormProvider {...form}>
        <FormContext.Provider value={{submitForm, ...formStruc}}>
          <form
            className={classNames('form', className)}
            onSubmit={submitHandler}>
            {headerContent}
            {errorsContent}
            {fieldsContent}
            {!data && !errorInfo && <Loading />}
            {footerContent}
          </form>
        </FormContext.Provider>
      </FormProvider>
    );
  }, [
    className,
    form,
    formStruc,
    errorInfo,
    errorsContent,
    fieldsContent,
    headerContent,
    footerContent,
    data,
    submitHandler,
    submitForm,
  ]);

  return <>{renderedForm}</>;
};

export const useForm = <
  Fields extends FieldValues,
>(): UserFormReturnProp<Fields> => {
  return useContext(FormContext) as UserFormReturnProp<Fields>;
};

export const useFormData = <T,>(): T => {
  return useContext(FormContext).data as T;
};

export const useFormProps = <
  Fields extends FieldValues,
>(): FormProps<Fields> => {
  return useContext(FormContext).props as FormProps<Fields>;
};

export const useIsFormCreation = (): boolean => {
  return useContext(FormContext).isCreation;
};

export const useSetFieldMetadata = () => {
  return useContext(FormContext).setFieldMetadata;
};

export const normalizeFieldName = (name: string) => {
  return name?.split('.')?.join('--');
};

export default Form;
