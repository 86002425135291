import type {AppDto} from 'types/Dto';

import {useApp} from 'components/App/AppContext';

const useCurrentApp = () => {
  const {selectedApp} = useApp();

  return (selectedApp as AppDto) || null;
};

export default useCurrentApp;
