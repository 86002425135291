import classNames from 'classnames';

import type {AppDto} from 'types/Dto';

import {cn} from 'lib/utils';

type AppStatusProps = {
  status: AppDto['status'];
  medium?: boolean;
};

export const AppStatus = ({status, medium}: AppStatusProps) => {
  return (
    <div className="flex">
      <div className={classNames('flex gap-2 items-center no-underline')}>
        <div
          className={cn(
            medium,
            status === 'draft' && 'bg-gray-600', // Assuming 'draft', 'active', etc., are the possible status values
            status === 'active' && 'bg-[#23FE88]',
            status === 'submitted' && 'bg-orange-400',
            (status === 'disabled' || status === 'deleted') && 'bg-red-600',
            'w-2 h-2 rounded-full ',
          )}
        />
        <span className="text-base font-medium leading-5 text-gray-500 capitalize">
          {status}
        </span>
      </div>
    </div>
  );
};

export default AppStatus;
