import React from 'react';

export const NoRampIcon = ({width = 46, height = 28, color = ''}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      style={{color: color}}
      className="text-text"
      fill="none">
      <path
        fill="currentColor"
        d="M20.489 2.368c0-2.286-2.617-2.679-3.49-1.786L.483 17.488c-.038.06-.081.214.059.357s.29.06.349 0l2.384-2.44c1.454-1.489 3.606 0 3.606 1.368v9.12c0 2.369 2.443 2.428 3.78 1.06l13.086-13.394c1.616-1.654 4.071-.582 4.071 1.369v10.774c0 1.964 2.21 3.274 4.071 1.37l13.61-13.93c.057-.06.094-.2 0-.297-.117-.12-.291-.06-.35 0l-2.442 2.5c-1.222 1.25-3.49 0-3.49-1.489V2.07c0-2.202-2.5-2.56-3.548-1.488L24.211 12.31c-1.64 1.68-3.722.489-3.722-1.19V2.37z"
        stroke="currentColor"></path>
    </svg>
  );
};

export default NoRampIcon;
