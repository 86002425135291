import {
  createContext,
  useContext,
  useState,
  type Dispatch,
  type SetStateAction,
  type ReactNode,
  useCallback,
  useRef,
} from 'react';

import {type PaginationInfinityContextProps} from './usePaginationInfinity';
import {type PaginationPagedContextProps} from './usePaginationPaged';

type PaginationType = {
  key: string;
  context: PaginationInfinityContextProps | PaginationPagedContextProps;
};

export type PaginationContextContext = {
  contexts: PaginationType[];
  setContexts: Dispatch<SetStateAction<PaginationType[]>>;
};

const PaginationContextContext = createContext<PaginationContextContext>({
  contexts: [],
  setContexts: () => {
    /* do nothing */
  },
});

export const usePaginationContext = () => {
  const {contexts, setContexts} = useContext(PaginationContextContext);
  const contextsRef = useRef(contexts);
  contextsRef.current = contexts;

  const addContext = useCallback(
    (context: PaginationType) => {
      setContexts(contexts => {
        return [...contexts.filter(e => e.key !== context.key), context];
      });
    },
    [setContexts],
  );

  const removeContext = useCallback(
    (key: string) => {
      setContexts(contexts => {
        return [...contexts.filter(e => e.key !== key)];
      });
    },
    [setContexts],
  );

  const getContext = useCallback(<T,>(key: string) => {
    return contextsRef.current.find(e => e.key === key)
      ?.context as unknown as T;
  }, []);

  return {addContext, removeContext, getContext};
};

type PaginationContextProviderProps = {
  children: ReactNode;
};

export const PaginationContextProvider = ({
  children,
}: PaginationContextProviderProps) => {
  const [contexts, setContexts] = useState<PaginationType[]>([]);

  return (
    <PaginationContextContext.Provider value={{contexts, setContexts}}>
      {children}
    </PaginationContextContext.Provider>
  );
};

export default usePaginationContext;
