import {getLocalStorage} from './Browser';

export const set = (key: string, value: unknown) => {
  const storage = getLocalStorage();
  if (!storage) return;

  storage.setItem(key, JSON.stringify(value));
};

export const get = <T = unknown>(key: string) => {
  const storage = getLocalStorage();
  if (!storage) return null;

  const value = storage.getItem(key);

  try {
    if (value) {
      return JSON.parse(value) as T;
    }
  } catch (e) {
    console.warn(e);
  }

  return null;
};

export const remove = (key: string) => {
  const storage = getLocalStorage();
  if (!storage) return;

  storage.removeItem(key);
};
