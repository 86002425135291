import {useEffect} from 'react';

type UseNoRampProps = {
  event: 'onPayment';
  handler: <T = any>(data: T) => void;
};

const useNoRampEvent = ({event, handler}: UseNoRampProps) => {
  useEffect(() => {
    const eventName = `noramp:${event}`;

    const onHandler = (e: any) => {
      if (eventName !== e.data.event) return;

      handler(e.data.detail);
    };

    window.addEventListener('message', onHandler, false);

    return () => {
      window.removeEventListener('message', onHandler, false);
    };
  }, [event, handler]);
};

export default useNoRampEvent;
