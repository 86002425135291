import {
  createContext,
  useMemo,
  useContext,
  useRef,
  useState,
  useEffect,
  useCallback,
  type ReactNode,
  type MouseEvent,
  type Dispatch,
  type SetStateAction,
} from 'react';

import {createPortal} from 'react-dom';

import useIsMounted from 'hooks/core/useIsMounted';

import {cn} from 'lib/utils';

import {useModals} from './Modals';

export type UseSetModalClosed =
  | Dispatch<SetStateAction<boolean>>
  | ((e: boolean) => void);

export type UseSetModalClosedReturn = ((animation?: boolean) => void) | null;

export type ModalItemTypeId = string;
export type ModalItemTypeContent = ReactNode;

export type ModalItemType = {
  id: ModalItemTypeId;
};

export type ModalProps = {
  children?: ReactNode;
  className?: string;
  setClosing?: () => void;
  setClosed?: UseSetModalClosed;
};

export type ModalContextProps = {
  setClosed: UseSetModalClosedReturn;
};

const ModalContext = createContext<ModalContextProps>({
  setClosed: null,
});

const randomId = () => '_' + Math.random().toString(36).substr(2, 9);

const Modal = ({
  children,
  className,
  setClosed: setClosedProp,
  setClosing: setClosingProp,
}: ModalProps) => {
  const {remove, add} = useModals();
  const isMounted = useIsMounted();
  const modalId = useMemo(() => randomId(), []);
  const containerRef = useRef<HTMLDivElement>(null);
  const lastClickRef = useRef<HTMLDivElement | null>(null);

  const [closing, setClosing] = useState(false);

  const doModalFadeOut = useCallback(
    (animation?: boolean) => {
      setTimeout(
        () => {
          remove(modalId);

          if (setClosedProp && isMounted()) {
            setClosedProp(false);
          }
        },
        animation ? 250 : 0,
      );
    },
    [isMounted, setClosedProp, remove, modalId],
  );

  const setClosed = useCallback(
    (animation?: boolean) => {
      if (isMounted()) {
        setClosing(true);
        doModalFadeOut(animation);
      } else {
        remove(modalId);
      }
    },
    [setClosing, doModalFadeOut, isMounted, remove, modalId],
  );

  const onMouseDown = useCallback((e: MouseEvent) => {
    lastClickRef.current = e.target as HTMLDivElement;
  }, []);

  const onMouseUp = useCallback(() => {
    if (closing || lastClickRef.current !== containerRef.current) {
      return;
    }

    setClosed(true);

    if (setClosingProp && isMounted()) {
      setClosingProp();
    }
  }, [setClosed, closing, setClosingProp, isMounted]);

  const [closingState, setClosingState] = useState(false);

  const setModal = useCallback(
    (forceClosing?: boolean) => {
      setClosingState(closing || forceClosing || !isMounted());

      add({id: modalId});
    },
    [add, isMounted, closing, modalId],
  );

  useEffect(() => {
    setModal(closing);
  }, [setModal, closing]);

  useEffect(() => {
    return () => {
      remove(modalId);
    };
  }, [remove, modalId]);

  return createPortal(
    <ModalContext.Provider value={{setClosed}}>
      <div
        data-testid="modal-container"
        ref={containerRef}
        onMouseUp={onMouseUp}
        onMouseDown={onMouseDown}
        className={` ${cn('flex fixed left-0 top-0', className?.split(' '), {
          closing: closingState,
        })} ${cn('modal', {
          closing: closingState,
        })}`}>
        {children}
      </div>
    </ModalContext.Provider>,
    document.getElementById('modals-list') || document.body,
  );
};

export const useSetModalClosed = (): UseSetModalClosedReturn => {
  return useContext(ModalContext).setClosed;
};

export const useIsModal = (): boolean => {
  return !!useContext(ModalContext).setClosed;
};

export default Modal;
