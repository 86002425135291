// import 'styles/globals.scss';
import 'styles/globals.css';

import {useEffect} from 'react';

import {datadogRum} from '@datadog/browser-rum';
import {Analytics} from '@vercel/analytics/react';
import type {AppProps} from 'next/app';
import Head from 'next/head';
import {useRouter} from 'next/router';
import Script from 'next/script';
import Favicon from 'public/favicon.png';
import * as fbq from 'src/lib/fpixel';

import {IS_STAGING} from 'constants/all';

import App from 'components/App';
import {ThemeProvider} from 'components/ui/ThemeProvider';
import {Toaster} from 'components/v3/toast/toaster';

datadogRum.init({
  applicationId: 'c2b5b680-db57-4bc4-bbc4-7270ba336b02',
  clientToken: 'pubc4bf3fc17c1a9153c61de76c6a3c9498',
  site: 'us5.datadoghq.com',
  service: 'noramp-dashboard',
  env: process.env.NEXT_PUBLIC_DEV ? 'dev' : 'prod',
  // Specify a version number to identify the deployed version of your application in Datadog
  // version: '1.0.0',
  sessionSampleRate: 100,
  // premiumSampleRate: 100,
  trackUserInteractions: true,
  defaultPrivacyLevel: 'mask-user-input',
});

datadogRum.startSessionReplayRecording();

const MyApp = ({Component, pageProps}: AppProps) => {
  const router = useRouter();

  useEffect(() => {
    if (IS_STAGING) return;
    fbq.pageview();

    const handleRouteChange = () => {
      fbq.pageview();
    };

    router.events.on('routeChangeComplete', handleRouteChange);
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [router.events]);

  return (
    <>
      <Head>
        <meta name="viewport" content="width=device-width, user-scalable=no" />
        <meta name="cf-2fa-verify" content="f0a0ef30eba2981" />
        <link rel="shortcut icon" href={Favicon.src} />
      </Head>

      {!IS_STAGING && (
        <Script
          id="fb-pixel"
          strategy="afterInteractive"
          dangerouslySetInnerHTML={{
            __html: `
              !function(f,b,e,v,n,t,s)
              {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
              n.callMethod.apply(n,arguments):n.queue.push(arguments)};
              if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
              n.queue=[];t=b.createElement(e);t.async=!0;
              t.src=v;s=b.getElementsByTagName(e)[0];
              s.parentNode.insertBefore(t,s)}(window, document,'script',
              'https://connect.facebook.net/en_US/fbevents.js');
              fbq('init', ${fbq.FB_PIXEL_ID});
            `,
          }}
        />
      )}
      <ThemeProvider
        attribute="class"
        defaultTheme="light"
        enableSystem
        disableTransitionOnChange>
        <App>
          <Component {...pageProps} />
          <Analytics />
        </App>
        <Toaster />
      </ThemeProvider>
    </>
  );
};

// MyApp.getInitialProps = async ({ctx}: AppContext) => {
//   return {
//     userAgent: ctx.req?.headers['user-agent'],
//   };
// };

export default MyApp;
