import {useCallback} from 'react';

import {AppDto} from 'types/Dto';

import PAGE from 'constants/page';

import {createApp} from 'api/apps/apps';

import useHandleError from 'hooks/core/useHandleError';
import useNetwork from 'hooks/use-network';
import useApps from 'hooks/useApps';

import {useRedirect} from 'components/App/Redirect';
import {Button} from 'components/v3/button';

import {useCreateApp} from './CreateAppProvider';

const SubmitField = ({
  onCreate,
  onClick,
}: {
  onCreate?: (app: AppDto) => any;
  onClick?: () => void;
}) => {
  const {setSelectedApp, refetch} = useApps();
  const {isTestnet} = useNetwork();
  const {name, type, appFee, setWorking} = useCreateApp();

  const isEnabled = name && type && (type !== 'marketplace' || appFee);

  const handleError = useHandleError();

  const redirect = useRedirect();

  const onPressCreate = useCallback(async () => {
    if (!name || !type) return;

    setWorking(true);

    try {
      const app = await createApp({
        name,
        type,
        use_explorer: isTestnet,
        app_fee: appFee ?? 0,
      });

      if (!onCreate) {
        await refetch();
        setSelectedApp(app);

        redirect({
          to: PAGE.APPS.NEW_APP,
        });
      } else {
        onCreate(app);
      }
    } catch (e) {
      handleError(e);
      setWorking(false);
    }
  }, [
    name,
    type,
    setWorking,
    isTestnet,
    appFee,
    onCreate,
    refetch,
    setSelectedApp,
    redirect,
    handleError,
  ]);

  // if (view !== 'confirm') return null;

  return (
    <div className={'w-full'}>
      <Button
        disabled={!isEnabled}
        onClick={() => {
          onPressCreate();
          onClick?.();
        }}
        className="w-full my-4 text-sm font-light h-10 rounded-lg"
        // label="Create"
        // working={working}
      >
        Create
      </Button>
    </div>
  );
};

export default SubmitField;
