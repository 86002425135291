import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
  type Dispatch,
  type SetStateAction,
} from 'react';

import {
  getAuth,
  isSignInWithEmailLink,
  signInWithEmailLink,
} from 'firebase/auth';
import {useTranslation} from 'react-i18next';

import type {ErrorDto} from 'types/Dto/Error';

import {EVENTS} from 'constants/mixpanel';
import PAGE from 'constants/page';

import useSession from 'hooks/core/useSession';
import useMixpanel from 'hooks/useMixpanel';
import useCurrentUserStatus from 'hooks/user/useCurrentUserStatus';

import Invite from 'components/Invite';
import NoRampIcon from 'components/v2/icons/NorampIcon';
import {useToast} from 'components/v3/toast/use-toast';

import Redirect from '../Redirect';
import AuthForm from './AuthForm/AuthForm';
import AuthSentConfirmation from './AuthSentConfirmation/AuthSentConfirmation';

export type ViewType = 'loading' | 'auth' | 'email-sent' | 'invite';

export type FirebaseError = {
  code: string;
};

const cancelledCodes = [
  'auth/popup-closed-by-user',
  'auth/cancelled-popup-request',
  'auth/user-cancelled',
];

type AuthContextProps = {
  setLoading: Dispatch<SetStateAction<boolean>>;
};
const AuthContext = createContext<AuthContextProps>({
  setLoading: () => {
    /* do nothing */
  },
});

type AuthProps = {
  title: string;
  invite?: boolean;
};

const Auth = ({title, invite = false}: AuthProps) => {
  const initial_view = invite ? 'invite' : 'auth';

  const [view, setView] = useState<ViewType>('loading');
  const userStatus = useCurrentUserStatus();
  const [loading, setLoading] = useState(false);
  const {track} = useMixpanel();
  const {doLogin} = useSession();
  const {toast} = useToast();

  const {t} = useTranslation();

  const email = useMemo(
    () =>
      typeof window !== 'undefined'
        ? window?.localStorage?.getItem('emailForSignIn') ?? null
        : null,
    [],
  );

  const [userEmail, setUserEmail] = useState<string | null>(email);

  const [dots, setDots] = useState('');

  useEffect(() => {
    const interval = setInterval(() => {
      setDots(prevDots => {
        if (prevDots.length === 3) {
          return '';
        } else {
          return prevDots + '.';
        }
      });
    }, 500);

    return () => clearInterval(interval);
  }, []);

  const onEmailSent = useCallback((email: string) => {
    setView('email-sent');
    setUserEmail(email);
  }, []);

  useEffect(() => {
    setView(email ? 'loading' : initial_view);
  }, [email, initial_view]);

  const isAuthenticating = useRef(false);

  useEffect(() => {
    if (view !== 'loading') return;

    (async () => {
      const auth = getAuth();

      if (!email || !isSignInWithEmailLink(auth, window.location.href)) {
        setView(initial_view);
        return;
      }

      if (isAuthenticating.current) return;

      try {
        isAuthenticating.current = true;

        const result = await signInWithEmailLink(
          auth,
          email,
          window.location.href,
        );
        console.log(result);

        window.localStorage.removeItem('emailForSignIn');

        const {is_new} = await doLogin({firebaseUser: result.user});

        track(EVENTS.LOGIN);

        if (is_new) {
          track(EVENTS.SIGN_UPS);
        }
      } catch (err) {
        console.error(err);

        // setNotification({
        //   type: 'error',
        //   msg: [
        //     `errors.${(e as FirebaseError)?.code}`,
        //     `errors.${(e as ErrorDto).message}`,
        //     'pages.login.error',
        //   ],
        // });

        toast({
          variant: 'destructive',
          title: 'Error',
          description: t([
            `errors.${(err as FirebaseError)?.code}`,
            `errors.${(err as ErrorDto).message}`,
            'pages.login.error',
          ]),
        });

        setView(initial_view);
      } finally {
        isAuthenticating.current = false;
      }
    })();
  }, [doLogin, email, toast, t, view, track, invite, initial_view]);

  if (userStatus === 'logged') {
    if (invite && view !== 'invite') {
      setView('invite');
      return;
    }
    if (!invite) {
      return <Redirect to={PAGE.APPS.HOME} replace />;
    }
  }

  return (
    <AuthContext.Provider value={{setLoading}}>
      <div className="flex flex-col items-center noramp-bg z-50 px-6 mt-8 max-w-[336px] w-full">
        {loading ? (
          <div className="flex flex-col items-center justify-center py-[192px] px-16">
            <div className="animate-pulse">
              <NoRampIcon />
            </div>
            <div className="flex items-center w-full gap-1 p-4">
              Authenticating
              <div className="w-4">{dots}</div>
            </div>
          </div>
        ) : (
          <div className="">
            {/* <pre>{JSON.stringify({invite, view, initial_view}, null, 2)}</pre> */}
            {view === 'auth' && (
              <AuthForm title={title} onEmailSent={onEmailSent} />
            )}

            {view === 'email-sent' && (
              <div className="flex flex-col items-center justify-center">
                <AuthSentConfirmation userEmail={userEmail} />
              </div>
            )}

            {view === 'loading' && (
              <div className="flex flex-col items-center justify-center px-16 pt-8 pb-16">
                <div className="animate-pulse">
                  <NoRampIcon />
                </div>
              </div>
            )}

            {view === 'invite' && <Invite setView={setView} />}
          </div>
        )}
      </div>
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);

export const isCancelledCode = (err: FirebaseError) =>
  cancelledCodes.indexOf(err.code) !== -1;

export default Auth;
