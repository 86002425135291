import {useMemo} from 'react';

import {getBaseUrl} from 'utils/Url';

const useBaseUrl = () => {
  const baseUrl = useMemo(() => {
    return getBaseUrl();
  }, []);

  return baseUrl;
};

export default useBaseUrl;
