import classNames from 'classnames';
import type {IconType as ReactIconType} from 'react-icons';

import NodeRender, {NodeType} from 'components/Common/NodeRender';

export type IconType = string | NodeType | (() => JSX.Element) | ReactIconType;

export type IconProps = {
  icon: IconType;
  className?: string;
};

const Icon = ({icon, className}: IconProps) => {
  if (typeof icon === 'string') {
    return (
      <div
        className={classNames('flex items-center justify-center', className)}
      />
    );
  } else if (icon) {
    return <NodeRender node={icon} />;
  }

  return <>{icon}</>;
};

export default Icon;
