'use client';

import * as React from 'react';
import {useCallback, useMemo, useRef, useState} from 'react';

import {CaretSortIcon, CheckIcon, PlusCircledIcon} from '@radix-ui/react-icons';
import Image from 'next/image';

import type {AppDto} from 'types/Dto';

import useOnClickOutside from 'hooks/core/useOnClickOutside';
import useApps from 'hooks/useApps';
import useCurrentApp from 'hooks/useCurrentApp';

import {cn} from 'lib/utils';

import {Avatar, AvatarImage} from 'components/ui/avatar';
import {Button} from 'components/ui/button';
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
  CommandSeparator,
} from 'components/ui/command';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from 'components/ui/dialog';
import {Label} from 'components/ui/label';
import {Popover, PopoverContent, PopoverTrigger} from 'components/ui/popover';

import CreateAppProvider from '../CreateApp/CreateAppProvider';
import NameField from '../CreateApp/NameField';
import SubmitField from '../CreateApp/SubmitField';
import TypeField from '../CreateApp/TypeField';

type AppButtonProps = {
  app: AppDto;
  onPress: (app: AppDto) => void;
};

const AppButton = ({app, onPress}: AppButtonProps) => {
  const {selectedApp, setSelectedApp} = useApps();
  const {name} = app;

  const onPressApp = useCallback(() => {
    setSelectedApp(app);
    onPress(app);
  }, [setSelectedApp, onPress, app]);

  return (
    <CommandItem
      key={app.id}
      onSelect={() => {
        onPressApp();
      }}
      className="text-sm">
      <Avatar className="w-5 h-5 mr-2">
        <Image
          src={`https://avatar.vercel.sh/${app.id}.png`}
          alt={app.name}
          className=""
          width={20}
          height={20}
        />
      </Avatar>
      {app.name}
      <CheckIcon
        className={cn(
          'ml-auto h-4 w-4',
          name === selectedApp?.name ? 'opacity-100' : 'opacity-0',
        )}
      />
    </CommandItem>
  );
};

type PopoverTriggerProps = React.ComponentPropsWithoutRef<
  typeof PopoverTrigger
>;

interface TeamSwitcherProps extends PopoverTriggerProps {
  className?: string;
  onCreate?: (app: AppDto) => any;
}

export const AppSwitcher = ({className, onCreate}: TeamSwitcherProps) => {
  const [showNewTeamDialog, setShowNewTeamDialog] = React.useState(false);

  const mainContainerRef = useRef<HTMLDivElement>(null);

  const [open, setOpen] = useState(false);

  const onPress = useCallback(() => {
    setOpen(state => !state);
  }, []);

  useOnClickOutside(
    mainContainerRef,
    useCallback(() => setOpen(false), []),
  );
  const currentApp = useCurrentApp();
  const {apps} = useApps();

  const renderApps = useMemo(
    () => apps, // .filter(a => a.id !== currentApp?.id)
    [apps],
  );

  if (!renderApps.length) return null;

  return (
    <Dialog
      open={showNewTeamDialog}
      onOpenChange={setShowNewTeamDialog}
      //   ref={mainContainerRef}
    >
      <Popover open={open} onOpenChange={setOpen}>
        <PopoverTrigger asChild>
          <Button
            variant="outline"
            role="combobox"
            aria-expanded={open}
            aria-label="Select a team"
            className={`noramp-sidebar-btn bg-[#fafafafa] dark:bg-[#000] ${className}`}>
            <Avatar className="w-5 h-5 mr-2">
              <AvatarImage
                src={`https://avatar.vercel.sh/${currentApp?.id}.png`}
                alt={currentApp?.name}
              />
            </Avatar>
            {currentApp?.name}
            <CaretSortIcon className="w-4 h-4 ml-auto opacity-50 shrink-0" />
          </Button>
        </PopoverTrigger>
        <PopoverContent className="w-full p-0">
          <Command>
            <CommandList>
              <CommandInput placeholder="Search App..." />
              <CommandEmpty>No App found.</CommandEmpty>
              <div className="py-1">
                {renderApps.map(app => {
                  console.log(app.id);
                  return <AppButton key={app.id} app={app} onPress={onPress} />;
                })}
              </div>
            </CommandList>
            <CommandSeparator />
            <CommandList>
              <CommandGroup>
                <DialogTrigger asChild>
                  <CommandItem
                    onSelect={() => {
                      setOpen(false);
                      setShowNewTeamDialog(true);
                    }}
                    className={'cursor-pointer'}>
                    {/* <CreateAppButton onPress={onPress} /> */}
                    <PlusCircledIcon className="w-5 h-5 mr-2" />
                    Create App
                  </CommandItem>
                </DialogTrigger>
              </CommandGroup>
            </CommandList>
          </Command>
        </PopoverContent>
      </Popover>
      <DialogContent>
        <CreateAppProvider>
          <DialogHeader>
            <DialogTitle>Create App</DialogTitle>
            <DialogDescription>Add a new app to sell NFTs.</DialogDescription>
          </DialogHeader>
          <div>
            <div className="py-2 pb-4 space-y-4">
              <div className="space-y-2 ">
                <Label htmlFor="name" className=" font-bold">
                  App name
                </Label>
                <NameField />
              </div>
              <div className="space-y-2">
                <Label htmlFor="plan" className=" font-bold">
                  App Type
                </Label>
                <TypeField />
              </div>
            </div>
          </div>
          <div className="flex flex-col">
            <SubmitField
              onCreate={onCreate}
              onClick={() => {
                setShowNewTeamDialog(false);
              }}
            />
            <Button
              variant="outline"
              onClick={() => setShowNewTeamDialog(false)}>
              Cancel
            </Button>
          </div>
        </CreateAppProvider>
      </DialogContent>
    </Dialog>
  );
};
