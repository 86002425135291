import {useMemo} from 'react';

import {useRouter} from 'next/router';

const useIsAdminPages = () => {
  const route = useRouter();

  const isEmbed = useMemo(
    () => !!route?.asPath?.startsWith('/admin'),
    [route?.asPath],
  );

  return isEmbed;
};

export default useIsAdminPages;
