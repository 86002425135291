import {useCallback, useEffect} from 'react';

import {useQuery} from '@tanstack/react-query';

import {AppDto} from 'types/Dto';

import {get, set} from 'utils/Storage';

import {fetchApps} from 'api/apps/apps';

import {useApp} from 'components/App/AppContext';

import useCurrentUser from './user/useCurrentUser';
import useUserHasRoles from './user/useUserHasRoles';

const LAST_SELECTED_KEY = 'last_selected_app_key';

const useApps = () => {
  const currentUser = useCurrentUser();
  const hasAdminAccess = useUserHasRoles('admin', 'staff');

  const {selectedApp, setSelectedApp: setSelectedAppBase} = useApp();

  const {data, isLoading, refetch} = useQuery({
    queryKey: [`apps/${currentUser?.id ?? 'anon'}`],
    queryFn: () => {
      if (!currentUser) {
        return [];
      }

      return fetchApps();
    },
  });

  const isAdminAccess = useCallback(
    (app: AppDto) => {
      return !!(hasAdminAccess && (app.status === 'deleted' || !app.is_member));
    },
    [hasAdminAccess],
  );

  const hasAccess = useCallback(
    (app: AppDto) => {
      return !!(hasAdminAccess || app?.is_member);
    },
    [hasAdminAccess],
  );

  const setLastSelected = useCallback((app: AppDto | null) => {
    set(LAST_SELECTED_KEY, app?.id ?? null);
  }, []);

  const getLastSelected = useCallback(() => {
    return get<string>(LAST_SELECTED_KEY);
  }, []);

  const setSelectedApp = useCallback(
    (app: AppDto | null) => {
      setSelectedAppBase(app);
      setLastSelected(app);
    },
    [setSelectedAppBase, setLastSelected],
  );

  // reset selected app if no data (initial)
  useEffect(() => {
    if (!data) {
      setSelectedAppBase(null);
    }
  }, [setSelectedAppBase, data]);

  return {
    initial: !data,
    apps: data ?? [],
    loading: isLoading,
    refetch,
    selectedApp,
    hasAccess,
    isAdminAccess,
    setSelectedApp,
    setLastSelected,
    getLastSelected,
  };
};

export default useApps;
