import Logo from '../../Logo';

const HeaderTitle = () => {
  return (
    <div className="flex items-center">
      <Logo />
    </div>
  );
};

export default HeaderTitle;
