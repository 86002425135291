import {
  createContext,
  useEffect,
  useState,
  useCallback,
  useContext,
  type ReactNode,
} from 'react';

import classNames from 'classnames';
import {createPortal} from 'react-dom';

import {ModalItemType, ModalItemTypeId} from '.';

type AddModal = (modal: ModalItemType) => void;
type RemoveModal = (idToRemove: ModalItemTypeId) => void;

type ModalsProps = {
  children?: ReactNode;
};

type ModalsContextProps = {
  add: AddModal;
  remove: RemoveModal;
};

const ModalsContext = createContext<ModalsContextProps>({
  add: () => {
    /* do nothing */
  },
  remove: () => {
    /* do nothing */
  },
});

const Modals = ({children}: ModalsProps) => {
  const [modals, setModals] = useState<ModalItemType[]>([]);
  const blockScroll = !!modals.length;

  useEffect(() => {
    document.body.style.overflow = blockScroll ? 'hidden' : 'auto';
  }, [blockScroll]);

  const add = useCallback<AddModal>(
    ({id}: ModalItemType) => {
      setModals(state => {
        const modals = [...state];
        const item = {id};

        const idx = modals.findIndex(({id}) => id === item.id);

        if (idx !== -1) {
          modals.splice(idx, 1, item);
          return [...modals];
        }

        return [...modals, item];
      });
    },
    [setModals],
  );

  const remove = useCallback<RemoveModal>(
    (idToRemove: ModalItemTypeId) => {
      setModals(state => {
        const modals = [...state];
        return [...modals.filter(({id}) => id !== idToRemove)];
      });
    },
    [setModals],
  );

  const renderPortal = () => {
    return typeof document === 'undefined' ? (
      <div id="modals-list" className={classNames('')} />
    ) : (
      createPortal(
        <div id="modals-list" className={classNames('')}></div>,
        document?.body,
      )
    );
  };

  return (
    <ModalsContext.Provider value={{add, remove}}>
      {children}
      {renderPortal()}
    </ModalsContext.Provider>
  );
};

export const useModals = () => {
  return useContext(ModalsContext);
};

export default Modals;
