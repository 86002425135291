import {useApp} from 'components/App/AppContext';

const useCurrentUserStatus = () => {
  const {
    user: {status},
  } = useApp();

  return status;
};

export default useCurrentUserStatus;
