'use client';

import {useCallback, useState} from 'react';

import {ChevronDownIcon} from '@radix-ui/react-icons';
import classNames from 'classnames';
import Link from 'next/link';
import {FiLogOut, FiUser} from 'react-icons/fi';
import {MdAdminPanelSettings} from 'react-icons/md';

import useSession from 'hooks/core/useSession';
import useUserHasRoles from 'hooks/user/useUserHasRoles';

import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from 'components/ui/dropdown-menu';

export function UserMenu() {
  const hasPrivilegedRole = useUserHasRoles('admin', 'staff', 'analyst');

  const {doLogout} = useSession();

  const onPressLogout = useCallback(async () => {
    await doLogout();
  }, [doLogout]);

  const [isOpen, setIsOpen] = useState(false);
  const classes = classNames(
    'h-[1.2rem] w-[1.2rem] rotate-0 noramp-text scale-100 transition-all  ',
    {
      'rotate-0': !isOpen,
      'rotate-180': isOpen,
    },
  );

  return (
    <DropdownMenu onOpenChange={setIsOpen}>
      <DropdownMenuTrigger asChild>
        <ChevronDownIcon className={classes} />
      </DropdownMenuTrigger>
      <DropdownMenuContent align="end">
        <Link href="/settings">
          <DropdownMenuItem onClick={() => {}}>
            <FiUser className="mr-2 text-sm" /> Profile
          </DropdownMenuItem>
        </Link>
        {hasPrivilegedRole && (
          <Link href="/admin">
            <DropdownMenuItem onClick={() => {}}>
              <MdAdminPanelSettings className="mr-2 text-sm" />
              Admin Panel
            </DropdownMenuItem>
          </Link>
        )}
        <DropdownMenuItem onClick={onPressLogout}>
          <FiLogOut className="mr-2 text-sm" />
          Logout
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
