import {MAIL_TO_HELLO} from 'constants/all';
import PAGE from 'constants/page';

import Link from 'components/Common/Link';

import Copyright from '../Copyright';
import SocialIcons from '../SocialIcons';

export const FOOTER_TEST_ID = 'footer-container';

const Footer = () => {
  return null;
  return (
    <footer className="flex p-4" data-testid={FOOTER_TEST_ID}>
      <div className="">
        <div className="">
          <Link to={PAGE.DOCS} external label="Documentation" />
          <Link to={PAGE.LEGAL.HOME} label="Legal" />
          <Link
            external
            target="_blank"
            to={`mailto:${MAIL_TO_HELLO}`}
            label={MAIL_TO_HELLO}
            className=""
          />
        </div>

        <div className="">
          <Copyright className="" />
          <SocialIcons className="" />
        </div>
      </div>
    </footer>
  );
};

export default Footer;
