import {Dispatch, SetStateAction, useCallback, useEffect} from 'react';

import {useQuery} from '@tanstack/react-query';
import {useRouter} from 'next/router';

import {remove, set} from 'utils/Storage';

import {INVITE_TOKEN_KEY} from 'constants/all';

import {acceptInvite, fetchInvite} from 'api/invite';

import useHandleAction from 'hooks/core/useHandleAction';
import useApps from 'hooks/useApps';
import useCurrentUser from 'hooks/user/useCurrentUser';

import {ViewType} from 'components/App/Auth';
import {useRedirect} from 'components/App/Redirect';
import Card from 'components/Misc/Card/Card';
import CardMessage from 'components/Misc/CardMessage/CardMessage';
import NoRampIcon from 'components/v2/icons/NorampIcon';
import {Button} from 'components/v3/button';
import {useToast} from 'components/v3/toast/use-toast';

type InviteProps = {
  setView: Dispatch<SetStateAction<ViewType>>;
};

const Invite = ({setView}: InviteProps) => {
  const {query} = useRouter();
  const token = query.token?.toString() ?? '';
  const {data, isLoading, error, isFetched, isSuccess, isError} = useQuery({
    queryKey: ['invite', token],
    queryFn: () => fetchInvite(token),

    retry: false,
  });

  const redirect = useRedirect();

  useEffect(() => {
    if (isSuccess) {
      set(INVITE_TOKEN_KEY, token);
    }
  }, [isSuccess, token]);

  useEffect(() => {
    if (isError) {
      remove(INVITE_TOKEN_KEY);
      redirect({to: '', replace: true});
    }
  }, [isError, redirect, token]);

  const {setSelectedApp, refetch} = useApps();

  const currentUser = useCurrentUser();
  const handleAction = useHandleAction();
  const {toast} = useToast();

  const onClickAccept = useCallback(() => {
    handleAction(async () => {
      try {
        const member = await acceptInvite(token);
        await refetch();
        setSelectedApp(member.app);
        remove(INVITE_TOKEN_KEY);
        redirect({});

        toast({
          variant: 'success',
          title: 'Invite accepted',
        });
      } catch (e) {
        console.log(e);

        remove(INVITE_TOKEN_KEY);
      }
    });
  }, [handleAction, token, refetch, setSelectedApp, redirect, toast]);

  const onClickReject = useCallback(() => {
    handleAction(async () => {
      remove(INVITE_TOKEN_KEY);
      redirect({});
    });
  }, [handleAction, redirect]);

  const onClickLogin = useCallback(() => {
    setView('auth');
  }, [setView]);

  const renderContent = () => {
    if (!isFetched && isLoading) {
      return (
        <div className="flex flex-col items-center justify-center px-16">
          <div className="animate-pulse">
            <NoRampIcon />
          </div>
          <div className="flex items-center w-full gap-1 p-4">Loading</div>
        </div>
      );
    }

    if (!data || error) {
      return <CardMessage message="Invalid or expired invite" />;
    }

    return (
      <div className="flex items-center justify-center">
        <Card
          className="z-20 w-[336px] flex justify-center items-center h-[350px] p-8 rounded-xl gap-8"
          padding={40}>
          <NoRampIcon />
          <div>
            <span className="font-semibold">
              &quot;{data?.invited_by?.first_name}&quot;
            </span>
            {` invited you to join to "${data?.app.name}"`}
          </div>

          {currentUser ? (
            <div className="flex flex-col w-full gap-2">
              <Button className="" onClick={onClickAccept}>
                Accept Invite
              </Button>

              <Button
                variant="soft"
                color="danger"
                className=""
                onClick={onClickReject}>
                Reject Invite
              </Button>
            </div>
          ) : (
            <Button className="" onClick={onClickLogin}>
              Login
            </Button>
          )}
        </Card>
      </div>
    );
  };

  return (
    <div className="z-0 flex flex-col items-center justify-center flex-1 h-full">
      {renderContent()}
    </div>
  );
};

export default Invite;
