import {useCallback} from 'react';

import {
  GoogleAuthProvider,
  OAuthProvider,
  fetchSignInMethodsForEmail,
  getAuth,
  linkWithCredential,
  signInWithPopup,
} from 'firebase/auth';
import {useTranslation} from 'react-i18next';
import {FcGoogle} from 'react-icons/fc';

import type {ErrorDto} from 'types/Dto/Error';

import {EVENTS} from 'constants/mixpanel';

import useSession from 'hooks/core/useSession';
import useMixpanel from 'hooks/useMixpanel';

import {Button} from 'components/v3/button';
import {useToast} from 'components/v3/toast/use-toast';

import {isCancelledCode, useAuth, type FirebaseError} from '../..';
import {SUPPORTED_POPUP_SIGN_IN_METHODS, getProvider} from '../utils';

const GoogleAuthButton = () => {
  const {track} = useMixpanel();
  const {toast} = useToast();
  const {setLoading} = useAuth();
  const {doLogin} = useSession();
  const {t} = useTranslation();

  const onClick = useCallback(async () => {
    const auth = getAuth();
    try {
      const provider = new GoogleAuthProvider();

      const result = await signInWithPopup(auth, provider);

      setLoading(true);

      const {is_new} = await doLogin({firebaseUser: result.user});

      track(EVENTS.LOGIN);

      if (is_new) {
        track(EVENTS.SIGN_UPS);
      }
    } catch (err: any) {
      if (
        err?.customData?.email &&
        err.code === 'auth/account-exists-with-different-credential'
      ) {
        const providers = await fetchSignInMethodsForEmail(
          auth,
          err?.customData?.email,
        );

        let firstPopupProviderMethod = providers.find(p =>
          SUPPORTED_POPUP_SIGN_IN_METHODS.includes(p as any),
        );

        if (!firstPopupProviderMethod) {
          const verifiedProvider =
            err?.customData?._tokenResponse?.verifiedProvider ?? [];

          firstPopupProviderMethod = verifiedProvider.find((p: any) =>
            SUPPORTED_POPUP_SIGN_IN_METHODS.includes(p),
          );
        }

        if (!firstPopupProviderMethod) {
          throw new Error(
            `Your account is linked to a provider that isn't supported.`,
          );
        }

        const linkedProvider = getProvider(firstPopupProviderMethod);
        linkedProvider.setCustomParameters({
          login_hint: err?.customData?.email,
        });

        const result = await signInWithPopup(auth, linkedProvider);

        const credential = OAuthProvider.credentialFromError(err);

        if (!credential) {
          return;
        }

        await linkWithCredential(result.user, credential);

        const {is_new} = await doLogin({firebaseUser: result.user});
        track(EVENTS.LOGIN);

        if (is_new) {
          track(EVENTS.SIGN_UPS);
        }

        setLoading(false);

        return;
      }
      setLoading(false);

      if (isCancelledCode(err as FirebaseError)) {
        return;
      }

      console.error(err);
      toast({
        variant: 'destructive',
        title: 'Error',
        description: t([
          `errors.${(err as FirebaseError)?.code}`,
          `errors.${(err as ErrorDto).message}`,
          'pages.login.error',
        ]),
      });
    }
  }, [setLoading, doLogin, track, toast, t]);

  return (
    <Button
      // className={classNames(
      //   'dark-blue',

      //   'w-72 ring-[0.5px] ring-zinc-500 flex items-center justify-center bg-nr-gray-950 px-8 py-2',
      // )}
      className="w-full border py-6 bg-gray-300 rounded-lg"
      variant="soft"
      onClick={onClick}>
      <div className="flex items-center gap-2 noramp-text font-normal text-sm">
        <FcGoogle className="" /> Continue with Google
      </div>
    </Button>
  );
};

export default GoogleAuthButton;
