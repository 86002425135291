import type {NextPage} from 'next';
import Link from 'next/link';

import Page from 'components/App/Page';
import Locale from 'components/Common/Locale';
import {Button} from 'components/v3/button';

const NotFound: NextPage = () => {
  return (
    <Page noAuth title="pages.not_found.title" padding>
      <div className="flex flex-col justify-center gap-10 mt-10 text-center">
        <p className="font-normal">
          <Locale text="pages.not_found.msg" />
        </p>

        <Link href="/">
          <Button>
            <Locale text="pages.not_found.button" />
          </Button>
        </Link>
      </div>
    </Page>
  );
};

export default NotFound;
