import classNames from 'classnames';

import type {StatusColor} from 'types/Misc';

type StatusDotProps = {
  color: StatusColor | null;
  size?: number;
};

const StatusDot = ({color, size}: StatusDotProps) => {
  if (!color) return null;

  return (
    <div
      style={{...(size && {width: `${size}px`, height: `${size}px`})}}
      className={classNames(
        'bg-white w-1.5 h-1.5 rounded-full', // Base styles for the dot
        color === 'yellow' && 'bg-yellow-400', // Assuming 'yellow', 'green', etc., are the color values
        color === 'green' && 'bg-green-400',
        color === 'red' && 'bg-red-400',
      )}
    />
  );
};

export default StatusDot;
