import {type ReactNode} from 'react';

import type {ErrorDto} from 'types/Dto/Error';

import {parseError} from 'utils/Error';

import Locale from 'components/Common/Locale';

type ErrorProps = {
  msg?: string | string[] | ReactNode;
  error?: string | string[] | ReactNode;
  info?: ErrorDto | string;
  children?: ReactNode;
};

const Error = ({msg, error, info, children}: ErrorProps) => {
  if (info) {
    console.warn(info);
    const errorInfo = parseError(info);
    msg = errorInfo.msg;
    error = errorInfo.error;
  }

  return (
    <div className="center column padding">
      <span>
        {(typeof msg === 'string' || Array.isArray(msg) ? (
          <Locale text={[msg, 'errors.unknown']} />
        ) : (
          msg
        )) || <Locale text="errors.unknown" />}
      </span>
      {error &&
        (typeof error === 'string' || Array.isArray(error) ? (
          <>
            <br />
            <span color="#9d9fa5">
              <Locale text={error} />
            </span>
          </>
        ) : (
          error
        ))}
      {children}
    </div>
  );
};

export default Error;
