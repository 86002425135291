import i18n from 'i18next';
import Locales from 'locales';
import {initReactI18next} from 'react-i18next';

import Config from 'configs/App';

const DEFAULT_LANGUAGE = Config.lang;

i18n.use(initReactI18next).init({
  resources: Locales,

  lng: DEFAULT_LANGUAGE,
  fallbackLng: DEFAULT_LANGUAGE,

  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
