import {useCallback, useEffect, useRef} from 'react';

import {useRouter} from 'next/router';

import useCurrentUserSelector from 'hooks/user/useCurrentUserSelector';
import useLoginRedirect from 'hooks/core/useLoginRedirect';

import {useApp} from 'components/App/AppContext';
import Dialog from 'components/Common/NodeRender/Dialog';

import Auth from '..';

export type SignInModalProps = {
  title?: string;
  subtitle?: string;
};

const SignInModal = () => {
  const {visible} = useSignInModal();

  if (!visible) {
    return null;
  }

  return <Modal />;
};

const Modal = () => {
  const {asPath} = useRouter();
  const openedPathRef = useRef(asPath);
  const {title, hide} = useSignInModal();
  const {status} = useCurrentUserSelector();

  // hide modal if path or user status changed
  useEffect(() => {
    if (status === 'logged' || openedPathRef.current !== asPath) {
      hide();
    }
  }, [hide, status, asPath]);

  return (
    <Dialog setClosed={hide}>
      <div className="relative z-10 py-4 noramp-bg rounded-2xl shadow-md">
        <Auth title={title ?? 'Sign in to continue'} />
      </div>
    </Dialog>
  );
};

export const useSignInModal = () => {
  const {showSignInModal, setShowSignInModal} = useApp();
  const {setRedirect} = useLoginRedirect();

  const show = useCallback(
    (props?: SignInModalProps) => {
      setRedirect();

      setShowSignInModal(props ?? {});
    },
    [setRedirect, setShowSignInModal],
  );

  const hide = useCallback(
    () => setShowSignInModal(null),
    [setShowSignInModal],
  );

  return {
    ...showSignInModal,
    visible: !!showSignInModal,
    show,
    hide,
  };
};

export default SignInModal;
