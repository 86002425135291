import {
  createContext,
  useContext,
  useState,
  type Dispatch,
  type SetStateAction,
  type ReactNode,
} from 'react';

import type {AppDto} from 'types/Dto';

type FormView = 'name' | 'type' | 'confirm';

type CreateAppContextProps = {
  view: 'name' | 'type' | 'confirm';
  setView: Dispatch<SetStateAction<FormView>>;

  name: string | null;
  setName: Dispatch<SetStateAction<string | null>>;

  appFee: number | null;
  setAppFee: Dispatch<SetStateAction<number | null>>;

  type: AppDto['type'] | null;
  setType: Dispatch<SetStateAction<AppDto['type'] | null>>;

  working: boolean;
  setWorking: Dispatch<SetStateAction<boolean>>;
};

const CreateAppContext = createContext<CreateAppContextProps>(
  {} as CreateAppContextProps,
);

type CreateAppProviderProps = {
  children: ReactNode;
};

const CreateAppProvider = ({children}: CreateAppProviderProps) => {
  const [working, setWorking] = useState(false);
  const [view, setView] = useState<FormView>('name');
  const [name, setName] = useState<string | null>(null);
  const [appFee, setAppFee] = useState<number | null>(null);
  const [type, setType] = useState<AppDto['type'] | null>(null);

  return (
    <CreateAppContext.Provider
      value={{
        working,
        setWorking,

        view,
        setView,

        name,
        setName,

        appFee,
        setAppFee,

        type,
        setType,
      }}>
      {children}
    </CreateAppContext.Provider>
  );
};

export const useCreateApp = () => {
  return useContext(CreateAppContext);
};

export default CreateAppProvider;
