import {useCallback, useEffect, useMemo} from 'react';

import {useRouter} from 'next/router';

import {get} from 'utils/Storage';

import {INVITE_TOKEN_KEY} from 'constants/all';
import PAGE from 'constants/page';

import useCurrentUser from 'hooks/user/useCurrentUser';

import {useRedirect} from 'components/App/Redirect';

import useLoginRedirect from '../core/useLoginRedirect';

const useUserCompleteProfile = (redirectToCompletePage?: boolean) => {
  const user = useCurrentUser();
  const {pathname} = useRouter();
  const redirect = useRedirect();
  const {doRedirect: doLoginRedirect} = useLoginRedirect();

  const isRequired = useMemo(() => {
    if (!user) return false;

    const valuesToCheck = [user.first_name, user.last_name, user.email];

    const isRequired = valuesToCheck.some(e => !e);

    return isRequired;
  }, [user]);

  const doRedirect = useCallback(() => {
    if (!user?.id) return false;

    const inviteToken = get(INVITE_TOKEN_KEY);

    // if invite is present, redirect to invite page
    if (inviteToken) {
      // prevent redirect when in complete page
      if (pathname.startsWith('/invite')) {
        return true;
      }

      redirect({
        to: `invite/${inviteToken}`,
        replace: true,
      });
      return true;
    }

    // if profile is incompleted...
    if (isRequired) {
      // prevent redirect when in complete page
      if (pathname === `/${PAGE.ONBOARDING.COMPLETE_PROFILE}`) {
        return true;
      }

      // redirect to complete page
      redirect({
        to: PAGE.ONBOARDING.COMPLETE_PROFILE,
        replace: true,
      });
      return true;
    }

    // check redirect after any change
    return doLoginRedirect();
  }, [doLoginRedirect, redirect, user?.id, pathname, isRequired]);

  useEffect(() => {
    if (!redirectToCompletePage) return;

    // do redirect
    doRedirect();
  }, [doRedirect, redirectToCompletePage]);

  return {doRedirect, isProfileIncomplete: isRequired};
};

export default useUserCompleteProfile;
