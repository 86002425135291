import {
  forwardRef,
  useCallback,
  useRef,
  useState,
  type CSSProperties,
  type MouseEvent,
  type ReactNode,
  type ForwardedRef,
} from 'react';

import classNames from 'classnames';

import Icon, {IconType} from 'components/Common/Icon';
import Loading from 'components/Common/Loading';
import Locale, {
  LocaleParamsProp,
  LocaleTextProp,
} from 'components/Common/Locale';
import Dialog, {DialogProps} from 'components/Common/NodeRender/Dialog';

export type OnPress = (event: MouseEvent) => Promise<void> | void;

export type ButtonBaseProps = {
  id?: string;
  style?: CSSProperties;
  title?: string;
  children?: ReactNode;
  className?: string;
  iconPosition?: 'before' | 'after';
  icon?: IconType;
  label?: LocaleTextProp;
  labelParams?: LocaleParamsProp;
  disabled?: boolean;
  working?: boolean;
  width?: string | number;
  onMouseDown?: OnPress;
  onMouseUp?: OnPress;
};

export type ButtonProps = ButtonBaseProps & {
  type?: 'submit' | 'button' | 'reset';
  onPress?: OnPress;
  onDialogOpen?: OnPress;
  dialog?: DialogProps;
  renderAsView?: boolean;
  onClosed?: () => any;
};

const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      id,
      type = 'button',
      children,
      className,
      onPress,
      onDialogOpen,
      label,
      labelParams,
      disabled,
      onClosed,

      icon,
      iconPosition = 'before',

      working,

      dialog,

      width,
      style,

      renderAsView,

      ...props
    },
    ref,
  ) => {
    const [visible, setVisible] = useState(false);
    const mouseEvent = useRef<MouseEvent | null>(null);
    const isDialog = !!dialog;

    const onPressDialog = useCallback(
      (e: MouseEvent) => {
        e.preventDefault();

        mouseEvent.current = e;
        setVisible(true);

        if (onDialogOpen) {
          onDialogOpen(e);
        }
      },
      [onDialogOpen, setVisible, mouseEvent],
    );

    const onConfirm = useCallback(async () => {
      if (mouseEvent.current && onPress) {
        await onPress(mouseEvent.current);
      }
    }, [onPress, mouseEvent]);

    const renderContent = () => {
      return (
        <>
          {iconPosition === 'before' && <Icon icon={icon} />}
          {children ||
            (label ? (
              <span className={'text'}>
                <Locale text={label} params={labelParams} />
              </span>
            ) : (
              label
            ))}
          {iconPosition === 'after' && <Icon icon={icon} />}
        </>
      );
    };

    const elementProps = {
      id,
      style: {
        ...style,
        ...(width && {
          width: typeof width === 'number' ? `${width}px` : width,
        }),
      },
      className: classNames(
        {working},
        ...classNames(className?.split(' '), disabled, {
          working,
        }).split(' '),
      ),
      onClick: isDialog ? onPressDialog : onPress,
    };

    const renderButton = () => {
      if (renderAsView) {
        return (
          <div
            ref={ref as ForwardedRef<HTMLDivElement>}
            {...elementProps}
            {...props}>
            {renderContent()}
            {working && <Loading size="small" />}
          </div>
        );
      }

      return (
        <button
          ref={ref}
          {...elementProps}
          type={type}
          disabled={disabled}
          {...props}>
          {renderContent()}
          {working && <Loading size="small" />}
        </button>
      );
    };

    return (
      <>
        {visible && (
          <Dialog
            onConfirm={onConfirm}
            setClosed={(e: boolean) => {
              setVisible(e);
              if (!e) {
                onClosed?.();
              }
            }}
            {...dialog}
          />
        )}

        {renderButton()}
      </>
    );
  },
);

Button.displayName = 'Button';

export default Button;
