import * as firebaseAuth from 'firebase/auth';

export function getProvider(providerId: string) {
  switch (providerId) {
    case firebaseAuth.GoogleAuthProvider.PROVIDER_ID:
      return new firebaseAuth.GoogleAuthProvider();
    case firebaseAuth.GithubAuthProvider.PROVIDER_ID:
      return new firebaseAuth.GithubAuthProvider();

    default:
      throw new Error(`No provider implemented for ${providerId}`);
  }
}

export const SUPPORTED_POPUP_SIGN_IN_METHODS = [
  firebaseAuth.ProviderId.GOOGLE,
  firebaseAuth.ProviderId.GITHUB,
  firebaseAuth.ProviderId.PASSWORD,
];
