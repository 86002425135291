import {useCallback, useMemo, useState} from 'react';

import {useQuery, useQueryClient} from '@tanstack/react-query';

import type {KycDto} from 'types/Dto';
import type {StatusColor} from 'types/Misc';

import {
  createKycManageCreate,
  createKycMe,
  fetchKycManage,
  fetchKycMe,
  syncKycManage,
  syncKycMe,
} from 'api/kyc';

// import {AppDtoSample} from 'components/Pages/Docs/Content/ApiReference/Apps/samples';

import useHandleError from './core/useHandleError';

const PREVIEW_DATA: KycDto = {
  id: 'a5bc25a6-6caf-429e-90c3-42fa1754ea2e',
  app: {} as any,
  type: 'app',
  account_exists: true,
  identifier: 'test_id',
  edit_token: 'token',
  account_type: 'standard',
  country: 'US',
  email: 'john@example.com',
  business_status: 'completed',
  details_submitted: false,
  charges_enabled: true,
  payouts_enabled: true,
  needs_documents: false,
  payout_schedule: {
    delay_days: 2,
    interval: 'daily',
  },
  required_documents: [],
  capabilities: {},
  bank_accounts: [
    {
      object: 'bank_account',
      account_holder_name: 'John Smith',
      account_holder_type: 'individual',
      account_type: 'checking',
      available_payout_methods: ['instant', 'standard'],
      bank_name: 'Bank Name',
      country: 'US',
      currency: 'usd',
      default_for_currency: true,
      last4: '1234',
      metadata: null,
      routing_number: '12345678',
      status: 'new',
    },
  ],
  cards: [],
  created_at: '2022-08-14T14:03:41.749Z',
  updated_at: '2022-08-14T14:03:41.749Z',
};

type UseKycProps = {
  appId?: string;
  editToken?: string;
  isPreview?: boolean;
};

const useKyc = ({appId: appIdProp, editToken, isPreview}: UseKycProps) => {
  const appId = !editToken ? appIdProp : undefined;
  const [isLoading, setIsLoading] = useState(false);

  const handleError = useHandleError();

  const queryKey = useMemo(() => {
    if (isPreview) {
      return 'kyc-preview';
    }

    if (editToken) {
      return `kyc-edit-${editToken}-${appId}`;
    }

    return `kyc-me-${appId}`;
  }, [appId, editToken, isPreview]);

  const queryClient = useQueryClient();

  const {
    data,
    error,
    refetch,
    isLoading: queryIsLoading,
  } = useQuery({
    queryKey: [queryKey],

    queryFn: async () => {
      if (isPreview) {
        return PREVIEW_DATA;
      }

      if (editToken) {
        return fetchKycManage(editToken);
      }

      if (appId) {
        return fetchKycMe(appId);
      }

      throw Error('app_id and edit_token are undefined');
    },

    retry: 2,
  });

  const create = useCallback(
    async (country: string) => {
      try {
        if (!data?.account_exists && editToken) {
          const kyc = await createKycManageCreate(editToken, country);
          queryClient.setQueryData([queryKey], kyc);
          return kyc;
        }

        if (data) {
          return data;
        }

        if (appId) {
          const kyc = await createKycMe(appId, country);
          queryClient.setQueryData([queryKey], kyc);
          return kyc;
        }
      } catch (e) {
        handleError(e);
      }
    },
    [handleError, appId, editToken, data, queryClient, queryKey],
  );

  const sync = useCallback(async () => {
    setIsLoading(true);

    if (editToken) {
      await syncKycManage(editToken);
    }

    if (appId) {
      await syncKycMe(appId);
    }

    await refetch();
    setIsLoading(false);
  }, [refetch, editToken, appId]);

  const statusColor = useMemo<StatusColor | null>(() => {
    if (!data?.business_status && isLoading) return null;

    switch (data?.business_status) {
      case 'completed': {
        return 'green';
      }
      case 'pending': {
        return 'yellow';
      }
      case 'incomplete': {
        return 'red';
      }
      default: {
        return 'yellow';
      }
    }
  }, [data?.business_status, isLoading]);

  const isPending = useMemo(() => {
    if (
      (!data?.id && queryIsLoading) ||
      data?.business_status === 'completed'
    ) {
      return false;
    }

    return true;
  }, [queryIsLoading, data?.id, data?.business_status]);

  return {
    error,
    kyc: data,
    isLoading: (!data && isLoading) || queryIsLoading,
    sync,
    refetch,
    create,
    statusColor,
    isPending,
  };
};

export default useKyc;
