import {FaPlusCircle} from 'react-icons/fa';

import PAGE from 'constants/page';

import {AppMenuLink} from './AppMenuOptions/AppMenuOptions';

const CreateAppButton = () => {
  return (
    <div className="flex">
      {/* <Link
        onPress={onPress}
        to={PAGE.APPS.CREATE}
        className={classNames('btn dark-blue size-40')}
        icon={CreateAppIcon}
        label="Create App"
      /> */}

      <AppMenuLink to={PAGE.APPS.CREATE}>
        <FaPlusCircle className="mr-2 text-xl" />
        Create App
      </AppMenuLink>
    </div>
  );
};

export default CreateAppButton;
