import {type ReactNode} from 'react';

import Card, {type CardProps} from '../Card/Card';

type CardMessageProps = CardProps & {
  message: string;
  children?: ReactNode;
};

const CardMessage = ({message, children, ...props}: CardMessageProps) => {
  return (
    <Card className="flex flex-col items-center justify-center" {...props}>
      <span className="text-base font-normal leading-6 tracking-wide text-center noramp-text">
        {message}
      </span>

      {children}
    </Card>
  );
};

export default CardMessage;
