import type {AppDto, CreateAppDto, UpdateAppDto} from 'types/Dto';

import {formatEndpoint} from 'utils/Api';
import {DELETE, GET, PATCH, POST} from 'utils/Http';

export const fetchApps = () => {
  return GET<AppDto[]>(formatEndpoint('APPS'));
};

export const createApp = (createAppDto: CreateAppDto) => {
  return POST<AppDto, CreateAppDto>(formatEndpoint('APPS'), createAppDto);
};

export const fetchApp = (appId: string) => {
  return GET<AppDto>(formatEndpoint('APPS_ITEM', {app_id: appId}));
};

export const deleteApp = (appId: string) => {
  return DELETE(formatEndpoint('APPS_ITEM', {app_id: appId}));
};

export const updateApp = (appId: string, updateAppDto: UpdateAppDto) => {
  return PATCH<AppDto, UpdateAppDto>(
    formatEndpoint('APPS_ITEM', {app_id: appId}),
    updateAppDto,
  );
};
