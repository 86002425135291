import {Role} from 'types/User';

import useCurrentUser from './useCurrentUser';

const useUserHasRoles = (...roles: Role[]) => {
  const user = useCurrentUser();

  if (!user) {
    return false;
  }

  return roles.includes(user.role);
};

export default useUserHasRoles;
