import {useMemo} from 'react';

import Head from 'next/head';

import useBaseUrl from 'hooks/core/useBaseUrl';

import Tags from './Tags';
import type {SeoProps} from './Types';

const Seo = (props: SeoProps) => {
  const baseUrl = useBaseUrl();

  const seoConfig = useMemo<SeoProps>(() => {
    if (Object.keys(props).length) {
      return props;
    }

    return {
      title: 'NoRamp',
      description: 'Web3 tools for developers',
      url: baseUrl,
      openGraph: {
        type: 'website',
        images: [
          {
            url: `${baseUrl}/assets/logo.png`,
            alt: 'NoRamp',
          },
        ],
      },
      facebook: {
        appId: '',
      },
      twitter: {
        handle: '@noramp',
        site: '@noramp',
      },
    };
  }, [props, baseUrl]);

  return (
    <Head>
      <Tags config={seoConfig} />
    </Head>
  );
};

export default Seo;
