import en from './en';

export const Locales = {
  en: {translation: en},
  test: {translation: en},
};

// type-safe support
/* declare module 'react-i18next' {
  interface CustomTypeOptions {
    // custom resources type
    resources: typeof Locales;
  }
} */

export default Locales;
