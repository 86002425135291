import {useCallback} from 'react';

import {get, set, remove} from 'utils/Storage';

import {LOGIN_REDIRECT_KEY} from 'constants/all';

import {useRedirect} from 'components/App/Redirect';

const useLoginRedirect = () => {
  const redirect = useRedirect();

  const setRedirect = useCallback((url?: string, overwrite = true) => {
    if (!overwrite && get(LOGIN_REDIRECT_KEY)) return;

    set(
      LOGIN_REDIRECT_KEY,
      url ?? location.href.substring(location.origin.length),
    );
  }, []);

  const removeRedirect = useCallback(() => remove(LOGIN_REDIRECT_KEY), []);

  const doRedirect = useCallback(() => {
    const finalUrl = get<string>(LOGIN_REDIRECT_KEY);

    if (!finalUrl) return false;

    removeRedirect();

    redirect({
      base: finalUrl,
      replace: true,
    });

    return true;
  }, [removeRedirect, redirect]);

  return {removeRedirect, setRedirect, doRedirect};
};

export default useLoginRedirect;
