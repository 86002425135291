import useCurrentUserStatus from 'hooks/user/useCurrentUserStatus';

import Logo from '../../Logo';
import HeaderMobileMenu from './HeaderMobileMenu/HeaderMobileMenu';

const HeaderMobile = () => {
  const currentUserStatus = useCurrentUserStatus();

  return (
    <>
      {currentUserStatus === 'not-logged' ? null : (
        <div
          className="grid w-full gap-2 px-4 lg:hidden "
          style={{
            gridTemplateColumns: '1fr 100px',
          }}>
          <div className="flex ">
            <Logo className="flex items-center justify-center h-full" mobile />
          </div>
          {/* <div className="flex border">
        {/* <div>
        {currentUserStatus === 'logged' && (
          <HeaderMobileAppSelector
            open={showAppMenu}
            onOpen={openAppMenu}
            onClose={closeAppMenu}
          />
        )}
      </div> */}
          {/* <HeaderMobileCreateAppButton /> * /}
      </div> */}
          <div className="flex justify-end ">
            <HeaderMobileMenu currentUserStatus={currentUserStatus} />
          </div>
        </div>
      )}
    </>
  );
};

export default HeaderMobile;
