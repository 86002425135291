import type {MemberDto} from 'types/Dto';

import {formatEndpoint} from 'utils/Api';
import {GET, POST} from 'utils/Http';

import API from 'constants/api';

export const fetchInvite = (token: string) => {
  return GET<MemberDto>(
    formatEndpoint(API.MEMBERS_INVITE, {
      invite_token: token,
    }),
  );
};

export const acceptInvite = (token: string) => {
  return POST<MemberDto>(
    formatEndpoint(API.MEMBERS_INVITE, {
      invite_token: token,
    }),
  );
};
