import {useCallback, useRef} from 'react';

import mixpanel from 'mixpanel-browser';

type UserData = {
  id: string;
  email: string | null;
  first_name: string | null;
  last_name: string | null;
};

const useMixpanel = () => {
  const isIdentitySet = useRef(false);
  const track = useCallback<typeof mixpanel.track>((...args) => {
    mixpanel.track(...args);
  }, []);

  const setUser = useCallback((data: UserData) => {
    isIdentitySet.current = true;

    mixpanel.identify(data.id);
    mixpanel.people.set(data);
  }, []);

  const unsetUser = useCallback(() => {
    if (!isIdentitySet.current) return;

    mixpanel.people.delete_user();

    isIdentitySet.current = false;
  }, []);

  return {
    track,
    setUser,
    unsetUser,
  };
};

export default useMixpanel;
