import {forwardRef, type HTMLProps} from 'react';

import classNames from 'classnames';

export type TitleProps = HTMLProps<HTMLHeadingElement> & {
  as?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5';
  small?: boolean;
  medium?: boolean;
  uppercase?: boolean;
  center?: boolean;
  gray?: boolean;
  lightGray?: boolean;
};

const Title = forwardRef<HTMLHeadingElement, TitleProps>(
  ({as = 'h1', className, ...props}: TitleProps, ref) => {
    const Component = as;

    return (
      <div className={classNames(className)}>
        <Component className={''} ref={ref} {...props} />
      </div>
    );
  },
);

Title.displayName = 'Title';

export default Title;
