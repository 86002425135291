import {type ReactNode} from 'react';

import {QueryClient, QueryClientProvider} from '@tanstack/react-query';
import {I18nextProvider, I18nextProviderProps} from 'react-i18next';

import {init} from 'configs/App';
import i18n from 'configs/Lang';

import useIsEmbedPages from 'hooks/embed/useIsEmbedPages';

import AppLayout from './AppLayout';
import ErrorBoundary from './Error/Boundary';
import Session from './Session';

const queryClient = new QueryClient();

// init config
init();

type AppProps = {
  children: ReactNode;
};

const App = ({children}: AppProps) => {
  const isEmbedPages = useIsEmbedPages();
  const providerProps: I18nextProviderProps = {i18n};

  return (
    <I18nextProvider {...providerProps}>
      <ErrorBoundary>
        <QueryClientProvider client={queryClient}>
          <AppLayout>
            {isEmbedPages ? children : <Session>{children}</Session>}
          </AppLayout>
        </QueryClientProvider>
      </ErrorBoundary>
    </I18nextProvider>
  );
};

export default App;
