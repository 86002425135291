type CopyrightProps = {
  className?: string;
};

const Copyright = ({className}: CopyrightProps) => {
  return (
    <span className={className}>&copy; {new Date().getFullYear()} NoRamp</span>
  );
};

export default Copyright;
