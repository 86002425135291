import {cn} from 'lib/utils';

export const LOADING_TEST_ID = 'loading-test-id';

type Props = {
  className?: string;
  size?: 'small' | 'medium' | 'large' | number;
  color?: string;
};

const Loading = ({className, color, size = 'small'}: Props) => {
  return (
    <div
      data-testid={LOADING_TEST_ID}
      className={`loading ${cn(
        'loading',
        typeof size !== 'number' && {
          [`size-${size}`]: size,
        },
        className?.split(' '),
      )}`}>
      {size && (
        <div
          className={`circle rounded-2xl animate-spin`}
          style={{
            ...(color && {borderColor: color}),
            ...(typeof size === 'number' && {
              width: `${size}px`,
              height: `${size}px`,
            }),
          }}
        />
      )}
    </div>
  );
};

export default Loading;
