import type {ErrorDto} from 'types/Dto/Error';

export type ErrorInfo = {
  msg: string | string[];
  error: string | string[];
};

export const parseError = (
  error: ErrorDto | string,
  fallbackMsg = 'errors.unknown',
): ErrorInfo => {
  if (!error) {
    return {
      msg: fallbackMsg,
      error: fallbackMsg,
    };
  }
  if (typeof error === 'string') {
    return {
      msg: fallbackMsg,
      error: error,
    };
  }

  const statusCode = error.statusCode;
  // ORIGINAL CODE
  // const errorMessageId =
  //   typeof error.message === 'string'
  //     ? error.message
  //     : Array.isArray(error?.message)
  //     ? Object.keys(error?.message[0]?.errors)[0]
  //     : null;

  // MODIFIED CODE:
  let errorMessageId;

  if (typeof error.message === 'string') {
    errorMessageId = error.message;
  } else if (Array.isArray(error?.message)) {
    errorMessageId = Object.keys(error.message[0]?.errors)[0];
  } else {
    errorMessageId = null;
  }
  // :MODIFIED CODE

  const errorId = error.error || errorMessageId;

  return {
    msg: [
      `errors.${errorMessageId}`,
      `errors.${errorId}`,
      `errors.${errorId}_${statusCode}`,
      `errors.error_${statusCode}`,
      fallbackMsg,
    ],
    error: errorId || fallbackMsg,
  };
};
