import {useCallback, useState, type ChangeEvent, type ReactNode} from 'react';

import {CgProfile} from 'react-icons/cg';
import {MdAddToQueue} from 'react-icons/md';

import type {AppDto} from 'types/Dto';

import Button from 'components/Common/Button';
import Form from 'components/Form';
import Field from 'components/Form/Field';
import Input from 'components/Form/Input';

import {useCreateApp} from './CreateAppProvider';
// import TypeIndividualIcon from './res/type-individual';

// import TypeMarketplaceIcon from './res/type-marketplace';

const TypeField = () => {
  const {setView, type, setType} = useCreateApp();
  const [selectedType, setSelectedType] = useState<AppDto['type'] | null>(null);

  const onPressType = useCallback(
    (type: AppDto['type']) => {
      setType(type);

      setView('confirm');
      setSelectedType(type);
    },

    [setType, setView],
  );

  return (
    <>
      <div className="">
        <div className="flex flex-col sm:flex-row items-center justify-center gap-3 py-2">
          <TypeBlock
            type="individual"
            onPress={onPressType}
            active={selectedType === 'individual' ? true : false}
            name="Individual"
            description="For NFT sales and smart contract actions"
            icon={
              <CgProfile
                size={28}
                color={type === 'individual' ? '#2b53e1' : 'black'}
              />
            }
          />
          <TypeBlock
            type="marketplace"
            onPress={onPressType}
            active={selectedType === 'marketplace' ? true : false}
            name="Marketplace"
            description="For marketplaces or when a third party seller is involved"
            icon={
              <MdAddToQueue
                size={28}
                color={type === 'marketplace' ? '#2b53e1' : 'black'}
              />
            }
          />
        </div>

        {type === 'marketplace' && (
          <>
            <MarketplaceExtraFields />
          </>
        )}
      </div>
    </>
  );
};

type TypeBlockProps = {
  icon: ReactNode;
  name: string;
  description: string;
  type: AppDto['type'];
  active: boolean;
  onPress: (type: AppDto['type']) => void;
};

const TypeBlock = ({
  onPress,
  type,
  icon,
  name,
  description,
  active,
}: TypeBlockProps) => {
  const onPressType = useCallback(() => onPress(type), [onPress, type]);
  return (
    <Button
      className={`button flex flex-col w-52 gap-2 p-2 md:p-3 rounded-xl items-center border border-solid noramp-bg py-4 shadow-2xl
      ${active ? 'border-blue-600' : ''}`}
      onPress={onPressType}>
      <div className="flex items-center h-8 mt-8">{icon}</div>
      <span className="text-lg noramp-text px-4">{name}</span>
      <span className="text-xs text-[#8F8F8F] px-4 mb-10">{description}</span>
    </Button>
  );
};

const MarketplaceExtraFields = () => {
  const {appFee, setAppFee} = useCreateApp();

  const onChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const value = +e.target.value;

      if (value <= 0 || value >= 100) {
        setAppFee(null);
        return;
      }

      setAppFee(value);
    },
    [setAppFee],
  );

  return (
    <div className={'gap-2 mt-8 flex flex-col items-center justify-center'}>
      <div className={'flex flex-col  items-center mb-2'}>
        <span className="font-bold">Marketplace Fee </span>
        <span className={'text-sm'}>
          % of seller transactions payable to Marketplace.
        </span>
      </div>

      <Form>
        {() => (
          <div className="flex gap-2">
            <Field name="app_fee">
              <Input
                type="number"
                onChange={onChange}
                placeholder="0%"
                defaultValue={appFee}
                autoFocus
                className="flex h-9 rounded-md"
              />
            </Field>
          </div>
        )}
      </Form>
    </div>
  );
};

export default TypeField;
