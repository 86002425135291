export const getLocalStorage = () => {
  if (typeof window === 'undefined') {
    return;
  }

  return window.localStorage;
};

export const getSessionStorage = () => {
  if (typeof window === 'undefined') {
    return;
  }

  return window.sessionStorage;
};
