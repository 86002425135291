import type {UserDto} from 'types/Dto';

import {useApp} from 'components/App/AppContext';

const useCurrentUser = () => {
  const {
    user: {user},
  } = useApp();

  return user as UserDto;
};

export default useCurrentUser;
