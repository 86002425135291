import {useEffect} from 'react';

import {MdOutlineAllInbox} from 'react-icons/md';

import useSession from 'hooks/core/useSession';

import {useRedirect} from 'components/App/Redirect';
import Title from 'components/Common/Title';

type SentConfirmationProps = {
  userEmail: string | null;
};

const AuthSentConfirmation = ({userEmail}: SentConfirmationProps) => {
  const {refreshSession} = useSession();
  const redirect = useRedirect();

  useEffect(() => {
    let timeoutId: ReturnType<typeof setTimeout>;

    const redirectHome = () => {
      timeoutId = setTimeout(async () => {
        const user = await refreshSession();
        if (!user) {
          redirectHome();
          return;
        }

        redirect({});
      }, 1500);
    };

    redirectHome();

    return () => {
      if (!timeoutId) return;

      clearTimeout(timeoutId);
    };
  }, [refreshSession, redirect]);

  return (
    <div className="flex flex-col gap-4 items-center justify-center">
      <div className="border p-4 rounded-xl bg-gray-100">
        <MdOutlineAllInbox size={24} />
      </div>
      <Title className="text-xl">Check your inbox</Title>
      <div className="w-full px-24">
        <div className="border-b w-full"></div>
      </div>
      <div className="flex gap-2 flex-col items-center justify-center max-w-sm w-full">
        <p className="text-xs">Click the link we sent to sign in</p>
        <p className="">{userEmail}</p>
      </div>
      <div className="text-sm text-center w-full">
        <p className="px-10">
          This page is going to refresh automatically once you click on the link
          from your email.
        </p>
      </div>
      <p className="text-center text-xs text-gray-500 px-16 mb-4">
        Please also check all your email folders.
      </p>
    </div>
  );
};

export default AuthSentConfirmation;
