import {useEffect} from 'react';

import {useRouter} from 'next/router';

import {useApp} from 'components/App/AppContext';

const useIsPageBack = () => {
  const {setIsPageBack} = useApp();
  const router = useRouter();

  useEffect(() => {
    if (!('beforePopState' in router)) {
      return;
    }

    let restorPopState = true;

    router.beforePopState(() => {
      restorPopState = false;
      setIsPageBack(true);
      return true;
    });

    return () => {
      if (restorPopState) {
        setIsPageBack(false);
      }
    };
  }, [setIsPageBack, router]);
};

export default useIsPageBack;
