import {useMemo, useContext, createContext, type HTMLProps} from 'react';

import classNames from 'classnames';
import {useFormContext, useFormState} from 'react-hook-form';

import {getDeepValue} from 'utils/Misc';

import Errors, {type ErrorMessage} from '../Errors';

import {normalizeFieldName} from '..';

type AnyProps = {[name: string]: any};

type FieldBaseProps = {
  name: string;
  type?: string;
};

type FieldProps = HTMLProps<HTMLDivElement> & FieldBaseProps & AnyProps;

const FieldContext = createContext<AnyProps>({});

const randToken = () => {
  return Math.random().toString(36).substring(2);
};

const Field = (props: FieldProps) => {
  const {id: idProp, name, type} = props;
  const {control} = useFormContext();

  const {errors} = useFormState({name, control});
  const id = useMemo(() => idProp || `${name}_${randToken()}`, [idProp, name]);
  const fieldError = getDeepValue(errors, name);

  const content = useMemo(() => {
    const {className, children, ...rest} = props;
    return (
      <FieldContext.Provider
        value={{...rest, id: normalizeFieldName(id)} as AnyProps}>
        <div
          className={classNames(
            'field flex flex-col gap-1',
            className,
            {[`field-${name}`]: normalizeFieldName(name)},
            {[`field-${type}`]: type},
            {error: !!fieldError},
          )}>
          {children}
          {fieldError && (
            <Errors error={{...fieldError, name} as ErrorMessage} />
          )}
        </div>
      </FieldContext.Provider>
    );
  }, [id, name, type, props, fieldError]);

  return <>{content}</>;
};

export const useField = <T,>(): T => {
  return useContext(FieldContext) as T;
};

export default Field;
