type SectionTitleProps = {
  title: string;
  asAdmin?: boolean;
};

const SectionTitle = ({title}: SectionTitleProps) => {
  return <p className="text-sm font-normal leading-4 text-main">{title}</p>;
};

export default SectionTitle;
