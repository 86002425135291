export const DashboardIcon = ({className}: {className?: string}) => {
  return (
    <svg
      className={`${className || ''}`}
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_5_113)">
        <path
          d="M12.6657 3.83333V5.16667H9.99902V3.83333H12.6657ZM5.99902 3.83333V7.83333H3.33236V3.83333H5.99902ZM12.6657 9.16667V13.1667H9.99902V9.16667H12.6657ZM5.99902 11.8333V13.1667H3.33236V11.8333H5.99902ZM13.999 2.5H8.66569V6.5H13.999V2.5ZM7.33236 2.5H1.99902V9.16667H7.33236V2.5ZM13.999 7.83333H8.66569V14.5H13.999V7.83333ZM7.33236 10.5H1.99902V14.5H7.33236V10.5Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_5_113">
          <rect
            width="16"
            height="16"
            fill="currentColor"
            transform="translate(-0.00134277 0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export const PaymentIcon = ({className}: {className?: string}) => {
  return (
    <svg
      className={`${className || ''}`}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_4_488)">
        <path
          d="M7.86683 7.26691C6.3535 6.87358 5.86683 6.46691 5.86683 5.83358C5.86683 5.10691 6.54017 4.60024 7.66683 4.60024C8.8535 4.60024 9.2935 5.16691 9.3335 6.00024H10.8068C10.7602 4.85358 10.0602 3.80024 8.66683 3.46024V2.00024H6.66683V3.44024C5.3735 3.72024 4.3335 4.56024 4.3335 5.84691C4.3335 7.38691 5.60683 8.15358 7.46683 8.60024C9.1335 9.00024 9.46683 9.58691 9.46683 10.2069C9.46683 10.6669 9.14017 11.4002 7.66683 11.4002C6.2935 11.4002 5.7535 10.7869 5.68017 10.0002H4.2135C4.2935 11.4602 5.38683 12.2802 6.66683 12.5536V14.0002H8.66683V12.5669C9.96683 12.3202 11.0002 11.5669 11.0002 10.2002C11.0002 8.30691 9.38017 7.66024 7.86683 7.26691Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_4_488">
          <rect
            width="16"
            height="16"
            fill="white"
            transform="translate(-0.00170898 0.000244141)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export const WalletIcon = ({className}: {className?: string}) => {
  return (
    <svg
      className={`${className || ''}`}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_4_493)">
        <path
          d="M13.9996 4.85333V3.33333C13.9996 2.6 13.3996 2 12.6663 2H3.33297C2.59297 2 1.99963 2.6 1.99963 3.33333V12.6667C1.99963 13.4 2.59297 14 3.33297 14H12.6663C13.3996 14 13.9996 13.4 13.9996 12.6667V11.1467C14.393 10.9133 14.6663 10.4933 14.6663 10V6C14.6663 5.50667 14.393 5.08667 13.9996 4.85333ZM13.333 6V10H8.6663V6H13.333ZM3.33297 12.6667V3.33333H12.6663V4.66667H8.6663C7.93297 4.66667 7.33297 5.26667 7.33297 6V10C7.33297 10.7333 7.93297 11.3333 8.6663 11.3333H12.6663V12.6667H3.33297Z"
          fill="currentColor"
        />
        <path
          d="M10.6666 8.99976C11.2189 8.99976 11.6666 8.55204 11.6666 7.99976C11.6666 7.44747 11.2189 6.99976 10.6666 6.99976C10.1143 6.99976 9.66663 7.44747 9.66663 7.99976C9.66663 8.55204 10.1143 8.99976 10.6666 8.99976Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_4_493">
          <rect
            width="16"
            height="16"
            fill="white"
            transform="translate(-0.00170898)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export const PriceIcon = ({className}: {className?: string}) => {
  return (
    <svg
      className={`${className || ''}`}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_4_498)">
        <path
          d="M13.9991 5.33374C13.0324 5.33374 12.4924 6.29374 12.7124 7.00707L10.3458 9.38041C10.1458 9.32041 9.85244 9.32041 9.65244 9.38041L7.95244 7.68041C8.17911 6.96707 7.6391 6.00041 6.66577 6.00041C5.6991 6.00041 5.15244 6.96041 5.3791 7.68041L2.3391 10.7137C1.62577 10.4937 0.665771 11.0337 0.665771 12.0004C0.665771 12.7337 1.26577 13.3337 1.9991 13.3337C2.96577 13.3337 3.50577 12.3737 3.28577 11.6604L6.3191 8.62041C6.5191 8.68041 6.81244 8.68041 7.01244 8.62041L8.71244 10.3204C8.48577 11.0337 9.02577 12.0004 9.99911 12.0004C10.9658 12.0004 11.5124 11.0404 11.2858 10.3204L13.6591 7.95374C14.3724 8.17374 15.3324 7.63374 15.3324 6.66707C15.3324 5.93374 14.7324 5.33374 13.9991 5.33374Z"
          fill="currentColor"
        />
        <path
          d="M9.99878 6.00024L10.6254 4.62024L11.9988 4.00024L10.6254 3.38024L9.99878 2.00024L9.38545 3.38024L7.99878 4.00024L9.38545 4.62024L9.99878 6.00024Z"
          fill="currentColor"
        />
        <path
          d="M2.33244 7.33333L2.66577 6L3.99911 5.66667L2.66577 5.33333L2.33244 4L1.9991 5.33333L0.665771 5.66667L1.9991 6L2.33244 7.33333Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_4_498">
          <rect
            width="16"
            height="16"
            fill="white"
            transform="translate(-0.00170898)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export const WebhookIcon = ({className}: {className?: string}) => {
  return (
    <svg
      className={`${className || ''}`}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6.5233 7.44016L5.57663 8.38683C5.1233 7.92683 4.6833 7.3335 4.3833 6.42683L5.67663 6.10016C5.88996 6.6935 6.18996 7.10016 6.5233 7.44016ZM7.33663 4.00016L4.66996 1.3335L2.0033 4.00016H4.01663C4.02996 4.54016 4.06996 5.02683 4.1433 5.44683L5.43663 5.12016C5.38996 4.80016 5.35663 4.42016 5.34996 4.00016H7.33663ZM14.0033 4.00016L11.3366 1.3335L8.66996 4.00016H10.6633C10.5966 6.4535 9.80996 7.16683 8.96996 7.92016C8.63663 8.2135 8.29663 8.5335 8.0033 8.9535C7.77663 8.62683 7.51663 8.36683 7.24996 8.12683L6.30996 9.06683C6.92996 9.6335 7.33663 10.0935 7.33663 11.3335V14.6668H8.66996V11.3335C8.66996 9.98683 9.1433 9.56016 9.8633 8.9135C10.7833 8.08683 11.9166 7.06016 11.9966 4.00016H14.0033V4.00016Z"
        fill="currentColor"
      />
    </svg>
  );
};

export const LogsIcon = ({className}: {className?: string}) => {
  return (
    <svg
      className={`${className || ''}`}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_4_518)">
        <path
          d="M2.66866 3.9998H1.33533V13.3331C1.33533 14.0665 1.93533 14.6665 2.66866 14.6665H12.002V13.3331H2.66866V3.9998ZM13.3353 1.33313H5.33533C4.60199 1.33313 4.00199 1.93313 4.00199 2.66646V10.6665C4.00199 11.3998 4.60199 11.9998 5.33533 11.9998H13.3353C14.0687 11.9998 14.6687 11.3998 14.6687 10.6665V2.66646C14.6687 1.93313 14.0687 1.33313 13.3353 1.33313ZM13.3353 10.6665H5.33533V2.66646H13.3353V10.6665ZM6.66866 5.9998H12.002V7.33313H6.66866V5.9998ZM6.66866 7.9998H9.33533V9.33313H6.66866V7.9998ZM6.66866 3.9998H12.002V5.33313H6.66866V3.9998Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_4_518">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
