import {useCallback, useRef, useState, type ChangeEvent} from 'react';

import Form from 'components/Form';
import {Input} from 'components/ui/input';

import {useCreateApp} from './CreateAppProvider';

const NameField = () => {
  const {setView, name, setName, type} = useCreateApp();
  const [value, setValue] = useState<string | null>(name);
  const lastValueRef = useRef(value);

  if (value) {
    lastValueRef.current = value;
  }

  const onSend = useCallback(() => {
    if (!lastValueRef.current) return;

    setName(lastValueRef.current);

    if (!type) {
      setView('type');
      return;
    }

    // setView('confirm');
  }, [setName, setView, type]);

  // const onPress = useCallback(() => {
  //   setValue(null);
  //   setName(null);
  //   setView('name');
  // }, [setName, setValue, setView]);

  const onChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value?.trim();
    setValue(value ? value : null);
  }, []);

  // const isActive = name === null || view === 'name';

  return (
    <>
      <div className={'flex flex-col w-full'}>
        <Form
          className={''}
          onSuccess={onSend}
          data={{name: lastValueRef.current}}>
          {() => (
            <>
              <div className={'relative'}>
                <Input
                  autoFocus
                  name="name"
                  onBlur={onSend}
                  onChange={onChange}
                  placeholder="Enter App Name"
                  minLength={1}
                  maxLength={128}
                />
              </div>
            </>
          )}
        </Form>
      </div>

      {/* {!isActive && (
        <ExpandableButton >{name}</ExpandableButton>
      )} */}
    </>
  );
};

export default NameField;
