import {useCallback} from 'react';

import {
  getIdToken,
  getAuth,
  signOut,
  User as FirebaseUser,
} from 'firebase/auth';

import type {UserDto} from 'types/Dto';

import {createAuth, fetchAuth, destroyAuth} from 'api/auth/auth';

import {useApp} from 'components/App/AppContext';

import useLoginRedirect from './useLoginRedirect';

type DoLoginProps = {
  firebaseUser?: FirebaseUser;
};

const useSession = () => {
  const {setRedirect} = useLoginRedirect();

  const {setUser} = useApp();

  const setLogin = useCallback(
    (user: UserDto, is_new: boolean) => {
      setUser({
        status: 'logged',
        user: {...user},
        is_new,
      });
    },
    [setUser],
  );

  const login = useCallback(
    (user: UserDto, is_new: boolean) => {
      // set redirect
      setRedirect('/', false);

      // set login
      setLogin(user, is_new);
    },
    [setLogin, setRedirect],
  );

  const logout = useCallback(() => {
    setRedirect('/', false);
    setUser({
      status: 'not-logged',
      user: null,
      is_new: false,
    });
  }, [setUser, setRedirect]);

  const cleanStateSession = useCallback(() => {
    logout();
  }, [logout]);

  const cleanFirebaseSession = useCallback(async () => {
    await signOut(getAuth());
  }, []);

  const doLogin = useCallback(
    async ({firebaseUser}: DoLoginProps) => {
      const {user, is_new} = await createAuth({
        ...(firebaseUser && {token: await getIdToken(firebaseUser)}),
        use_cookies: true,
      });

      login(user, is_new);

      // clear firebase session
      await cleanFirebaseSession();

      return {user, is_new};
    },
    [login, cleanFirebaseSession],
  );

  const doLogout = useCallback(async () => {
    // signout from firebase
    await cleanFirebaseSession();

    try {
      // delete session
      await destroyAuth();
    } catch (e) {
      console.warn(e);
    }

    // clean session
    cleanStateSession();
  }, [cleanStateSession, cleanFirebaseSession]);

  const refreshSession = useCallback(async () => {
    try {
      const {user, is_new} = await fetchAuth();

      // if user session is empty
      if (!user) {
        cleanStateSession();
        return;
      }

      setLogin(user, is_new);
      return user;
    } catch (e) {
      console.warn(e);

      // clean session
      cleanStateSession();
    }

    return null;
  }, [setLogin, cleanStateSession]);

  return {login, logout, doLogin, doLogout, refreshSession};
};

export default useSession;
