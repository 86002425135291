import {type ReactNode} from 'react';

import classNames from 'classnames';
import {useRouter} from 'next/router';
import {FaTheaterMasks} from 'react-icons/fa';
import {FiShoppingCart} from 'react-icons/fi';

import PAGE from 'constants/page';

import useUserHasRoles from 'hooks/user/useUserHasRoles';

import Link from 'components/Common/Link';

import ApprovalsIcon from './res/approvals.svg';
import AppsIcon from './res/apps.svg';
import DashboardIcon from './res/dashboard.svg';
import StatusIcon from './res/status.svg';
import UserIcon from './res/user.svg';

type AadminMenuOptionsProps = {
  className?: string;
};

const AadminMenuOptions = ({className}: AadminMenuOptionsProps) => {
  const isAdmin = useUserHasRoles('admin');

  return (
    <div className={classNames('flex flex-col gap-0.5', className)}>
      <AppMenuLink defaultTo={PAGE.ADMIN.HOME} to={PAGE.ADMIN.DASHBOARD}>
        <DashboardIcon />
        Dashboard
      </AppMenuLink>
      <AppMenuLink to={PAGE.ADMIN.USERS}>
        <UserIcon />
        Users
      </AppMenuLink>
      {isAdmin && (
        <AppMenuLink to={PAGE.ADMIN.SHOPPERS}>
          <FiShoppingCart />
          Shoppers
        </AppMenuLink>
      )}

      {isAdmin && (
        <AppMenuLink to={PAGE.ADMIN.THEMES}>
          <FaTheaterMasks />
          Themes
        </AppMenuLink>
      )}

      <AppMenuLink to={PAGE.ADMIN.APPS}>
        <AppsIcon />
        Apps
      </AppMenuLink>
      <AppMenuLink to={PAGE.ADMIN.APP_APPROVALS}>
        <ApprovalsIcon />
        App Approvals
      </AppMenuLink>
      {isAdmin && (
        <AppMenuLink to={PAGE.ADMIN.STATUS}>
          <StatusIcon />
          Status
        </AppMenuLink>
      )}

      {/* {isAdmin && (
        <AppMenuLink to={PAGE.ADMIN.KYCS}>
          <StatusIcon />
          Outdated KYCs
        </AppMenuLink>
      )} */}
    </div>
  );
};

type AppMenuLinkProps = {
  to: string;
  defaultTo?: string;
  children: ReactNode;
  className?: string;
  onClick?: () => void;
};

const AppMenuLink = ({
  to,
  // defaultTo,
  children,
  className,
  onClick = () => {},
}: AppMenuLinkProps) => {
  const {asPath} = useRouter();

  const isActive = asPath.indexOf(`/${to}`) === 0;

  return (
    <Link
      className={`${classNames(
        isActive &&
          'noramp-text bg-zinc-100 dark:bg-zinc-800 text-main/80 dark:text-white/80',
        !isActive && 'text-main/80 dark:text-white/80',
        className,
        'flex items-center font-medium p-2 rounded-lg gap-2',
      )}`}
      onPress={onClick}
      to={to}>
      {children}
    </Link>
  );
};

export default AadminMenuOptions;
