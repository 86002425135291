import {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
  type ReactNode,
  type RefObject,
} from 'react';

import classNames from 'classnames';
import {createPortal} from 'react-dom';

import useIsMobile from 'hooks/core/useIsMobile';
import useOnClickOutside from 'hooks/core/useOnClickOutside';
import useTranslation from 'hooks/core/useTranslation';

export type Position = 'auto' | 'top' | 'bottom' | 'left' | 'right';

type Behavior = 'hover' | 'click';

type TooltipProps = {
  target: RefObject<HTMLElement>;
  children?: ReactNode;
  position?: Position;
  behavior?: Behavior;
  delay?: number;
  active?: boolean;
  label?: string;
};

export const Tooltip = ({
  children,
  target,
  position = 'auto',
  behavior: behaviorProp = 'hover',
  delay = 1000,
  label,
}: TooltipProps) => {
  const isMobile = useIsMobile();
  const {t} = useTranslation();
  const [visible, setVisible] = useState(false);
  const content = useMemo(() => label || children, [label, children]);
  const cancelRef = useRef(false);
  const visibleRef = useRef(false);

  const behavior = useMemo<Behavior>(
    () => (isMobile ? 'click' : behaviorProp),
    [isMobile, behaviorProp],
  );

  useEffect(() => {
    if (!target.current) return;

    const currentDelay = behavior === 'click' ? 0 : delay;
    const targetEl = target.current;

    let timeoutId: ReturnType<typeof setTimeout> | null = null;

    const onMouseEnter = () => {
      if (behavior === 'click' && (cancelRef.current || visibleRef.current))
        return;

      visibleRef.current = true;
      cancelRef.current = true;

      timeoutId = setTimeout(() => {
        setVisible(visibleRef.current);
        timeoutId = null;
      }, currentDelay);
    };

    const onMouseLeave = () => {
      if (behavior === 'click' && cancelRef.current) {
        cancelRef.current = false;
        return;
      }

      visibleRef.current = false;
      setVisible(false);

      if (timeoutId) {
        clearTimeout(timeoutId);
        timeoutId = null;
      }
    };

    if (behavior === 'click') {
      targetEl.addEventListener('mouseup', onMouseEnter);
    } else {
      targetEl.addEventListener('mouseenter', onMouseEnter);
      targetEl.addEventListener('mouseleave', onMouseLeave);
    }

    targetEl.addEventListener('mouseup', onMouseLeave);

    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
        timeoutId = null;
      }

      if (behavior === 'click') {
        targetEl.removeEventListener('mouseup', onMouseEnter);
      } else {
        targetEl.removeEventListener('mouseenter', onMouseEnter);
        targetEl.removeEventListener('mouseleave', onMouseLeave);
      }

      targetEl.removeEventListener('mouseup', onMouseLeave);
    };
  }, [behavior, delay, target, content]);

  useOnClickOutside(
    target,
    useCallback(e => {
      if ((e.target as HTMLElement)?.closest('[data-tooltip-container]')) {
        return;
      }

      setVisible(false);
      cancelRef.current = false;
      visibleRef.current = false;
    }, []),
  );

  return (
    <>
      {visible && (
        <TooltipRender target={target} position={position}>
          {typeof content === 'string' ? t(content) : content}
        </TooltipRender>
      )}
    </>
  );
};

const TooltipRender = ({children, target, position = 'auto'}: TooltipProps) => {
  const tooltipRef = useRef<HTMLDivElement>(null);
  const [visible, setVisible] = useState(false);

  position = position === 'auto' ? 'top' : position;

  useEffect(() => {
    if (!target.current || !tooltipRef.current) {
      return;
    }

    const targetEl = target.current;
    const tooltipEl = tooltipRef.current;

    const updatePosition = () => {
      const targetRect = targetEl.getBoundingClientRect();
      const tooltipRect = tooltipEl.getBoundingClientRect();

      const {height: tooltipHeight, width: tooltipWidth} = tooltipRect;
      const {width, height, x, y} = targetRect;

      switch (position) {
        case 'top':
        default: {
          tooltipEl.style.top = `${y - tooltipHeight}px`;
          tooltipEl.style.left = `${x + width / 2 - tooltipWidth / 2}px`;
          break;
        }
        case 'bottom': {
          tooltipEl.style.top = `${y + height}px`;
          tooltipEl.style.left = `${x + width / 2 - tooltipWidth / 2}px`;
          break;
        }
        case 'left': {
          tooltipEl.style.top = `${y + height / 2 - tooltipHeight / 2}px`;
          tooltipEl.style.left = `${x - tooltipWidth}px`;
          break;
        }
        case 'right': {
          tooltipEl.style.top = `${y + height / 2 - tooltipHeight / 2}px`;
          tooltipEl.style.left = `${x + width}px`;
          break;
        }
      }
    };

    updatePosition();

    setVisible(true);

    document.addEventListener('scroll', updatePosition);

    return () => {
      document.removeEventListener('scroll', updatePosition);
    };
  }, [tooltipRef, target, position]);

  return createPortal(
    <div
      key={position}
      data-tooltip-container
      className={classNames(' fixed flex-col hidden', {
        ['flex']: visible,
      })}
      ref={tooltipRef}>
      <div className={classNames('flex noramp-text noramp-bg')}>{children}</div>
    </div>,
    document?.body,
  );
};

export default Tooltip;
