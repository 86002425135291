import {generatePath, type Params as BasePathParams} from 'react-router';

import type {QueryParams} from 'utils/Url';

import API from 'constants/api';

import {API_ENDPOINT} from './Http';

export type PathParams = BasePathParams;

export const formatEndpoint = (
  path: keyof typeof API | API | string,
  params?: PathParams,
  query?: QueryParams,
  full?: boolean,
  pathIsString?: boolean,
): string => {
  const validatedPath = pathIsString
    ? path
    : generatePath(
        API[path as keyof typeof API] ?? path,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        params,
      );

  if (query && Object.keys(query).length) {
    const queryParams =
      '?' +
      new URLSearchParams(
        (() => {
          const newQuery: {[key: string]: string} = {};

          Object.entries(query).forEach(([key, value]) => {
            if (value) {
              newQuery[key] = value?.toString() ?? '';
            }
          });
          return newQuery;
        })(),
      );
    return `${full ? `${API_ENDPOINT}/` : ''}${validatedPath}${queryParams}`;
  }

  return `${full ? `${API_ENDPOINT}/` : ''}${validatedPath}`;
};

export const formatStringEndpoint = (
  path: keyof typeof API | API | string,
  params?: PathParams,
  query?: QueryParams,
  full?: boolean,
): string => {
  return formatEndpoint(path, params, query, full, true);
};

type RemoveEmptyParams = (params: {[key: string]: string | number}) => {
  [key: string]: string | number;
};

export const removeEmptyParams: RemoveEmptyParams = params => {
  return Object.fromEntries(Object.entries(params).filter(([, v]) => v !== ''));
};
