import Link from 'next/link';
import {FaDiscord, FaTwitter} from 'react-icons/fa';

import {DISCORD_LINK, TWITTER_LINK} from 'constants/all';

export const SOCIAL_TEST_ID = 'social-links';

type SocialIconsProps = {
  className?: string;
};

const SocialIcons = ({className}: SocialIconsProps) => {
  return (
    <div data-testid={SOCIAL_TEST_ID} className={className}>
      <Link href={DISCORD_LINK} target="_blank">
        <FaDiscord className="w-5 h-5" />
      </Link>

      <Link href={TWITTER_LINK} target="_blank">
        <FaTwitter className="w-5 h-5" />
      </Link>
    </div>
  );
};

export default SocialIcons;
