import {type ReactNode} from 'react';

import posthog from 'posthog-js';
import {PostHogProvider} from 'posthog-js/react';

import {PaginationContextProvider} from 'hooks/pagination/usePaginationContext';

import ModalsProvider from '../Common/Table/Modal/Modals';
import SignInModal from './Auth/SignInModal';
import HeaderProvider from './Structure/Header/Provider';

type AppProvidersProps = {
  children: ReactNode;
};

if (typeof window !== 'undefined') {
  posthog.init('phc_kcH0cLqQ13pIWuHSLpKelHDFK5M3iYh6IZiQwNE8LN9', {
    api_host: 'https://us.posthog.com',
  });
}

const AppProviders = ({children}: AppProvidersProps) => {
  const renderProviders = () => {
    return (
      <PostHogProvider>
        <ModalsProvider>
          <HeaderProvider>
            <PaginationContextProvider>
              {children}

              <SignInModal />
            </PaginationContextProvider>
          </HeaderProvider>
        </ModalsProvider>
      </PostHogProvider>
    );
  };

  return <>{renderProviders()}</>;
};

export default AppProviders;
