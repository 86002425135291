import {useCallback} from 'react';

import {useRouter} from 'next/router';

import {UrlProps} from 'types/Url';

import {formatUrl} from 'utils/Url';

const useUrl = () => {
  const {asPath} = useRouter();

  const url = useCallback(
    (props: UrlProps) => {
      formatUrl(props, asPath);
      try {
        return formatUrl(props, asPath);
      } catch (e) {
        console.warn(e);
        return '/';
      }
    },
    [asPath],
  );

  return url;
};

export default useUrl;
