import HeaderDesktop from './HeaderDesktop/HeaderDesktop';
import HeaderMobile from './HeaderMobile/HeaderMobile';

export const HEADER_TEST_ID = 'header-container';

const Header = () => {
  return (
    <header
      id="app-header"
      data-testid={HEADER_TEST_ID}
      className="flex items-center justify-between py-4 noramp-bg">
      <div className="flex w-full lg:hidden">
        <HeaderMobile />
      </div>
      <div className="justify-between hidden w-full px-5 lg:flex">
        <HeaderDesktop />
      </div>
    </header>
  );
};

export default Header;
