import {Magic} from 'magic-sdk';

const createMagic = (key: string) => {
  if (typeof window === 'undefined') {
    return null;
  }

  return new Magic(key);
};

const MAGIC_KEY = String(process.env.NEXT_PUBLIC_MAGIC_PUBLISHABLE_KEY);

export const magic = createMagic(MAGIC_KEY);
