import {useCallback, useEffect} from 'react';

import classNames from 'classnames';

import PAGE from 'constants/page';

import useApps from 'hooks/useApps';
import useCurrentApp from 'hooks/useCurrentApp';

import Link from 'components/Common/Link';
import Loading from 'components/Common/Loading';

import SectionTitle from '../../App/Structure/LeftPanel/SectionTitle';
import AppMenuOptions from './AppMenuOptions/AppMenuOptions';
import CreateAppButton from './CreateAppButton';

const AppMenu = () => {
  const {
    apps,
    initial,
    loading,
    hasAccess,
    isAdminAccess,
    setSelectedApp,
    getLastSelected,
  } = useApps();

  const currentApp = useCurrentApp();

  // set selected app
  useEffect(() => {
    if (currentApp || !apps.length) return;

    const lastSelected = getLastSelected();
    const newAppSelected = apps.find(e => e.id === lastSelected) ?? apps[0];

    setSelectedApp(newAppSelected);
  }, [setSelectedApp, getLastSelected, hasAccess, currentApp, apps, loading]);

  const onPressBackToAdmin = useCallback(() => {
    setSelectedApp(null);
  }, [setSelectedApp]);

  const renderMenu = () => {
    if (!currentApp) {
      return <CreateAppButton />;
    }

    if (isAdminAccess(currentApp)) {
      return (
        <div>
          <div className="flex justify-center mb-4">
            <SectionTitle title="Viewing App as Admin" asAdmin />
          </div>

          <Link
            to={PAGE.ADMIN.APPS}
            onPress={onPressBackToAdmin}
            className={classNames('btn primary small text-sm')}
            label="Back to Admin Apps"
          />

          <AppMenuOptions key={currentApp.id} className="mt-4" />
        </div>
      );
    }

    return (
      <>
        {/* <SectionTitle title="My Apps" /> */}

        <AppMenuOptions key={currentApp.id} />
      </>
    );
  };

  if (initial && loading) {
    return <Loading />;
  }

  return <div className="flex flex-col w-full">{renderMenu()}</div>;
};

export default AppMenu;
