import type {ErrorDto} from 'types/Dto/Error';

import useTranslation from 'hooks/core/useTranslation';

import {customValidations} from '../useRules';

export type ErrorMessage = {
  name: string;
  type: string;
  message: ErrorDto;
};

type Props = {
  error?: ErrorMessage;
  errors?: ErrorMessage[];
};

const Errors = ({error, errors}: Props) => {
  const {t} = useTranslation();

  const formatMessage = (
    inline: boolean,
    name: string,
    type: string,
    message: any,
  ) => {
    // forms
    if (customValidations[type] && name) {
      return t(`form.errors.${type}`, {
        name,
        label: t([`field_${name}`, name]),
      });
    }

    // remote
    if (type === 'remote') {
      return t([`errors.${message}`, message || 'error']);
    }

    // local
    if (type === 'local') {
      return t([`errors.${message}`, message || '']);
    }

    if (typeof message === 'function') {
      return message(inline);
    }

    return type;
  };

  const renderSingleError = () => {
    if (!error) {
      return null;
    }

    const {name, type, message} = error;

    return (
      <div className="inline-error">
        <span className="text-red-700 text" key={`${name}-${type}`}>
          {formatMessage(true, name, type, message)}
        </span>
      </div>
    );
  };

  const renderErrors = () => {
    if (!errors || errors.length) {
      return null;
    }

    return (
      <div className="errors-list">
        {errors.map(({name, type, message}) => (
          <span className="text" key={`${name}-${type}`}>
            - {formatMessage(false, name, type, message)}
          </span>
        ))}
      </div>
    );
  };

  return (
    <>
      {error && renderSingleError()}
      {errors && renderErrors()}
    </>
  );
};

export default Errors;
