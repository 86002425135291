import axios, {
  type AxiosInstance,
  type AxiosRequestConfig,
  type AxiosResponse,
} from 'axios';

// import {IS_STAGING} from 'constants/all';

import {AUTH_STORAGE_KEY} from 'components/Misc/PaymentContext';

import {get} from './Storage';

export type MethodType = 'get' | 'post' | 'delete' | 'patch' | 'put';

export type PagedParams = {
  page: number;
  take: number;
};

export type RequestResult<T> = {data: T};

export type RequestPagedResult<T> = RequestResult<T[]> &
  PagedParams & {count: number};

type AxiosRequestHeaders = Record<string, string | number | boolean>;

type BaseParams<Params> = Params & {headers?: AxiosRequestHeaders};

export const API_ENDPOINT =
  process.env.NEXT_PUBLIC_ENDPOINT || 'https://localhost';

const AxiosClient = axios.create({
  baseURL: process.env.NEXT_PUBLIC_ENDPOINT || 'https://localhost',
  withCredentials: true,
});

const doRequest = async <Data, Params>(
  path: string,
  method: MethodType,
  paramsProp?: BaseParams<Params>,
  axiosClient?: AxiosInstance,
): Promise<RequestResult<Data>> => {
  try {
    let requestParams: AxiosRequestConfig<Params> = {};
    let params: BaseParams<Params> | null = null;

    let headers: AxiosRequestHeaders = {};
    if (paramsProp) {
      const {headers: headersProps, ...rest} = paramsProp;
      headers = headersProps ?? {};

      params = rest as BaseParams<Params>;
    }

    const token = get<string>(AUTH_STORAGE_KEY);
    if (token) {
      if (!headers['X-Shopper-Auth']) {
        headers['X-Shopper-Auth'] = `Bearer ${token}`;
      }
    }

    const network = localStorage.getItem('nr-network');

    //     if (network?.includes('testnet') || IS_STAGING) {

    if (network?.includes('testnet')) {
      headers['NoRamp-NetworkType'] = 'testnet';
    } else {
      headers['NoRamp-NetworkType'] = 'mainnet';
    }

    requestParams.headers = headers;

    if (method === 'get') {
      if (params) {
        requestParams.params = params;
      }
    } else {
      if (params) {
        requestParams.data = params;

        // handle files
        const containsFile = Object.values(params).some(
          item => item instanceof FileList || item instanceof File,
        );

        if (containsFile) {
          const formData = new FormData();

          Object.entries(params).forEach(([key, value]) => {
            // parse files
            if (value instanceof File) {
              formData.append(key, value);
              return;
            } else if (value instanceof FileList) {
              for (let i = 0; i < value.length; i++) {
                const file = value.item(i);
                if (file) {
                  formData.append(key, file);
                }
              }
              return;
            }

            // parse objects
            if (value != null && typeof value === 'object') {
              formData.append(key, JSON.stringify(value));
              return;
            }

            // add as normal value
            formData.append(key, value);
          });

          requestParams = {
            headers: {'Content-Type': 'multipart/form-data'},
            data: formData as any,
          };
        }
      }
    }

    const response = await (axiosClient || AxiosClient)({
      method,
      url: path,
      ...requestParams,
    });

    return Promise.resolve(response.data);
  } catch (error: any) {
    const errorMsg = (error.response?.data?.error ?? error).toString();
    console.warn(errorMsg, error?.response?.data?.errors);
    return Promise.reject({
      error: errorMsg,
      ...error?.response?.data,
      request: error,
    });
  }
};

const doRequestRaw = async <Data>(
  path: string,
  method: MethodType,
  params?: AxiosRequestConfig,
): Promise<AxiosResponse<RequestResult<Data>>> => {
  try {
    const headers: AxiosRequestHeaders = {};

    const network = localStorage.getItem('nr-network');

    // if (network?.includes('testnet') || IS_STAGING) {

    if (network?.includes('testnet')) {
      headers['NoRamp-NetworkType'] = 'testnet';
    } else {
      headers['NoRamp-NetworkType'] = 'mainnet';
    }

    const newParams = {
      ...params,
      headers: {
        ...params?.headers,
        ...headers,
      },
    };

    const response = await AxiosClient[method](path, newParams);
    return Promise.resolve(response);
  } catch (error: any) {
    const errorMsg = (error.response?.data?.error ?? error).toString();
    console.warn(errorMsg, error?.response?.data?.errors);
    return Promise.reject({
      error: errorMsg,
      ...error?.response?.data,
      request: error,
    });
  }
};

export const GET = async <Data, Params = null>(
  path: string,
  params?: BaseParams<Params>,
): Promise<Data> => {
  return (await GETRaw<Data, Params>(path, params)).data;
};

export const GETPaged = async <Data, Params = Partial<PagedParams>>(
  path: string,
  params?: Params & Partial<PagedParams>,
): Promise<RequestPagedResult<Data>> => {
  const request = await doRequestRaw<Data[]>(path, 'get', {params});

  return {
    ...request.data,
    page: +request.headers['pagination-page'],
    take: +request.headers['pagination-take'],
    count: +request.headers['pagination-count'],
  };
};

export const GETRaw = async <Data, Params = undefined>(
  path: string,
  params?: BaseParams<Params>,
): Promise<RequestResult<Data>> => {
  return doRequest<Data, Params>(path, 'get', params);
};

export const POST = async <Data, Params = unknown>(
  path: string,
  params?: BaseParams<Params>,
): Promise<Data> => {
  return (await POSTRaw<Data, Params>(path, params)).data;
};

export const POSTRaw = async <Data, Params = unknown>(
  path: string,
  params?: BaseParams<Params>,
): Promise<RequestResult<Data>> => {
  return doRequest<Data, Params>(path, 'post', params);
};

export const PUT = async <Data, Params>(
  path: string,
  params?: BaseParams<Params>,
): Promise<Data> => {
  return (await PUTRaw<Data, Params>(path, params)).data;
};

export const PUTRaw = async <Data, Params>(
  path: string,
  params?: BaseParams<Params>,
): Promise<RequestResult<Data>> => {
  return doRequest<Data, Params>(path, 'put', params);
};

export const PATCH = async <T, P>(
  path: string,
  params?: BaseParams<P>,
): Promise<T> => {
  return (await PATCHRaw<T, P>(path, params)).data;
};

export const PATCHRaw = async <Data, Params>(
  path: string,
  params?: BaseParams<Params>,
): Promise<RequestResult<Data>> => {
  return doRequest<Data, Params>(path, 'patch', params);
};

export const DELETE = async <Data = unknown, Params = unknown>(
  path: string,
  params?: BaseParams<Params>,
): Promise<Data> => {
  return (await doRequest<Data, Params>(path, 'delete', params)).data;
};
