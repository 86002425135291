import {useEffect, useRef, type ReactNode} from 'react';

import app from 'configs/App';

import useSession from 'hooks/core/useSession';

type SessionProps = {
  children: ReactNode;
};

const Session = ({children}: SessionProps) => {
  const firstRender = useRef(false);
  const {refreshSession} = useSession();

  // init session
  useEffect(() => {
    if (firstRender.current) return;

    firstRender.current = true;

    if (!app.tests) {
      refreshSession();
    }
  }, [refreshSession]);

  return <>{children}</>;
};

export default Session;
