import {generatePath, type Params as PathParams} from 'react-router';

import type {UrlProps} from 'types/Url';

import {IS_STAGING} from 'constants/all';

export type QueryValue = string | number | undefined;
export type QueryParams = {[name: string]: QueryValue};

export const uri = (
  path?: string | string[],
  params?: PathParams,
  query?: QueryParams,
): string => {
  let basePath = '';

  path = path || '/';

  if (Array.isArray(path)) {
    basePath = path.map(encodeURIComponent).join('/');
  } else if (typeof path === 'string') {
    basePath = path;
  }

  if (basePath[0] !== '/') {
    basePath = `/${basePath}`;
  }

  const validatedPath = generatePath(basePath, params);

  if (query && Object.keys(query).length) {
    const queryParams =
      '?' +
      new URLSearchParams(
        (() => {
          const newQuery: {[key: string]: string} = {};

          Object.entries(query).every(([key, value]) => {
            if (value) {
              newQuery[key] = value?.toString() ?? '';
            }
          });
          return newQuery;
        })(),
      );

    return `${validatedPath}${queryParams}`;
  }

  return `${validatedPath}`;
};

export const formatGoBack = (path: string, goBack: boolean | number) => {
  let goBackCount = typeof goBack === 'boolean' ? 1 : goBack;

  do {
    path = path.substring(0, path.lastIndexOf('/'));
    goBackCount--;
  } while (goBackCount > 0);

  return path;
};

export const getBaseUrl = () => {
  if (typeof location !== 'undefined') {
    return location.origin;
  }

  if (process.env.NEXT_PUBLIC_BASE_URL) {
    return process.env.NEXT_PUBLIC_BASE_URL;
  }

  if (process.env.NEXT_PUBLIC_VERCEL_URL) {
    return `https://${process.env.NEXT_PUBLIC_VERCEL_URL}`;
  }

  return 'http://localhost:3000';
};

export type EmbedType = 'app' | 'checkout';

export const getEmbedBaseUrl = (type: EmbedType) => {
  if (process.env.NEXT_PUBLIC_IS_LOCALHOST) {
    if (type === 'app') {
      return 'http://localhost:3000';
    }

    return 'http://localhost:4000';
  }

  if (type === 'app') {
    if (IS_STAGING) {
      return 'https://dashboard.noramp.dev';
    }
    return 'https://dashboard.noramp.io';
  }

  if (IS_STAGING) {
    return 'https://checkout.noramp.dev';
  }

  return 'https://checkout.noramp.io';
};

export const formatUrl = (
  {
    fromPath,
    base,
    goBack,
    full,
    follows,
    to,
    params,
    query,
    external,
    hash,
  }: UrlProps,
  pathname: string,
) => {
  // set from path
  if (fromPath) {
    to = pathname?.split('?')[0].split('#')[0];
  }
  // ORIGINAL CODE
  // const basePathName =
  //   !follows && !goBack
  //     ? to || ''
  //     : pathname.slice(-1) === '/'
  //     ? pathname.slice(0, -1)
  //     : pathname;

  // MODIFIED CODE:
  let basePathName;

  if (!follows && !goBack) {
    basePathName = to || '';
  } else {
    basePathName =
      pathname.slice(-1) === '/' ? pathname.slice(0, -1) : pathname;
  }
  // :MODIFIED CODE

  if (external) {
    return basePathName;
  }

  const finalUrlPathBase = goBack
    ? formatGoBack(basePathName, goBack)
    : basePathName;

  const baseUri = (base ? base : '') + (follows ? finalUrlPathBase : '');

  const pathUri = uri(follows ? follows : finalUrlPathBase, params, query);

  let fullUri = `${baseUri}${baseUri && pathUri === '/' ? '' : pathUri}${
    hash ? `#${hash}` : ''
  }`;

  if (fullUri[0] !== '/') {
    fullUri = `/${fullUri}`;
  }

  if (full) {
    return `${getBaseUrl()}${fullUri}`;
  }

  return fullUri;
};
