import {
  createContext,
  useContext,
  useState,
  type Dispatch,
  type ReactNode,
  type SetStateAction,
} from 'react';

import type {ThemeType} from 'types/Theme';

type HeaderTheme = null | ThemeType;

type HeaderContextProps = {
  theme: HeaderTheme;
  setTheme: Dispatch<SetStateAction<HeaderTheme>>;

  isMenuOpen: boolean;
  setIsMenuOpen: Dispatch<SetStateAction<boolean>>;
};

const HeaderContext = createContext<HeaderContextProps>({
  theme: null,
  setTheme: () => {
    /* do nothing */
  },

  isMenuOpen: false,
  setIsMenuOpen: () => {
    /* do nothing */
  },
});

type HeaderProviderProps = {
  children: ReactNode;
};

const HeaderProvider = ({children}: HeaderProviderProps) => {
  const [theme, setTheme] = useState<HeaderTheme | null>(null);
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);

  return (
    <HeaderContext.Provider
      value={{theme, setTheme, isMenuOpen, setIsMenuOpen}}>
      {children}
    </HeaderContext.Provider>
  );
};

export const useHeader = () => {
  return useContext(HeaderContext);
};

export default HeaderProvider;
