import {useRef, type ReactNode} from 'react';

import {AvatarFallback} from '@radix-ui/react-avatar';
import classNames from 'classnames';

import {formatUserInitials} from 'utils/User';

import {IS_STAGING} from 'constants/all';
import PAGE from 'constants/page';

import useIsMobile from 'hooks/core/useIsMobile';
import useIsPageBack from 'hooks/core/useIsPageBack';
import useIsEmbedPages from 'hooks/embed/useIsEmbedPages';
import useNetwork from 'hooks/use-network';
import useCurrentApp from 'hooks/useCurrentApp';
import useCurrentUserStatus from 'hooks/user/useCurrentUserStatus';
import useUserCompleteProfile from 'hooks/user/useUserCompleteProfile';

import {ThemeToggleButton} from 'components/App/theme-toggle-button';
import AppStatus from 'components/Apps/Misc/AppStatus/AppStatus';
import Link from 'components/Common/Link';
import Tooltip from 'components/Common/Table/Tooltip';
import {Avatar} from 'components/ui/avatar';

import {useApp, type LoginStatus} from './AppContext';
import ErrorBoundary from './Error/Boundary';
import Footer from './Structure/Footer';
import Header from './Structure/Header';
import LeftPanel from './Structure/LeftPanel';
import HeaderTitle from './Structure/LeftPanel/HeaderTitle/HeaderTitle';
import {UserMenu} from './user-menu';

type AppRenderprops = {
  children: ReactNode;
};

const AppRender = ({children}: AppRenderprops) => {
  const isMobile = useIsMobile();
  const {resolvedTheme} = useApp();
  const isEmbedPages = useIsEmbedPages();
  const userStatus = useCurrentUserStatus();

  return (
    <div
      className={classNames('app-container', {
        mobile: isMobile,
        desktop: !isMobile,
        'theme-dark': resolvedTheme === 'dark',
        'theme-light': resolvedTheme === 'light',
      })}>
      {!isEmbedPages && (
        <HeaderRenderer userStatus={userStatus} isMobile={isMobile} />
      )}

      <ContentRender userStatus={userStatus} isMobile={isMobile}>
        {children}
      </ContentRender>

      {!isEmbedPages && userStatus === 'not-logged' && !isMobile && <Footer />}
    </div>
  );
};

type HeaderRendererProps = {
  userStatus: LoginStatus;
  isMobile: boolean;
};

const HeaderRenderer = ({userStatus, isMobile}: HeaderRendererProps) => {
  if (userStatus === 'not-logged') return null;

  if (!isMobile) return null;

  return <Header />;
};

type ContentRenderProps = {
  children: ReactNode;
  userStatus: LoginStatus;
  isMobile: boolean;
};

const ContentRender = ({
  children,
  userStatus,
  isMobile,
}: ContentRenderProps) => {
  useUserCompleteProfile(true);
  useIsPageBack();

  return (
    <div className="flex flex-1 max-w-full min-h-screen">
      <ErrorBoundary>
        <div className="flex flex-col flex-1 max-w-full">
          {userStatus === 'logged' && (
            <div className="sticky top-0 z-30 w-full bg-white border-b noramp-border">
              <Navbar />
            </div>
          )}

          <div className="flex flex-1 bg-alabaster-50 dark:bg-woodsmoke-950 noramp-bg">
            {userStatus === 'logged' && !isMobile && <LeftPanel />}
            <div className="flex flex-col flex-1 max-w-full overflow-auto">
              {children}
            </div>
          </div>
        </div>
      </ErrorBoundary>
    </div>
  );
};

const Navbar = () => {
  const currentApp = useCurrentApp();
  const {user} = useApp();

  const appStatusRef = useRef<HTMLAnchorElement>(null);
  const {setNetworkType, isTestnet} = useNetwork();

  const renderAppDetails = () => {
    if (!currentApp) {
      return;
    }

    const {status} = currentApp;

    return (
      <div className="flex items-center gap-4">
        <Tooltip
          target={appStatusRef}
          label="App Status"
          delay={150}
          position="bottom"
        />

        <Link ref={appStatusRef} to={PAGE.APPS.SETTINGS} className="">
          <AppStatus status={status} />
        </Link>

        <div className="absolute -top-[53px] right-14 md:static">
          <ThemeToggleButton />
        </div>

        <Avatar>
          <AvatarFallback>{formatUserInitials(user.user)}</AvatarFallback>
        </Avatar>

        <UserMenu />
      </div>
    );
  };

  return (
    <div className="sticky top-0 flex justify-between gap-4 p-5 py-4 noramp-bg">
      <div className="hidden md:flex">
        <HeaderTitle />
      </div>

      <div className="flex items-center justify-between w-full gap-2 lg:pr-4 lg:justify-center md:w-auto lg:space-x-8 ">
        {/* <button className="px-2 py-1 text-sm font-medium rounded-md select-none bg-woodsmoke-950 text-woodsmoke-200 hover:bg-gray-200">
          Developers
        </button> */}

        <button
          disabled={IS_STAGING}
          className="flex items-center gap-4 px-2 pr-2 text-sm font-medium rounded-md select-none noramp-bg noramp-text disabled:cursor-not-allowed">
          <span className="text-sm font-medium">
            {isTestnet || IS_STAGING ? 'Testnet' : 'Mainnet'}
          </span>
          <label className="relative inline-flex items-center cursor-pointer">
            <input
              type="checkbox"
              value=""
              className="sr-only peer"
              // checked={IS_STAGING ? true : isTestnet}
              checked={isTestnet}
              // disabled={IS_STAGING}
              onChange={e => {
                setNetworkType(e.target.checked ? 'testnet' : 'mainnet');
              }}
            />

            <div
              className={classNames(
                "w-10 h-5 bg-gray-200 dark:bg-zinc-800 peer-focus:outline-none rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-4 after:w-4 after:transition-all peer-checked:bg-orange-300",
                {
                  // 'cursor-not-allowed': IS_STAGING,
                },
              )}></div>
          </label>
        </button>
        {renderAppDetails()}
      </div>
    </div>
  );
};

export default AppRender;
