import {useCallback} from 'react';

import Link from 'next/link';
import {FaDelicious, FaKey} from 'react-icons/fa';
import {FiLogOut, FiUser} from 'react-icons/fi';
import {MdSpaceDashboard} from 'react-icons/md';

import PAGE from 'constants/page';

import useSession from 'hooks/core/useSession';
import useUserHasRoles from 'hooks/user/useUserHasRoles';

import AppMenuOptions, {
  AppMenuLink,
} from 'components/Apps/AppMenu/AppMenuOptions/AppMenuOptions';
import {AppSwitcher} from 'components/Apps/AppMenu/AppSwitcher';

import HeaderMobileFooter from '../HeaderMobileFooter/HeaderMobileFooter';

const HeaderMobileAccountMenu = () => {
  const {doLogout} = useSession();
  const hasRole = useUserHasRoles('admin', 'staff', 'analyst');

  const onPressLogout = useCallback(async () => {
    await doLogout();
  }, [doLogout]);

  return (
    <div className="">
      <div className="">
        <div className="flex items-center justify-center">
          <AppSwitcher />
        </div>
        <div className="py-4">
          <AppMenuOptions />
        </div>

        <div className="pb-4">
          <div className="flex flex-col p-0 rounded-md bg-alabaster-50 dark:bg-woodsmoke-950">
            <Link
              className="flex items-center p-2 font-medium rounded-lg text-main/80 dark:text-white/80"
              href={PAGE.SETTINGS.API_KEY}>
              <FaKey className="mr-2 text-xl" />
              API Key
            </Link>
            <Link
              href={PAGE.DOCS}
              className="flex items-center p-2 font-medium rounded-lg text-main/80 dark:text-white/80"
              target="_blank">
              <FaDelicious className="mr-2 text-xl" />
              Documentation
            </Link>
          </div>

          <div className="mt-8">
            <Link
              href="https://noramp.io"
              target="_blank"
              className="flex items-center justify-center px-4 py-2 border rounded-lg shadow-sm noramp-border">
              <div className="flex flex-col items-start">
                <p className="font-bold text-md">NoRamp 3.0</p>
                <p className="text-sm font-semibold text-gray-400">
                  See whats New
                </p>
              </div>
              <MdSpaceDashboard className="ml-auto text-3xl" />
            </Link>
          </div>
        </div>

        <div className="-mx-4 border-b noramp-border"></div>

        <div className="flex flex-col py-2">
          <AppMenuLink to={PAGE.SETTINGS.HOME}>
            <FiUser className="mr-2 text-xl" />
            Profile
          </AppMenuLink>

          {hasRole && (
            <AppMenuLink to={PAGE.ADMIN.HOME}>
              <FiUser className="mr-2 text-xl" />
              Admin Panel
            </AppMenuLink>

            // <Link
            //   label="Admin Panel"
            //   className=""
            //   to={PAGE.ADMIN.HOME}
            //   icon={<AdminIcon />}
            // />
          )}

          <AppMenuLink to="#" onClick={onPressLogout}>
            <FiLogOut className="mr-2 text-xl" />
            Logout
          </AppMenuLink>

          {/* <Button
            icon={LogoutIcon}
            onPress={onPressLogout}
            label="Logout"
            className=""
          /> */}
        </div>
        <div className="-mx-4 border-b noramp-border"></div>
      </div>

      <div className="px-2 py-4">
        <HeaderMobileFooter />
      </div>
    </div>
  );
};

export default HeaderMobileAccountMenu;
