import {forwardRef, useEffect, useRef, type HTMLProps} from 'react';

import InputRender, {type InputRenderProps} from './Render';

type InputProps<T> = HTMLProps<HTMLInputElement> &
  InputRenderProps<T, HTMLInputElement>;

const Input = forwardRef<HTMLInputElement, InputProps<string>>(
  (props, inputRef) => {
    const {autoFocus} = props;
    const inputLocalRef = useRef<HTMLInputElement | null>(null);
    const autoFocusRef = useRef(autoFocus);

    useEffect(() => {
      if (autoFocusRef.current) {
        inputLocalRef.current?.focus();
      }
    }, []);

    return (
      <InputRender<string, HTMLInputElement>
        {...props}
        render={props => {
          return (
            <input
              {...props}
              ref={ref => {
                if (inputRef && 'current' in inputRef) {
                  inputRef.current = ref;
                }

                inputLocalRef.current = ref;

                props.ref(ref);
              }}
            />
          );
        }}
      />
    );
  },
);

Input.displayName = 'Input';

export default Input;
