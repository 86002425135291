import {type ReactNode} from 'react';

import classNames from 'classnames';
import {useRouter} from 'next/router';
import {FaGavel, FaPaintBrush, FaSync} from 'react-icons/fa';
import {FiSettings} from 'react-icons/fi';
import {MdAccountBalance, MdBolt} from 'react-icons/md';

import PAGE from 'constants/page';

import useCurrentApp from 'hooks/useCurrentApp';
import useKyc from 'hooks/useKyc';

import Link from 'components/Common/Link';
import StatusDot from 'components/Misc/StatusDot/StatusDot';
import {
  DashboardIcon,
  LogsIcon,
  PaymentIcon,
  PriceIcon,
  WalletIcon,
  WebhookIcon,
} from 'components/v3/icons/sidebar/Dashboard';

type AppMenuOptionsProps = {
  className?: string;
};

const AppMenuOptions = ({className}: AppMenuOptionsProps) => {
  const {id: appId, type} = useCurrentApp() || {};
  const {isPending, statusColor} = useKyc({appId});

  const isMarketplace = type === 'marketplace';
  const isIndividual = type === 'individual';

  return (
    <div className={classNames('flex flex-col gap-0.5', className)}>
      <AppMenuLink to={PAGE.APPS.DASHBOARD}>
        <DashboardIcon className="mr-2 text-xl" />
        <span>Dashboard</span>
      </AppMenuLink>
      <AppMenuLink to={PAGE.APPS.PAYMENTS}>
        <PaymentIcon className="mr-2 text-xl" />
        Payments
      </AppMenuLink>
      <AppMenuLink to={PAGE.APPS.TRIGGERS.HOME}>
        <MdBolt className="mr-2 text-xl" />
        Triggers
      </AppMenuLink>

      <AppMenuLink to={PAGE.APPS.WALLETS.HOME}>
        <WalletIcon className="mr-2 text-xl" />
        Wallets
      </AppMenuLink>

      {isIndividual && (
        <AppMenuLink to={PAGE.APPS.PAYMENT_INTENTS.LISTING}>
          <PriceIcon className="mr-2 text-xl" />
          Payment Intents
        </AppMenuLink>
      )}

      {isIndividual && (
        <AppMenuLink to={PAGE.APPS.AUCTIONS.HOME}>
          <FaGavel className="mr-2 text-xl" />
          Auctions
        </AppMenuLink>
      )}

      {isIndividual && (
        <AppMenuLink to={PAGE.APPS.SUBSCRIPTIONS.HOME}>
          <FaSync className="mr-2 text-xl" />
          Subscription Plans
        </AppMenuLink>
      )}

      <AppMenuLink to={PAGE.APPS.CUSTOMIZE}>
        <FaPaintBrush className="mr-2 text-xl" />
        Customize
      </AppMenuLink>

      <AppMenuLink to={PAGE.APPS.WEBHOOKS}>
        <WebhookIcon className="mr-2 text-xl" />
        Webhooks
      </AppMenuLink>
      <AppMenuLink to={PAGE.APPS.LOGS}>
        <LogsIcon className="mr-2 text-xl" />
        Logs
      </AppMenuLink>
      {isMarketplace && (
        <AppMenuLink to={PAGE.APPS.SELLER_ACCOUNTS}>
          <MdAccountBalance className="mr-2 text-xl" />
          Seller Accounts
        </AppMenuLink>
      )}
      <AppMenuLink to={PAGE.APPS.SETTINGS}>
        <FiSettings className="mr-2 text-xl" />
        Settings
        {isPending && (
          <span className="ml-4">
            <StatusDot color={statusColor} />{' '}
          </span>
        )}
      </AppMenuLink>
    </div>
  );
};

type AppMenuLinkProps = {
  to: string;
  children: ReactNode;
  className?: string;
  onClick?: () => void;
};

export const AppMenuLink = ({
  to,
  children,
  className,
  onClick = () => {},
}: AppMenuLinkProps) => {
  const {asPath} = useRouter();

  return (
    <Link
      className={`${classNames(
        'noramp-sidebar-btn',
        'app-menu-link',
        asPath.indexOf(`/${to}`) === 0
          ? 'noramp-sidebar-btn-active'
          : 'noramp-sidebar-btn-unactive',
        className,
      )}`}
      to={to}
      onPress={onClick}>
      {children}
    </Link>
  );
};

export default AppMenuOptions;
