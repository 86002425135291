import {type ReactNode} from 'react';

import type {SeoProps, OpenGraphMedia} from './Types';

const buildOpenGraphMediaTags = (
  mediaType: 'image' | 'video',
  media: OpenGraphMedia[] = [],
) => {
  return media.reduce<ReactNode[]>((tags, medium, index) => {
    tags.push(
      <meta
        key={`og:${mediaType}:0${index}`}
        property={`og:${mediaType}`}
        content={medium.url}
      />,
    );

    if (medium.alt) {
      tags.push(
        <meta
          key={`og:${mediaType}:alt0${index}`}
          property={`og:${mediaType}:alt`}
          content={medium.alt}
        />,
      );
    }

    if (medium.secureUrl) {
      tags.push(
        <meta
          key={`og:${mediaType}:secure_url0${index}`}
          property={`og:${mediaType}:secure_url`}
          content={medium.secureUrl.toString()}
        />,
      );
    }

    if (medium.type) {
      tags.push(
        <meta
          key={`og:${mediaType}:type0${index}`}
          property={`og:${mediaType}:type`}
          content={medium.type.toString()}
        />,
      );
    }

    if (medium.width) {
      tags.push(
        <meta
          key={`og:${mediaType}:width0${index}`}
          property={`og:${mediaType}:width`}
          content={medium.width.toString()}
        />,
      );
    }

    if (medium.height) {
      tags.push(
        <meta
          key={`og:${mediaType}:height${index}`}
          property={`og:${mediaType}:height`}
          content={medium.height.toString()}
        />,
      );
    }

    return tags;
  }, []);
};

type TagsProps = {
  config: SeoProps;
};

const Tags = ({config}: TagsProps) => {
  const tagsToRender: ReactNode[] = [];

  if (config.title) {
    tagsToRender.push(
      <meta key="og:title" property="og:title" content={config.title} />,
    );
  }

  if (config.description) {
    tagsToRender.push(
      <meta
        key="description"
        name="description"
        content={config.description}
      />,
    );

    tagsToRender.push(
      <meta
        key="og:description"
        property="og:description"
        content={config.description}
      />,
    );
  }

  if (config.twitter) {
    tagsToRender.push(
      <meta
        key="twitter:card"
        name="twitter:card"
        content={config.twitter.cardType ?? 'summary'}
      />,
    );

    if (config.twitter.site) {
      tagsToRender.push(
        <meta
          key="twitter:site"
          name="twitter:site"
          content={config.twitter.site}
        />,
      );
    }

    if (config.twitter.handle) {
      tagsToRender.push(
        <meta
          key="twitter:creator"
          name="twitter:creator"
          content={config.twitter.handle}
        />,
      );
    }
  }

  if (config.facebook) {
    if (config.facebook.appId) {
      tagsToRender.push(
        <meta
          key="fb:app_id"
          property="fb:app_id"
          content={config.facebook.appId}
        />,
      );
    }
  }

  const openGraph = config.openGraph ?? {};

  // set default open graph image
  if (!openGraph?.images?.length && config.image) {
    openGraph.images = [
      {
        url: config.image,
      },
    ];
  }

  const type = openGraph?.type?.toLowerCase() ?? 'website';

  tagsToRender.push(<meta key="og:type" property="og:type" content={type} />);

  // images
  if (openGraph?.images?.length) {
    tagsToRender.push(...buildOpenGraphMediaTags('image', openGraph.images));
  }

  // videos
  if (openGraph.videos && openGraph.videos.length) {
    tagsToRender.push(...buildOpenGraphMediaTags('video', openGraph.videos));
  }

  if (openGraph.locale) {
    tagsToRender.push(
      <meta key="og:locale" property="og:locale" content={openGraph.locale} />,
    );
  }

  if (openGraph.site_name) {
    tagsToRender.push(
      <meta
        key="og:site_name"
        property="og:site_name"
        content={openGraph.site_name}
      />,
    );
  }

  if (config.url) {
    tagsToRender.push(
      <link rel="canonical" href={config.url} key="canonical" />,
    );

    tagsToRender.push(
      <meta key="og:url" property="og:url" content={config.url} />,
    );
  }

  return <>{tagsToRender}</>;
};

export default Tags;
