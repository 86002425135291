import {
  createContext,
  useContext,
  useEffect,
  type Dispatch,
  type SetStateAction,
} from 'react';

import type {AppDto, UserDto} from 'types/Dto';
import type {ThemeMode, ThemeType} from 'types/Theme';

import {type SignInModalProps} from 'components/App/Auth/SignInModal';

export type LoginStatus = 'loading' | 'logged' | 'not-logged';

export type NetworkType = 'mainnet' | 'testnet';

export type UserState = {
  status: LoginStatus;
  user: null | UserDto;
  is_new: boolean;
};

type AppContextProps = {
  userAgent?: string;

  user: UserState;
  setUser: Dispatch<SetStateAction<UserState>>;

  resolvedTheme: ThemeType;

  theme: ThemeMode;
  setTheme: Dispatch<SetStateAction<ThemeMode>>;

  viewTheme: ThemeType | null;
  setViewTheme: Dispatch<SetStateAction<ThemeType | null>>;

  showFooter: boolean;
  setShowFooter: Dispatch<SetStateAction<boolean>>;

  headerTitle: string | null;
  setHeaderTitle: Dispatch<SetStateAction<string | null>>;

  hideScroll: boolean;
  setHideScroll: Dispatch<SetStateAction<boolean>>;

  showSignInModal: SignInModalProps | null;
  setShowSignInModal: Dispatch<SetStateAction<SignInModalProps | null>>;

  isPageBack: boolean;
  setIsPageBack: Dispatch<SetStateAction<boolean>>;

  selectedApp: AppDto | null;
  setSelectedApp: Dispatch<SetStateAction<AppDto | null>>;

  networkType: NetworkType;
  setNetworkType: (networkType: NetworkType) => void;
};

const DEFAULT_THEME = 'dark'; // to `system`

const AppContext = createContext<AppContextProps>({
  userAgent: '',

  user: {
    status: 'loading',
    user: null,
    is_new: false,
  },

  setUser: () => null,

  resolvedTheme: DEFAULT_THEME,

  theme: DEFAULT_THEME,
  setTheme: () => null,

  viewTheme: null,
  setViewTheme: () => null,

  showFooter: true,
  setShowFooter: () => null,

  headerTitle: null,
  setHeaderTitle: () => null,

  hideScroll: true,
  setHideScroll: () => null,

  showSignInModal: null,
  setShowSignInModal: () => null,

  isPageBack: false,
  setIsPageBack: () => null,

  selectedApp: null,
  setSelectedApp: () => null,

  networkType: 'mainnet',
  setNetworkType: () => null,
});

export const useApp = () => {
  return useContext(AppContext);
};

export const useAppTheme = (): ThemeType => {
  return useContext(AppContext).resolvedTheme;
};

export const useAppViewTheme = (theme: ThemeType) => {
  const {setViewTheme} = useApp();

  useEffect(() => {
    setViewTheme(theme);

    return () => {
      setViewTheme(null);
    };
  }, [theme, setViewTheme]);
};

export default AppContext;
