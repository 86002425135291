import {useState, useEffect, useMemo} from 'react';

import {parseUserAgent} from 'react-device-detect';

import {useApp} from 'components/App/AppContext';

const useIsMobile = (userAgent?: string): boolean => {
  const {userAgent: userAgentApp} = useApp();

  const [isMobileResponsive, setIsMobileResponsive] = useState(false);

  const isMobile = useMemo(() => {
    if (!userAgent && !userAgentApp) {
      return false;
    }

    const {device} = parseUserAgent(userAgent ?? userAgentApp);

    return device.type === 'mobile' && device.type !== 'tablet';
  }, [userAgentApp, userAgent]);

  useEffect(() => {
    const reportWindowSize = () => {
      if (!('matchMedia' in window)) {
        return;
      }

      const result = window.matchMedia('(max-width: 767px)').matches;

      setIsMobileResponsive(result);
    };

    reportWindowSize();

    window.addEventListener('resize', reportWindowSize);
    return () => window.removeEventListener('resize', reportWindowSize);
  }, []);

  return isMobileResponsive ?? isMobile;
};

export default useIsMobile;
