import {MAIL_TO_HELLO} from 'constants/all';
import PAGE from 'constants/page';

import Link from 'components/Common/Link';

import Copyright from '../../../Copyright';
import SocialIcons from '../../../SocialIcons';

const HeaderMobileFooter = () => {
  return (
    <div className="flex flex-col gap-2 text-sm text-center text-gray-500 dark:text-gray-500">
      <p className="text-sm">
        <Link to={PAGE.LEGAL.HOME} label="Legal" />
      </p>
      <p className="text-sm">
        <Link
          external
          target="_blank"
          to={`mailto:${MAIL_TO_HELLO}`}
          label={MAIL_TO_HELLO}
          className=""
        />
      </p>
      <div className="flex items-center justify-center gap-4">
        <SocialIcons className="flex gap-2" />
        <Copyright className="" />
      </div>
    </div>
  );
};

export default HeaderMobileFooter;
