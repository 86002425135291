import {useCallback} from 'react';

import {useTranslation} from 'react-i18next';

import type {ErrorDto} from 'types/Dto/Error';

import {parseError} from 'utils/Error';

import {useToast} from 'components/v3/toast/use-toast';

const useHandleError = () => {
  const {toast} = useToast();
  const {t} = useTranslation();

  const handleError = useCallback(
    (error: unknown, fallbackMsg?: string) => {
      const {msg} = parseError(error as ErrorDto, fallbackMsg);

      toast({
        variant: 'destructive',
        title: 'Uh oh! Something went wrong.',
        description: t(msg),
        // action: <ToastAction altText="Try again">Try again</ToastAction>,
      });
    },
    [t, toast],
  );

  return handleError;
};

export default useHandleError;
