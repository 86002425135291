import Link from 'next/link';

import PAGE from 'constants/page';

import useIsAdminPages from 'hooks/useIsAdminPages';
import useUserCompleteProfile from 'hooks/user/useUserCompleteProfile';

import AdminMenu from 'components/Admin/AdminMenu/AdminMenu';
import {AppSwitcher} from 'components/Apps/AppMenu/AppSwitcher';
import {LogsIcon, WebhookIcon} from 'components/v3/icons/sidebar/Dashboard';

import AppMenu from '../../../Apps/AppMenu/AppMenu';

const LeftPanel = () => {
  const {isProfileIncomplete} = useUserCompleteProfile();
  const isAdminPages = useIsAdminPages();

  if (isProfileIncomplete) return null;

  const renderContent = () => {
    if (isAdminPages) {
      return (
        <div className="flex flex-col">
          <div className="z-10 flex flex-col w-full"></div>
          <AdminMenu />
        </div>
      );
    }

    return (
      <>
        <div className="flex flex-col items-start gap-2 justify-between">
          <span className={'text-[#878787] text-[8px] uppercase font-bold'}>
            Application
          </span>
          <AppSwitcher />
        </div>

        <div className="flex flex-col mt-4">
          <span className={'text-[#878787] text-[8px] uppercase font-bold'}>
            Tools
          </span>
          <AppMenu />

          <div className="flex flex-col p-0 rounded-md bg-alabaster-50 dark:bg-woodsmoke-950 mt-6">
            <Link
              className="noramp-sidebar-btn noramp-sidebar-btn-unactive app-menu-link"
              href={PAGE.SETTINGS.API_KEY}>
              <WebhookIcon className="mr-2 text-xl" />
              API Key
            </Link>
            <Link
              href={PAGE.DOCS}
              className={
                'noramp-sidebar-btn noramp-sidebar-btn-unactive app-menu-link'
              }
              target="_blank">
              <LogsIcon className="mr-2 text-xl" />
              Documentation
            </Link>
          </div>

          {/*<div className="mt-8">*/}
          {/*  <Link*/}
          {/*    href="https://noramp.io"*/}
          {/*    target="_blank"*/}
          {/*    className="flex items-center justify-center px-4 py-2 border rounded-lg shadow-sm noramp-border">*/}
          {/*    <div className="flex flex-col items-start">*/}
          {/*      <p className="font-bold text-md">NoRamp 3.0</p>*/}
          {/*      <p className="text-sm font-semibold text-gray-400">*/}
          {/*        See whats New*/}
          {/*      </p>*/}
          {/*    </div>*/}
          {/*    <MdSpaceDashboard className="ml-auto text-3xl" />*/}
          {/*  </Link>*/}
          {/*</div>*/}
        </div>
      </>
    );
  };

  return (
    <div
      className={
        'p-5 h-full flex flex-col w-72 flex-none border-r noramp-border select-none noramp-bg z-20'
      }>
      <div className="sticky top-24">{renderContent()}</div>
    </div>
  );
};

export default LeftPanel;
