export const isDeepValue = (obj: any, path: string): boolean => {
  const parts = path.split('.');
  const len = parts.length;

  let lastValue = obj;
  for (let i = 0; i < len; i++) {
    if (!(parts[i] in lastValue)) {
      break;
    }

    if (i + 1 === len) {
      return true;
    }

    lastValue = (lastValue as any)[parts[i]];
  }

  return false;
};

export const getDeepValue = <T = any>(obj: any, path: string): T => {
  const parts = path.split('.');
  const len = parts.length;

  let lastValue = obj;
  for (let i = 0; i < len; i++) {
    if (
      typeof lastValue === 'undefined' ||
      lastValue === null ||
      typeof lastValue !== 'object'
    ) {
      return lastValue;
    }

    lastValue = (lastValue as any)[parts[i]];
  }

  return lastValue;
};

export const deleteDeepValue = <T = any>(obj: any, path: string): T => {
  const parts = path.split('.');
  const len = parts.length;

  let lastValue = obj;
  for (let i = 0; i < len; i++) {
    if (!(parts[i] in lastValue)) {
      break;
    }

    if (i + 1 === len) {
      delete lastValue[parts[i]];
      break;
    }

    lastValue = (lastValue as any)[parts[i]];
  }

  return lastValue;
};

export const setDeepValue = <T extends object>(
  obj: T,
  path: string,
  value: unknown,
) => {
  const parts = path.split('.');
  const len = parts.length;

  let lastValue = obj;
  for (let i = 0; i < len; i++) {
    if (!(parts[i] in lastValue)) {
      (lastValue as any)[parts[i]] = {};
    }

    if (i === len - 1) {
      (lastValue as any)[parts[i]] = value;
      break;
    }

    lastValue = (lastValue as any)[parts[i]];
  }

  return obj;
};

export const eachDeepValue = (
  data: any,
  handler: (name: string, value: unknown) => void,
  prefix?: string,
) => {
  if (!data || typeof data !== 'object') {
    return data;
  }

  Object.entries(data ?? {}).forEach(([key, value]) => {
    const currentKey = prefix ? `${prefix}.${key}` : key;

    if (value && typeof value === 'object') {
      eachDeepValue(value, handler, currentKey);
      return;
    }

    handler(currentKey, value);
  });
};

/* console.log('setDeepValue', setDeepValue({}, 'abc.testing.keke', 'value'));
console.log(
  'setDeepValue',
  setDeepValue({abc: {bmm: 'aaa'}}, 'abc.testing.keke', 'value'),
);*/

/* console.log(
  'convertToPlainPath',
  eachDeepValue(
    {
      abc: 'testing',
      other: 'abc',
      here: {
        abdd: 32332,
      },
      first: {
        second: {
          third: {
            final: 'abc',
          },
        },
      },
    },
    (name, value) => {
      console.table({name, value});
    },
  ),
); */
