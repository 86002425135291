import {useEffect, useState} from 'react';

import classNames from 'classnames';
import {useRouter} from 'next/router';
import {FiMenu, FiX} from 'react-icons/fi';

import useCurrentUser from 'hooks/user/useCurrentUser';

import {LoginStatus} from 'components/App/AppContext';
import Logo from 'components/App/Structure/Logo';
import Button from 'components/Common/Button';
import Modal from 'components/Common/Table/Modal';

import HeaderMobileAccountMenu from '../HeaderMobileAccountMenu/HeaderMobileAccountMenu';
import HeaderMobileAuthMenu from '../HeaderMobileAuthMenu/HeaderMobileAuthMenu';

type HeaderMobileMenuProps = {
  currentUserStatus: LoginStatus;
  // open: boolean;
  // onOpen: () => void;
  // onClose: () => void;
};

const HeaderMobileMenu = ({currentUserStatus}: HeaderMobileMenuProps) => {
  const router = useRouter();
  const user = useCurrentUser();

  const [open, setOpen] = useState(false);

  useEffect(() => {
    const onRouteChangeComplete = () => setOpen(false);

    router.events.on('routeChangeComplete', onRouteChangeComplete);

    return () =>
      router.events.off('routeChangeComplete', onRouteChangeComplete);
  }, [router.events]);

  useEffect(() => {
    const modalsList = document.getElementById('modals-list');
    const header = document.getElementById('app-header');

    if (!modalsList || !header) return;

    modalsList.style.top = `${header.getBoundingClientRect().height}px`;

    return () => {
      modalsList.style.top = '0';
    };
  }, []);

  return (
    <>
      <Button
        icon={<FiMenu className="w-6 h-6" />}
        className={'flex-grow-0 min-w-0 w-[22px] p-0'}
        onPress={() => setOpen(true)}
      />

      <div
        className={classNames(
          'fixed top-0 left-0 z-20 w-full min-h-screen noramp-bg transition-transform transform max-h-screen overflow-auto',

          open ? '-translate-x-0' : 'translate-x-[100%]  ',
        )}>
        <div className="flex items-center justify-end w-full pt-4 pb-4 border-b noramp-border">
          <div
            className="grid w-full gap-2 px-4"
            style={{
              gridTemplateColumns: '1fr 100px',
            }}>
            <div className="flex">
              <Logo
                className="flex items-center justify-center h-full"
                mobile
              />
            </div>
            {/* <div className="flex border">
        {/* <div>
        {currentUserStatus === 'logged' && (
          <HeaderMobileAppSelector
            open={showAppMenu}
            onOpen={openAppMenu}
            onClose={closeAppMenu}
          />
        )}
      </div> */}
            {/* <HeaderMobileCreateAppButton /> * /}
      </div> */}
            <div className="flex justify-end ">
              <Button
                icon={<FiX className="w-6 h-6" />}
                className={'flex-grow-0 min-w-0 w-[22px] p-0'}
                onPress={() => setOpen(false)}
              />
            </div>
          </div>
        </div>

        <div className="p-4 overflow-auto ">
          {user && currentUserStatus === 'logged' ? (
            <HeaderMobileAccountMenu />
          ) : (
            <HeaderMobileAuthMenu />
          )}
        </div>
      </div>

      {open && (
        <Modal
          setClosed={close}
          className={'bg-snow-50 flex flex-col top-auto bottom-0'}></Modal>
      )}
    </>
  );
};

export default HeaderMobileMenu;
