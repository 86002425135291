import {
  browserSessionPersistence,
  getAuth,
  setPersistence,
} from '@firebase/auth';
import * as Sentry from '@sentry/react';
import {initializeApp} from 'firebase/app';
import mixpanel from 'mixpanel-browser';

type Config = {
  dev: boolean;
  lang: string;
  firebaseConfig: any;
  sentryIngest: string;
  mixpanelToken: string;

  tests: boolean;

  algolia: {
    appId: string;
    indexName: string;
    apiKey: string;
  };
};

// Load config
const appConfig: Config = {
  dev: process.env.NEXT_PUBLIC_DEV === 'true',
  lang: process.env.NEXT_PUBLIC_APP_LANG || 'en',
  firebaseConfig: JSON.parse(process.env.NEXT_PUBLIC_FIREBASE_CONFIG || '{}'),
  sentryIngest: process.env.NEXT_PUBLIC_SENTRY_INGEST || '',
  mixpanelToken: process.env.NEXT_PUBLIC_MIXPANEL_TOKEN || '',

  algolia: {
    appId: process.env.NEXT_PUBLIC_ALGOLIA_APP_ID || '',
    indexName: process.env.NEXT_PUBLIC_ALGOLIA_INDEX_NAME || '',
    apiKey: process.env.NEXT_PUBLIC_ALGOLIA_API_KEY || '',
  },

  // set based on Jest
  tests: typeof process.env.JEST_WORKER_ID !== 'undefined',
};

export const init = () => {
  // init sentry
  if (appConfig.sentryIngest) {
    Sentry.init({
      dsn: appConfig.sentryIngest,

      // Set tracesSampleRate to 1.0 to capture 100%
      // of transactions for performance monitoring.
      // We recommend adjusting this value in production
      tracesSampleRate: 1.0,
    });
  }

  // Initialize firebase
  initializeApp(appConfig.firebaseConfig);

  // initialize mixpanel
  if (appConfig.mixpanelToken) {
    mixpanel.init(appConfig.mixpanelToken, {debug: appConfig.dev});
  }

  // Set session persistence
  const auth = getAuth();
  setPersistence(auth, browserSessionPersistence);

  if (appConfig.dev && !appConfig.tests) {
    console.log('[App] App running in dev mode');
  }
};

export default appConfig;
