import {
  useEffect,
  useMemo,
  type KeyboardEvent,
  type FocusEvent,
  type ChangeEvent,
} from 'react';

import classNames from 'classnames';
import {useFormState} from 'react-hook-form';

import {getDeepValue} from 'utils/Misc';

import useTranslation from 'hooks/core/useTranslation';

import {useField} from '../Field';
import {
  normalizeFieldName,
  useForm,
  useFormData,
  useSetFieldMetadata,
} from '../index';
import {useRules} from '../useRules';

type RenderType = {render: (props: any) => JSX.Element};

export type AnyProps = {[name: string]: any};

export type InputRenderProps<T, E> = {
  id?: string;
  name?: string;
  type?: string;
  trim?: boolean;
  noTrans?: boolean;
  optional?: boolean;
  required?: boolean;
  defaultValue?: any;
  readOnly?: boolean;
  disabled?: boolean;
  accept?: string;
  checked?: boolean;
  className?: string;
  rules?: any;
  onInput?: (event: ChangeEvent<E>) => void | Promise<void>;
  onChange?: (event: ChangeEvent<E>) => void | Promise<void>;
  onFocus?: (event: FocusEvent<E>) => void | Promise<void>;
  onBlur?: (event: FocusEvent<E>) => void | Promise<void>;
  onKeyDown?: (event: KeyboardEvent<E>) => void | Promise<void>;
  onKeyUp?: (event: KeyboardEvent<E>) => void | Promise<void>;
  asNumber?: boolean;
  setValueAs?: (value: T) => void;
  valueAsBoolean?: boolean;
  valueAsDate?: boolean;
  valueAsNumber?: boolean;
  valueAsObject?: boolean;
  removeAsEmpty?: boolean;
  min?: number;
  max?: number;
  autoComplete?: string;
  autoCorrect?: string;
  autoCapitalize?: string;
  spellCheck?: string;
  minLength?: number;
  maxLength?: number;
  pattern?: RegExp;
  validate?: any;
  label?: string;
  labelParams?: AnyProps;
  placeholder?: string;
  placeholderParams?: AnyProps;
};

type BaseValue<T> = {[name: string]: T};

export const InputRender = <T, E>({
  id,
  name: nameProp,
  type = 'text',
  defaultValue: defaultValueProp,
  className,
  noTrans,
  label: labelProp,
  labelParams: labelParamsProp,
  placeholder: placeholderProp,
  placeholderParams: placeholderParamsProp,
  rules,
  onChange: onChangeProp,
  onFocus: onFocusProp,
  onBlur: onBlurProp,
  onInput,
  onKeyDown,
  onKeyUp,
  setValueAs,
  valueAsDate: valueAsDateProp,
  valueAsBoolean: valueAsBooleanProp,
  valueAsNumber: valueAsNumberProp,
  valueAsObject,
  removeAsEmpty,
  required: requiredProp,
  readOnly: readOnlyProp,
  accept,
  checked,
  disabled,
  optional,
  min,
  max,
  minLength,
  maxLength,
  autoComplete,
  autoCorrect,
  autoCapitalize,
  spellCheck,
  pattern,
  validate,
  render,
}: //...props
InputRenderProps<T, E> & RenderType) => {
  const {t} = useTranslation();
  const setFieldMetadata = useSetFieldMetadata();
  const {register, control} = useForm<BaseValue<T>>();
  const {
    id: fieldId,
    name: fieldName,
    label: fieldLabel,
    labelParams: fieldLabelParams,
    placeholder: fieldPlaceholder,
    placeholderParams: fieldPlaceholderParams,
  } = useField<{
    id: string;
    name: string;
    label: string;
    labelParams: AnyProps;
    placeholder: string;
    placeholderParams: AnyProps;
  }>();

  const name = nameProp || fieldName;

  if (!name) {
    throw new Error('form/undefined-input-name');
  }

  const formData = useFormData<BaseValue<T>>();

  const fieldValue = getDeepValue(formData, name);

  const defaultValue = useMemo(() => {
    if (typeof defaultValueProp !== 'undefined') {
      return defaultValueProp;
    }

    if (fieldValue && typeof fieldValue === 'object' && 'id' in fieldValue) {
      return (fieldValue as any).id;
    }

    return fieldValue;
  }, [defaultValueProp, fieldValue]);

  useEffect(() => {
    const metadata = {
      ...(valueAsObject && {valueAsObject}),
      ...(removeAsEmpty && {removeAsEmpty}),
    };

    if (!Object.keys(metadata).length) {
      return;
    }

    setFieldMetadata(name, metadata);
  }, [name, setFieldMetadata, valueAsObject, removeAsEmpty]);

  const label = typeof labelProp !== 'undefined' ? labelProp : fieldLabel;
  const placeholder =
    typeof placeholderProp !== 'undefined'
      ? placeholderProp
      : fieldPlaceholder || label;

  const required = optional ? false : requiredProp;

  const valueAsDate = valueAsDateProp || type === 'date';
  const valueAsNumber = valueAsNumberProp || type === 'number';
  const valueAsBoolean = valueAsBooleanProp || type === 'checkbox';

  const allRules = useMemo(
    () => ({
      noTrans,
      setValueAs,
      valueAsDate,
      valueAsNumber,
      required,
      min,
      max,
      minLength,
      maxLength,
      pattern,
      validate,
      ...rules,
    }),
    [
      noTrans,
      setValueAs,
      valueAsDate,
      valueAsNumber,
      required,
      min,
      max,
      minLength,
      maxLength,
      pattern,
      validate,
      rules,
    ],
  );

  const parsedRules = useRules(
    label ? t(label, labelParamsProp || fieldLabelParams) : label,
    name,
    allRules,
  );

  const field = useMemo(
    () => register(name as any, parsedRules),
    [name, register, parsedRules],
  );

  const {onBlur, onChange} = field;

  const {errors, isSubmitting} = useFormState({name: name as any, control});
  const fieldError = getDeepValue(errors, name);

  const _onBlur = useMemo(
    () =>
      onBlurProp
        ? async (e: FocusEvent<E>) => {
            await onBlurProp(e);

            // cancel if prevented
            if (!e.isDefaultPrevented()) {
              onBlur(e);
            }
          }
        : onBlur,
    [onBlurProp, onBlur],
  );

  const _onFocus = useMemo(
    () =>
      onFocusProp
        ? (e: FocusEvent<E>) => {
            onFocusProp(e);
          }
        : undefined,
    [onFocusProp],
  );

  const _onChange = useMemo(
    () =>
      onChangeProp || valueAsObject
        ? async (e: ChangeEvent<E>) => {
            if (onChangeProp) {
              await onChangeProp(e);
            }

            // cancel if prevented
            if (e.isDefaultPrevented()) {
              return;
            }

            if (valueAsObject) {
              (e.target as any).value = JSON.stringify(e);
            }

            onChange(e);
          }
        : onChange,
    [onChangeProp, valueAsObject, onChange],
  );

  const readOnly = readOnlyProp || isSubmitting;

  const content = useMemo(() => {
    const finalDefaultValue =
      defaultValue && valueAsObject
        ? JSON.stringify(defaultValue)
        : defaultValue;

    const params = {
      ...field,
      //...props,

      ...(valueAsBoolean
        ? {defaultChecked: finalDefaultValue}
        : typeof finalDefaultValue !== 'undefined' && {
            defaultValue: finalDefaultValue,
          }),

      id: normalizeFieldName(id || fieldId || name),
      type,
      onBlur: _onBlur,
      onChange: _onChange,
      onFocus: _onFocus,
      onKeyDown,
      onKeyUp,
      onInput,
      maxLength,
      autoComplete,
      autoCorrect,
      autoCapitalize,
      spellCheck,
      readOnly,
      accept,
      checked,
      disabled: readOnly || disabled,
      placeholder: placeholder
        ? t(
            placeholder,
            placeholderParamsProp ||
              fieldPlaceholderParams ||
              fieldLabelParams ||
              labelParamsProp,
          )
        : placeholder,
      className: classNames(
        className,
        'input noramp-bg noramp-text noramp-border',
        {
          [`input-${type}`]: type,
          [`input-${normalizeFieldName(name)}`]: true,
          readonly: readOnly,
          disabled: disabled,
        },
        {error: fieldError},
      ),
    };

    return render(params);
  }, [
    _onBlur,
    _onChange,
    _onFocus,
    onKeyDown,
    onInput,
    onKeyUp,
    className,
    defaultValue,
    fieldError,
    field,
    valueAsObject,
    valueAsBoolean,
    //props,
    fieldLabelParams,
    fieldPlaceholderParams,
    labelParamsProp,
    maxLength,
    id,
    fieldId,
    name,
    placeholder,
    placeholderParamsProp,
    autoComplete,
    autoCorrect,
    autoCapitalize,
    spellCheck,
    render,
    t,
    type,
    readOnly,
    accept,
    checked,
    disabled,
  ]);

  return <>{content}</>;
};

export default InputRender;
