import {forwardRef, useCallback, type MouseEvent} from 'react';

import NextLink from 'next/link';

import type {UrlProps} from 'types/Url';

import useUrl from 'hooks/core/useUrl';

import {cn} from 'lib/utils';

import type {ButtonBaseProps} from 'components/Common/Button';
import Icon from 'components/Common/Icon';
import Locale from 'components/Common/Locale';

export type OnPress = (
  event: MouseEvent<HTMLLinkElement>,
) => Promise<void> | void;

export type LinkProps = UrlProps &
  ButtonBaseProps & {
    onPress?: OnPress;
    target?: string;
  };

const Link = forwardRef<HTMLAnchorElement, LinkProps>(
  (
    {
      id,
      className,
      target,
      title,
      onPress,
      children,

      label,
      labelParams,

      icon,
      iconPosition = 'before',

      to,
      base,
      goBack,
      follows,
      replace,
      params,
      width,
      style,

      ...props
    },
    ref,
  ) => {
    const urlProps = {
      to,
      base,
      params,
      follows,
      goBack,
      replace,
      ...props,
    };
    const url = useUrl();
    const linkUrl = url(urlProps);

    const onClick = useCallback(
      async (e: any) => {
        onPress?.(e);
      },
      [onPress],
    );

    const renderContent = () => {
      return (
        <>
          {iconPosition === 'before' && <Icon icon={icon} />}
          {children ||
            (label ? (
              <span className="text">
                <Locale text={label} params={labelParams} />
              </span>
            ) : (
              label
            ))}
          {iconPosition === 'after' && <Icon icon={icon} />}
        </>
      );
    };

    return (
      <NextLink
        href={linkUrl}
        replace={replace}
        ref={ref}
        id={id}
        style={{
          ...style,
          ...(width && {
            width: typeof width === 'number' ? `${width}px` : width,
          }),
        }}
        {...(className && {className: cn(className.split(' '))})}
        onClick={onClick}
        title={title}
        target={target}>
        {renderContent()}
      </NextLink>
    );
  },
);

Link.displayName = 'Link';

export default Link;
