import AdminMenuOptions from './AdminMenuOptions/AdminMenuOptions';

const AdminMenu = () => {
  return (
    <>
      <AdminMenuOptions />
    </>
  );
};

export default AdminMenu;
