import {useCallback} from 'react';

import useHandleError from './useHandleError';

type UseHandleActionOptions = {
  onError?: () => Promise<void>;
  error?: string;
};

const useHandleAction = () => {
  const handleError = useHandleError();

  const handleAction = useCallback(
    async <T>(
      handler: () => T | Promise<T>,
      options?: UseHandleActionOptions,
    ) => {
      const {error, onError} = options ?? {};

      try {
        return await handler();
      } catch (e) {
        console.warn(e);
        try {
          await onError?.();
          handleError(e, error);
        } catch (e) {
          console.warn(e);
          handleError(e, error);
        }
      }
    },
    [handleError],
  );

  return handleAction;
};

export default useHandleAction;
