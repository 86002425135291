import {UserDto} from 'types/Dto';

type UserNameProps = {
  id: string;
  first_name?: string;
  last_name?: string;
  username?: string;
};

export const formatUserName = (user: UserNameProps) => {
  if (!user) {
    return 'Undefined name';
  }

  const {id, first_name} = user;
  const last_name = 'last_name' in user ? user.last_name : null;

  if (first_name && last_name) {
    return `${first_name} ${last_name}`;
  }

  if (first_name) {
    return `${first_name}`;
  }

  return user?.username ?? id.split('-')[0] ?? 'Undefined name';
};

export type UserFullNameProps = {
  id: string;
  first_name: string | null;
  last_name: string | null;
};

export const formatUserFullName = (user: UserFullNameProps) => {
  if (!user) {
    return 'Undefined name';
  }

  const {id, first_name} = user;
  const last_name = 'last_name' in user ? user.last_name : null;

  if (first_name && last_name) {
    return `${first_name} ${last_name}`;
  }

  if (first_name) {
    return `${first_name}`;
  }

  return id?.split('-')[0] ?? 'Undefined name';
};

export type UserIdentifierProps = {
  id: string;
  username: string;
};

export const formatUserIdentifier = (user: UserIdentifierProps) => {
  const {id, username} = user;
  return username ?? id;
};

export const formatUserInitials = (user: UserDto | null) => {
  if (!user) {
    return '';
  }
  const {first_name, last_name} = user;
  const firstInitial = first_name?.charAt(0) ?? '';
  const lastInitial = last_name?.charAt(0) ?? '';
  return `${firstInitial}${lastInitial}`;
};
