import {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
  type Dispatch,
  type ReactNode,
  type SetStateAction,
} from 'react';

import classNames from 'classnames';
import Head from 'next/head';
import {useRouter} from 'next/router';

import PAGE from 'constants/page';

//import useMixpanel from 'hooks/useMixpanel';
import useTranslation from 'hooks/core/useTranslation';
import useCurrentUserStatus from 'hooks/user/useCurrentUserStatus';

// import {useApp} from 'components/App/AppContext';
import Seo from 'components/App/Seo/Seo';
import type {SeoProps} from 'components/App/Seo/Types';

import Redirect from '../Redirect';

type SetPageTitle = Dispatch<SetStateAction<string>>;

type PageContextProps = {
  title: string;
  setTitle: SetPageTitle | null;
};

const PageContext = createContext<PageContextProps>({
  title: '',
  setTitle: null,
});

export type PageProps = {
  title?: string;
  noTrans?: boolean;
  className?: string;
  padding?: boolean;
  children?: ReactNode;
  noAuth?: boolean;
  seo?: SeoProps;
};

const Page = ({
  title: titleProp,
  padding,
  className,
  noAuth,
  noTrans,
  children,
  seo,
}: PageProps) => {
  const {t} = useTranslation();
  const status = useCurrentUserStatus();
  const initTitle = useRef(titleProp || '');
  const [title, setTitle] = useState(initTitle.current);

  const {asPath} = useRouter();
  //const {track} = useMixpanel();

  const pageKey = useMemo(() => asPath.split('#')[0], [asPath]);

  const currentTitle = useMemo(() => {
    return (noTrans ? title : t(title)).toString() || 'NoRamp';
  }, [t, title, noTrans]);

  // track page
  /* useEffect(
    () =>
      track(currentTitle, {
        pageView: true,
      }),
    [track, currentTitle],
  ); */

  // updates if `title` changes
  useEffect(() => {
    if (titleProp !== initTitle.current) {
      setTitle(titleProp || '');
    }
  }, [initTitle, titleProp]);

  // update document title
  useEffect(() => {
    document.title = currentTitle;
  }, [currentTitle]);

  // check no-access
  if (!noAuth && status !== 'logged') {
    if (status !== 'loading') {
      return <Redirect to={PAGE.LOGIN.HOME} replace />;
    }

    return null;
  }

  return (
    <PageContext.Provider key={pageKey} value={{title: currentTitle, setTitle}}>
      <Head>
        <title>{currentTitle}</title>
      </Head>
      <Seo title={currentTitle} {...seo} />
      <div
        className={classNames(
          'flex flex-col box-border w-full mx-auto flex-1 lg:px-8 lg:py-8 px-4 py-4 max-w-full overflow-auto',
          className,
          {
            padding,
            'base-size': !className,
          },
        )}>
        {children}
      </div>
    </PageContext.Provider>
  );
};

export const usePageTitle = (): string => {
  const {title} = useContext(PageContext);
  return title;
};

export const useSetPageTitle = (): SetPageTitle => {
  const {setTitle} = useContext(PageContext);

  return setTitle as SetPageTitle;
};

export default Page;
