import {useState, useCallback, type ReactNode} from 'react';

import {cn} from 'lib/utils';

import Button from 'components/Common/Button';
import Locale from 'components/Common/Locale';
import Modal, {
  useSetModalClosed,
  UseSetModalClosed,
} from 'components/Common/Table/Modal';

import CloseIcon from './res/close-icon.svg';

type AnyProps = {[name: string]: any};

type UseSetClosed = UseSetModalClosed;

export type DialogProps = {
  className?: string;
  header?: ReactNode;
  title?: string;
  titleParams?: AnyProps;
  subtitle?: string;
  subtitleParams?: AnyProps;
  labelClass?: string;
  label?: string;
  labelParams?: AnyProps;
  onConfirm?: () => void | Promise<void>;
  children?: ReactNode;
  raw?: boolean;
};

const Dialog = ({
  setClosed: setClosedProp,
  ...props
}: DialogProps & {setClosed: UseSetClosed}) => {
  const [visible, setVisible] = useState(true);

  const setClosed = useCallback(() => {
    setVisible(false);

    if (setClosedProp) {
      setClosedProp(false);
    }
  }, [setClosedProp, setVisible]);

  return (
    <>
      {visible && (
        <Modal setClosed={setClosed}>
          <DialogRender {...props} />
        </Modal>
      )}
    </>
  );
};

const DialogRender = ({
  className,
  header,
  title,
  titleParams,
  subtitle,
  subtitleParams,
  children,
  onConfirm,
  label,
  labelClass,
  labelParams,
  raw,
}: DialogProps) => {
  const setModalClosed = useSetModalClosed();

  const onPressClose = useCallback(() => {
    if (setModalClosed) {
      setModalClosed();
    }
  }, [setModalClosed]);

  const onPressConfirm = useCallback(() => {
    if (onConfirm) {
      onConfirm();
    }

    if (setModalClosed) {
      setModalClosed();
    }
  }, [onConfirm, setModalClosed]);

  const renderDefault = () => {
    return (
      <div className={cn('default-actions')}>
        <Button
          onPress={onPressConfirm}
          className={cn(labelClass?.split(' ') ?? ['btn', 'primary', 'expand'])}
          label={label || 'common.dialog_confirm'}
          labelParams={labelParams}
        />
      </div>
    );
  };

  const renderTitles = () => {
    if (!title && !subtitle) {
      return null;
    }

    return (
      <div className={cn('titles')}>
        {title && (
          <span className={cn('title')}>
            <Locale text={title} params={titleParams} />
          </span>
        )}
        {subtitle && (
          <span className={cn('subtitle')}>
            {<Locale text={subtitle} params={subtitleParams} />}
          </span>
        )}
      </div>
    );
  };

  return (
    <div className={cn('dialog', 'horizontal-padding', className)}>
      <div className={cn('header')}>
        {header}
        <div className={'flex flex-1 justify-end'}>
          <Button
            onPress={onPressClose}
            className={cn(
              'flex cursor-pointer w-6 h-6 items-center justify-center',
            )}>
            <CloseIcon />
          </Button>
        </div>
      </div>

      {raw ? (
        children
      ) : (
        <div className={cn('bottom')}>
          {renderTitles()}
          <div className={cn('content', 'bottom-padding')}>
            {children || renderDefault()}
          </div>
        </div>
      )}
    </div>
  );
};

export default Dialog;
