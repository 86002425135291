import {type ReactNode} from 'react';

import type {IconType as ReactIconType} from 'react-icons';

export type NodeType =
  | ReactNode
  | JSX.Element
  | null
  | undefined
  | (() => JSX.Element)
  | ReactIconType;

export type NodeRenderProps = {
  node: NodeType;
};

const NodeRender = ({node: NodeElement}: NodeRenderProps) => {
  if (typeof NodeElement === 'undefined' || NodeElement === null) {
    return null;
  }

  if (typeof NodeElement === 'function') {
    const ElementToRender = NodeElement as () => JSX.Element;
    return <ElementToRender />;
  }

  return <>{NodeElement}</>;
};

export default NodeRender;
