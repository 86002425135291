const PAGE = {
  DOCS: 'https://docs.noramp.io',

  LEGAL: {
    HOME: 'legal',
    TERMS: 'legal/terms.pdf',
    PRIVACY: 'legal/terms.pdf',
  },

  APPS: {
    NEW_APP: 'apps/onboard',
    HOME: 'apps',
    CREATE: 'apps/create',
    DASHBOARD: 'apps/dashboard',
    TRIGGERS: {
      HOME: 'apps/triggers',
    },
    WALLETS: {
      HOME: 'apps/wallets',
    },
    PAYMENTS: 'apps/payments',
    // INTEGRATION: {
    //   HOME: 'apps/integration',
    //   KYC: 'apps/integration/kyc',
    //   PAYOUT: 'apps/integration/payout',
    //   HOSTED_CHECKOUT: 'apps/integration/hosted-checkout',
    // },
    LOGS: 'apps/logs',
    WEBHOOKS: 'apps/webhooks',
    SETTINGS: 'apps/settings',

    SELLER_ACCOUNTS: 'apps/seller-accounts',

    PAYMENT_INTENTS: {
      LISTING: 'apps/payment-intents',
      CREATE: 'apps/payment-intents/create',
    },

    CUSTOMIZE: 'apps/themes',

    AUCTIONS: {
      HOME: 'apps/auctions',
      CREATE: 'apps/auctions/create',
    },

    SUBSCRIPTIONS: {
      HOME: 'apps/subscriptions',
      CREATE: 'apps/subscriptions/create',
    },
  },

  NOTIFICATIONS: 'notifications',

  ADMIN: {
    HOME: 'admin',
    DASHBOARD: 'admin/dashboard',
    USERS: 'admin/users',
    APPS: 'admin/apps',
    APP_APPROVALS: 'admin/app-approvals',
    STATUS: 'admin/status',
    KYCS: 'admin/kycs',
    SHOPPERS: 'admin/shoppers',
    THEMES: 'admin/themes',
  },

  SETTINGS: {
    HOME: 'settings',
    API_KEY: '/settings/api-key',
  },

  LOGIN: {
    HOME: 'login',
    EMAIL: 'login/email',
  },

  SIGNUP: 'signup',
  WELCOME: '/welcome',

  ONBOARDING: {
    COMPLETE_PROFILE: 'onboarding/complete-profile',
  },
};

export default PAGE;
