import {useEffect, useMemo, useState, type ReactNode} from 'react';

import classNames from 'classnames';

import {AppDto} from 'types/Dto';
import type {ThemeMode, ThemeType} from 'types/Theme';

import useIsMobile from 'hooks/core/useIsMobile';
import {useSystemTheme} from 'hooks/core/useSystemTheme';
import useIsEmbedPages from 'hooks/embed/useIsEmbedPages';
import useNetwork from 'hooks/use-network';
import useMixpanel from 'hooks/useMixpanel';

import {type SignInModalProps} from 'components/App/Auth/SignInModal';

import AppContext, {type UserState} from './AppContext';
import AppProvider from './AppProvider';
import AppRender from './AppRender';

const DEFAULT_THEME = 'dark'; // to `system`

type AppLayoutProps = {
  children: ReactNode;
};

const AppLayout = ({children}: AppLayoutProps) => {
  const userAgent = useMemo(
    () => (typeof navigator !== 'undefined' ? navigator.userAgent : undefined),
    [],
  );
  const {networkType, setNetworkType} = useNetwork();

  const isEmbedPages = useIsEmbedPages();
  const isMobile = useIsMobile(userAgent);
  const systemTheme = useSystemTheme();
  const [user, setUser] = useState<UserState>({
    status: 'loading',
    user: null,
    is_new: false,
  });
  const {status, user: currentUser} = user;
  const [theme, setTheme] = useState<ThemeMode>(DEFAULT_THEME);
  const [viewTheme, setViewTheme] = useState<ThemeType | null>(null);
  const [showFooter, setShowFooter] = useState<boolean>(true);
  const [headerTitle, setHeaderTitle] = useState<string | null>(null);
  const [hideScroll, setHideScroll] = useState<boolean>(false);
  const [showSignInModal, setShowSignInModal] =
    useState<SignInModalProps | null>(null);
  const [isPageBack, setIsPageBack] = useState<boolean>(false);

  const {setUser: setMixpanelUser, unsetUser: unsetMixpanelUser} =
    useMixpanel();

  useEffect(() => {
    document.body.style.overflow = hideScroll ? 'hidden' : 'auto';
  }, [hideScroll]);

  // report user info
  useEffect(() => {
    if (status === 'loading') return;

    if (!currentUser) {
      unsetMixpanelUser();
      return;
    }

    setMixpanelUser({
      id: currentUser.id,
      email: currentUser.email,
      first_name: currentUser.first_name,
      last_name: currentUser.last_name,
    });
  }, [setMixpanelUser, unsetMixpanelUser, currentUser, status]);

  const resolvedTheme = useMemo(() => {
    if (theme === 'system') {
      return systemTheme;
    }

    return theme;
  }, [theme, systemTheme]);

  useEffect(() => {
    const classNameList = classNames({
      'body-mobile': isMobile,
      'body-desktop': !isMobile,
      'body-theme-dark': resolvedTheme === 'dark',
      'body-theme-light': resolvedTheme === 'light',
      'embed-app': isEmbedPages,
    });

    document.body.classList.add(...classNameList.split(' '));

    return () => {
      document.body.classList.remove(...classNameList.split(' '));
    };
  }, [isMobile, isEmbedPages, resolvedTheme]);

  const [selectedApp, setSelectedApp] = useState<AppDto | null>(null);

  return (
    <AppContext.Provider
      value={{
        userAgent,

        user,
        setUser,

        selectedApp,
        setSelectedApp,

        resolvedTheme,
        theme,
        setTheme,
        viewTheme,
        setViewTheme,

        showFooter,
        setShowFooter,

        headerTitle,
        setHeaderTitle,

        hideScroll,
        setHideScroll,

        showSignInModal,
        setShowSignInModal,

        isPageBack,
        setIsPageBack,

        networkType,
        setNetworkType,
      }}>
      <AppProvider>
        <AppRender>{children}</AppRender>
      </AppProvider>
    </AppContext.Provider>
  );
};

export default AppLayout;
