export const IS_STAGING = process.env.NEXT_PUBLIC_STAGING === 'true';

export const QUERY_CACHE_TIME = 60000 * 5;

export const DISCORD_LINK = 'https://discord.gg/S3mdXdBg4W';

export const INSTAGRAM_LINK = 'https://www.instagram.com/norvmp/';

export const TWITTER_LINK = 'https://twitter.com/no_ramp';

export const LINKEDIN_LINK = 'https://www.linkedin.com/company/noramp';

export const MAIL_TO_HELLO = 'hello@noramp.io';

export const DEV_TEAM_CALENDLY_LINK =
  'https://calendly.com/d/d66-v9z-pvc/noramp-dev-team';

export const TESTNET_LINK = 'https://testnet.noramp.io';

export const LOGIN_REDIRECT_KEY = '__login_redirect';

export const DEMO_APP_LINK = 'https://noramp-seller-demo.vercel.app/';

// localstorage invite
export const INVITE_TOKEN_KEY = 'invite-token';
