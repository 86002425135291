import {useCallback} from 'react';

import classNames from 'classnames';

import {sendLoginLink} from 'api/auth/auth';

import useHandleError from 'hooks/core/useHandleError';

import Form, {type OnSubmit} from 'components/Form';
import Field from 'components/Form/Field';
import Input from 'components/Form/Input';
import Submit from 'components/Form/Submit';

type EmailFormProps = {
  onSuccess: (email: string) => void;
};

type EmailFields = {
  email: string;
};

const AuthEmailForm = ({onSuccess}: EmailFormProps) => {
  const handleError = useHandleError();

  const onSubmit = useCallback<OnSubmit<EmailFields>>(
    async ({values: {email}}) => {
      if (!email) {
        return;
      }

      try {
        await sendLoginLink(email);

        window.localStorage.setItem('emailForSignIn', email);

        onSuccess(email);
      } catch (e) {
        console.error(e);

        handleError(e, 'pages.login.error');
      }
    },
    [handleError, onSuccess],
  );

  return (
    <Form<EmailFields> onSubmit={onSubmit} className="w-full simple-form">
      {() => (
        <>
          <Field
            className="w-72"
            name="email"
            placeholder="Enter your email"
            // label="pages.login.email.email_field"
          >
            <Input
              type="email"
              autoFocus
              // placeholder="pages.login.email.email_placeholder"
              minLength={1}
              maxLength={128}
              className="rounded-lg text-sm py-3"
            />
          </Field>

          <Submit
            className={classNames('w-full mt-6 font-normal rounded-lg py-6')}>
            Log In / Sign Up
          </Submit>
        </>
      )}
    </Form>
  );
};

export default AuthEmailForm;
