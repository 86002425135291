import {useEffect, useCallback} from 'react';

import {useRouter} from 'next/router';

import appConfig from 'configs/App';

import type {UrlProps} from 'types/Url';

import useUrl from 'hooks/core/useUrl';

type RedirectProps = UrlProps;

export type UseRedirect = (url: UrlProps) => void;

const Redirect = (props: RedirectProps) => {
  const redirect = useRedirect();

  useEffect(() => {
    redirect(props);
  }, [redirect, props]);

  return null;
};

export const useRedirect = (): UseRedirect => {
  const router = useRouter();
  const url = useUrl();

  const redirect = useCallback<UseRedirect>(
    ({replace, newTab, ...props}) => {
      const redirectUrl = url(props);

      if (appConfig.tests) {
        return;
      }

      if (newTab) {
        window.open(redirectUrl, '_blank');
        return;
      }

      queueMicrotask(() => {
        router[replace ? 'replace' : 'push'](redirectUrl);
      });
    },
    [url, router],
  );

  return redirect;
};

export default Redirect;
