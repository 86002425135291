import useCurrentUserStatus from 'hooks/user/useCurrentUserStatus';

import Logo from '../../Logo';

const HeaderDesktop = () => {
  const userStatus = useCurrentUserStatus();

  return (
    <>
      {userStatus === 'not-logged' ? null : (
        <Logo className="flex items-center justify-center flex-1 h-full" />
      )}
    </>
  );
};

export default HeaderDesktop;
