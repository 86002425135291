import PAGE from 'constants/page';

import HeaderMobileFooter from 'components/App/Structure/Header/HeaderMobile/HeaderMobileFooter/HeaderMobileFooter';
import Link from 'components/Common/Link';

import UserIcon from './res/user.svg';

const HeaderMobileAuthMenu = () => {
  return (
    <div className="">
      <div className="">
        <Link icon={UserIcon} className="" to={PAGE.LOGIN.HOME} label="Login" />
        <Link icon={UserIcon} className="" to={PAGE.SIGNUP} label="Sign up" />
      </div>
      <HeaderMobileFooter />
    </div>
  );
};

export default HeaderMobileAuthMenu;
