import type {KycDto, KycUrlDto, CreateKycAccountDto} from 'types/Dto';

import {formatEndpoint} from 'utils/Api';
import {GET, PATCH, POST} from 'utils/Http';

export const fetchKycMe = (appId: string) => {
  return GET<KycDto>(
    formatEndpoint('APPS_KYCS_ME', {
      app_id: appId,
    }),
  );
};

export const createKycMe = (appId: string, country: string) => {
  return POST<KycDto, CreateKycAccountDto>(
    formatEndpoint('APPS_KYCS_ME', {
      app_id: appId,
    }),
    {country},
  );
};

export const createKycMeLink = (appId: string) => {
  return POST<KycUrlDto>(
    formatEndpoint('APPS_KYCS_ME_LINK', {
      app_id: appId,
    }),
  );
};

export const syncKycMe = (appId: string) => {
  return PATCH<KycDto, null>(
    formatEndpoint('APPS_KYCS_ME_SYNC', {
      app_id: appId,
    }),
  );
};

export const fetchKycManage = (editToken: string) => {
  return GET<KycDto>(
    formatEndpoint('KYCS_MANAGE', {
      edit_token: editToken,
    }),
  );
};
export const createKycManageCreate = (editToken: string, country: string) => {
  return POST<KycDto, CreateKycAccountDto>(
    formatEndpoint('KYCS_MANAGE_CREATE', {
      edit_token: editToken,
    }),
    {country},
  );
};

export const createKycManageLink = (editToken: string) => {
  return POST<KycUrlDto>(
    formatEndpoint('KYCS_MANAGE_LINK', {
      edit_token: editToken,
    }),
  );
};

export const syncKycManage = (editToken: string) => {
  return PATCH<KycDto, null>(
    formatEndpoint('KYCS_MANAGE_SYNC', {
      edit_token: editToken,
    }),
  );
};
