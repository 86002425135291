import type {AuthDto, AuthResponseDto, CreateLoginLinkDto} from 'types/Dto';

import {GET, POST, DELETE} from 'utils/Http';

import API from 'constants/api';

export const createAuth = (params: AuthDto) => {
  return POST<AuthResponseDto, AuthDto>(API.AUTH, params);
};

export const fetchAuth = () => {
  return GET<AuthResponseDto>(API.AUTH);
};

export const destroyAuth = () => {
  return DELETE(API.AUTH);
};

export const sendLoginLink = (email: string) => {
  return POST<null, CreateLoginLinkDto>(API.AUTH_LINK, {
    email,
  });
};
