import {useEffect, useRef, type ReactNode} from 'react';

import {useRouter} from 'next/router';
import {
  ErrorBoundary as ErrorBoundaryBase,
  type FallbackProps,
} from 'react-error-boundary';

import PageNotFound from 'pages/errors/not-found';

import Error from 'components/App/Error';
import {Button} from 'components/v3/button';

type ErrorBoundaryProps = {
  children?: ReactNode;
};

const ErrorCatcher = ({error, resetErrorBoundary}: FallbackProps) => {
  const {asPath} = useRouter();
  const asPathRef = useRef(asPath);

  useEffect(() => {
    if (asPathRef.current === asPath) return;

    resetErrorBoundary();
  }, [asPath, resetErrorBoundary]);

  // 404
  if ((error as any).statusCode === 404) {
    return <PageNotFound />;
  }

  return (
    <div className="m-auto flex flex-col max-w-xs items-center justify-center">
      <Error info={error as any} />

      <div className="mt-6">
        <Button onClick={resetErrorBoundary}>Try again</Button>
      </div>
    </div>
  );
};

const ErrorBoundary = ({children}: ErrorBoundaryProps) => {
  return (
    <ErrorBoundaryBase FallbackComponent={ErrorCatcher}>
      {children}
    </ErrorBoundaryBase>
  );
};

export default ErrorBoundary;
